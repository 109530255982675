import React from "react";
// import firebase from "../../utils/Firebase";
import { Button, DatePicker, Divider, Input, message, Select, Space, Table } from "antd";
import { EditFilled, EyeFilled, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { callOnServer, RRServer } from "../../../utils/lib";
import { InstallmentInterface, UserInterface } from "../../../types";
// import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import TopBar from "../../TopBar";
import { CSVLink } from "react-csv";
import TableContainer from "../../TableComp/TableContainer";
import { DateColumnFilter2, SelectColumnFilter } from "../../TableComp/Filter";
import Installments from "./Installments";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;


interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface InstallmentStateProps {
  schools: DrivingSchoolInterface[];
  selectedSchool: DrivingSchoolInterface | null;
  schoolsHashed: { [key: string]: DrivingSchoolInterface };
  users: UserInterface[];
  usersHashed: { [key: string]: UserInterface };
  userCode: string;
  filterSchoolId: any;
  filterUserId: any;
  filterDate: any;
  installments: InstallmentInterface[];
  searchText: string;
  searchedColumn: string;
  installmentDetailsId: any;
  filteredInfo: any;
  installmentOG: any
}

class InstallmentsList extends React.Component<any, InstallmentStateProps> {
  searchInput:  null | undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      schools: [],
      schoolsHashed: {},
      selectedSchool: null,
      users: [],
      usersHashed: {},
      userCode: "",
      filterSchoolId: null,
      filterUserId: null,
      filterDate: null,
      installments: [],
      searchText: "",
      searchedColumn: "",
      filteredInfo: {},
      installmentDetailsId: null,
      installmentOG: []
    };
    this.getSchool = this.getSchool.bind(this);
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    if (claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#" ) {
      Promise.all([
        RRServer.get({ table: "driving_schools", id: claims.drivingSchoolId }),
        RRServer.list({
          table: RRServer.Tables.USERS,
          forCurrentUser: false,
          where: [
            ["companyId", "==", claims.drivingSchoolId],
            ["type", "==", "DRIVING_SCHOOL_USER"],
          ],
        }),
        RRServer.list({
          table: RRServer.Tables.INSTALLMENTS,
          forCurrentUser: false,
          where: [["companyId", "==", claims.drivingSchoolId]],
        }),
      ]).then((results: any) => {
        let schools = [results[0].data.data];
        let users = results[1].data.data;

        let installments = results[2].data.data;
        this.setState({installmentOG : results[2].data.data})
        let usersHashed: { [key: string]: UserInterface } = {};
        let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
        users.map((user: UserInterface) => (usersHashed[user.id] = user));
        schools.map(
          (school: DrivingSchoolInterface) =>
            (schoolsHashed[school.id] = school)
        );
        let userHash: any = {};

        users.map((a: any) => (userHash[a.id] = a));
        this.setState({
          installments: installments.map(
            (c: any) => {( (c.uid = userHash[c.uid]?  userHash[c.uid].name : '')); return c}
          ),
        });


        this.setState({
          schools,
          users,
          usersHashed,
          installments ,
          schoolsHashed,
        });
      });
    } else {
      Promise.all([
        RRServer.list({ table: "driving_schools", forCurrentUser: false }),
        RRServer.list({
          table: RRServer.Tables.USERS,
          forCurrentUser: false,
          where: [["type", "==", "DRIVING_SCHOOL_USER"]],
        }),
        RRServer.list({
          table: RRServer.Tables.INSTALLMENTS,
          forCurrentUser: false,
        }),
      ]).then((results: any) => {
        let schools = results[0].data.data;
        let users = results[1].data.data;
        let installments = results[2].data.data;
        this.setState({installmentOG : results[2].data.data})

        let usersHashed: { [key: string]: UserInterface } = {};
        let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
        // console.log('heree',schools)
        users.map((user: UserInterface) => (usersHashed[user.id] = user));
        schools.map(
          (school: DrivingSchoolInterface) =>
            (schoolsHashed[school.id] = school)
        );
        let userHash: any = {};

        users.map((a: any) => (userHash[a.id] = a));
        this.setState({
          schools,
          users,
          usersHashed,
          installments: installments.map(
            (c: any) => {( (c.uid = userHash[c.uid]?  userHash[c.uid].name : '')); return c}
          ),

          schoolsHashed,

        });
        this.setState({
      
          installments : installments.map((ins:any)=>{
            return {...ins,dueDate : ins.dueDate.replace('-','/').replace('-','/')}
          }),
        });


      });
    }
  }

  getSchool(schoolId: string) {
    const school = this.state.schools.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  handleChange = (pagination: any, filters: any, sorter: any) => {
    // console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
    });
  };

  handleToggleCheck = (e: any) => {
    // console.log(e.target.checked, "asd");
    e.target.checked = !e.target.checked;

  };

  handleInstallment = (e:any) =>{
    RRServer.list({
      table: RRServer.Tables.INSTALLMENTS,
      forCurrentUser: false,
    }).then((data)=>{
        console.log('inss data new', data?.data.data)
        const selectedInstallment = data?.data.data.filter((ins:any)=>{
          return ins.id === this.state.installmentDetailsId
        })
        var objToUpdate = {}

        if(e.target.checked) {
            objToUpdate = {...selectedInstallment[0], status: 1}
        } else {
            objToUpdate = {...selectedInstallment[0], status: 0}
        }

        // console.log(objToUpdate, 'objToUp')
       RRServer.update({
        table: RRServer.Tables.INSTALLMENTS,
        id: this.state.installmentDetailsId,
        ...objToUpdate,
      }).then((res)=>{
        message.success("Successfully updated installment status", 2, ()=>{
          window.location.reload()
        })
        
      })

    })
  
    }

  render() {
    const {
      schools,
      filterSchoolId,
      filterUserId,
      filterDate,
      usersHashed,
      users,
      installments,
      schoolsHashed,
      filteredInfo,
    } = this.state;
    // let { url } = this.props.match;

    let dataSource = installments.map((installment) => ({
      key: installment.id,
      ...installment,
      status: installment.status ? "Paid" : "Pending",
    })).sort(function (a :any, b:any) {
      // '01/03/2014'.split('/')
      // gives ["01", "03", "2014"]
      a = a?.dueDate ? a.dueDate.split('-') : a.value.split('-');
      a = b?.dueDate ? b.dueDate.split('-') : b.value.split('-');
      return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
  });


    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.companyId === filterSchoolId);
    }
    if (filterUserId) {
      dataSource = dataSource.filter((d) => d.uid === filterUserId);
    }
    if (filterDate) {
    
   
      dataSource = dataSource.filter(item => {
        let itemDate = item.dueDate
        let startDateModified = filterDate[0].split('-').reverse().join("-")
        let endDateModified = filterDate[1].split('-').reverse().join("-")
       return (new Date(itemDate).getTime() >= new Date(startDateModified).getTime() && new Date(itemDate).getTime() <= new Date(endDateModified).getTime());
     })
    
    }


    const headers = [
      { label: "Schools", key: "school" },
      { label: "Name", key: "name" },
      { label: "No Of Installments", key: "noOfInstallments" },
      { label: "Status", key: "status" },
      { label: "Payment Start Date", key: "paymentStartDate" },
      { label: "Payment End Date", key: "paymentEndDate" },
      { label: "Action", key: "action" },
    ];

    const columns = [
      // {
      //   Header: "Schools",
      //   accessor: "companyId",
      //   Filter: SelectColumnFilter,
      //   key: "school",
      //   label: "school",
      //   // disableSortBy: true
      // },
      {
        Header: "Name",
        accessor: "uid",
        Filter: SelectColumnFilter,
        key: "name",
        label: "name",
        // disableSortBy: true
      },
      {
        Header: "No of Installments",
        accessor: "installment",
        key: "noOfInstallments",
        label: "noOfInstallments",
        Filter: SelectColumnFilter,
        // Filter: true
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        key: "status",
        label: "status",
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        // key: "paymentEndDate",
        // label: "paymentEndDate",
        Filter: DateColumnFilter2,
        Cell:(val:any)=>{
          return moment(val.value).format("DD-MM-YYYY")
        }
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: (val: any) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <a
                type="button"
          
                onClick={(e) =>
                  this.setState({ installmentDetailsId: val.value })
                }
                data-bs-toggle="modal"
                data-bs-target="#installmentEdit"
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  marginRight: "5px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                  />
                </svg>
              </a>
             
            </div>
          );
        },
      },
    ];

    const columnsWithoutAction = [
      // {
      //   Header: "Schools",
      //   accessor: "companyId",
      //   Filter: SelectColumnFilter,
      //   key: "school",
      //   label: "school",
      //   // disableSortBy: true
      // },
      {
        Header: "Name",
        accessor: "uid",
        Filter: SelectColumnFilter,
        key: "name",
        label: "name",
        // disableSortBy: true
      },
      {
        Header: "No of Installments",
        accessor: "installment",
        key: "noOfInstallments",
        label: "noOfInstallments",
        Filter: SelectColumnFilter,
        // Filter: true
      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        key: "status",
        label: "status",
      },
      {
        Header: "Due Date",
        accessor: "dueDate",
        // key: "paymentEndDate",
        // label: "paymentEndDate",
        Filter: DateColumnFilter2,
        Cell:(val:any)=>{
          return moment(val.value).format("DD-MM-YYYY")
        }
      },
      
      
    ];

    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;
    const isSalesUser = claims.drivingSchoolId === "#" && claims?.userType === "SALES"? true : false

    return (
      <>
        <TopBar title="Installments" />

        <div className="contentContainer">
          <div className="contentTop">
            <h5>Installment List</h5>
          {!isSkl &&  <Select
                        showSearch
                        style={{ width: 200, marginLeft:"50px" }}
                        placeholder="Filter School"
                        optionFilterProp="children"
                        onChange={(id) => this.setState({ filterSchoolId: id + "", })}
             
                        filterOption={(input:any, option:any) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                    </Select>}
                    <RangePicker style={{marginLeft:"10px"}} format={"DD-MM-YYYY"} onChange={(date,dateString)=>this.setState({filterDate: dateString})}/>

            <div className="tableBtn" style={{ marginLeft: "auto" }}>
              <CSVLink
                data={this.state.installments}
                headers={headers}
                className="btn btn-primary" /* style={stylesBtn()} */
              >
                Export CSV
              </CSVLink>
            </div>
          </div>
          <div className="table show">
            {/* <TableContainer columns={columns} data={this.state.filterSchoolId? this.state.installments.filter(skl=>skl.companyId === filterSchoolId): dataSource} /> */}
            <TableContainer columns={isSalesUser? columnsWithoutAction : columns } data={dataSource} />

          </div>
          <div
            className="modal fade"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Data one"
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Due Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Status</label>
                      <input type="text" className="form-control" value="1" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Paid Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Rating</label>
                      <select name="rating" id="" className="form-control">
                        <option value="">--Rating--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <table className="table table-hover">
                    <tr>
                      <th>Name</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Paid Date</th>
                      <th>Rating</th>
                    </tr>
                    <tr>
                      <td>Data one</td>
                      <td>07-05-2021</td>
                      <td>Paid</td>
                      <td>12-12-22</td>
                      <td>1</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
        {/* Modal Starts */}
        <div
          className="modal fade"
          id="installmentDetails"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="installmentDetails">
                  Installment Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div
                  className="installmentPreview"
                  style={{ width: "100%", padding: "10px" }}
                >
                  {/* <h1>{this.state.installmentDetailsId}</h1> */}
                  {this.state.installments.map((data: any, idx: any) => {
                    if (data.id === this.state.installmentDetailsId) {
                      // console.log("dataa", data);
                      // console.log('idd leng', data.installment.charAt(data.installment.length - 1))
                      const numberStr = parseInt(
                        data.installment.charAt(data.installment.length - 1)
                      );

                      return [...Array(numberStr)].map((e, i) => {
                       return <div className="classCard">
                          <span className="number">{i + 1}</span>
                          <div className="info">
                            <p className="date">{data.dueDate}</p>
                            {/* <p className="time">10:00 AM - 12:00 PM </p> */}
                          </div>
                          <p className="day">{data.installment}</p>
                        </div>;
                      });
                    }
                  })}
                </div>
              </div>

              {/* <div className="modal-footer">
             <button type="button" className="btn btn-primary">Close</button>
         </div> */}
            </div>
          </div>
        </div>
        {/* Modal Ends */}

        {/* {second modal starts} */}
        <div
          className="modal fade"
          id="installmentEdit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="installmentEdit">
                  Installment Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div
                  className="installmentPreview"
                  style={{ width: "100%", padding: "10px" }}
                >
                  {/* <h1>{this.state.installmentDetailsId}</h1> */}
                  {this.state.installments.map((data: any, idx: any) => {
                    if (data.id === this.state.installmentDetailsId) {
                      // console.log("dataa", data);
                      // console.log('idd leng', data.installment.charAt(data.installment.length - 1))
                      const numberStr = parseInt(
                        data.installment.charAt(data.installment.length - 1)
                      );

                      return [...Array(numberStr)].map((e, i) => (
                        <div className="classCard">
                          <span className="number">{i + 1}</span>
                          <div className="info">
                            <p className="date">{data.dueDate}</p>
                          </div>
                          <p className="day">{`Installment - ${i + 1}`}</p>

                          {numberStr !== i + 1 && (
                            <div className="form-check form-switch d-flex justify-content-between align-items-center">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                data-bs-dismiss="modal"
                                id={`installmentCheck-${i}`}
                                name={`installmentCheck-${i}`}
                                onClick={(e: any) => this.handleToggleCheck(e)}
                                checked={true}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`installmentCheck-${i}`}
                              >
                                Is Paid
                              </label>
                            </div>
                          )}

                        

                          {numberStr === i + 1 && !data.status && (
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`installmentCheck-${i}`}
                                name={`installmentCheck-${i}`}
                                data-bs-dismiss="modal"
                                onClick={(e)=>this.handleInstallment(e)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`installmentCheck-${i}`}
                              >
                                Is Paid
                              </label>
                            </div>
                          )}
                        </div>
                      ));
                    }
                  })}
                </div>
              </div>
              {/* <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

// export default withRouter(UserAdd);
export default InstallmentsList;

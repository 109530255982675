import React, { Component } from 'react';
import { SelectColumnFilter, DateColumnFilter } from '../TableComp/Filter';
import TableContainer from '../TableComp/TableContainer';
import { CSVLink } from "react-csv";
import TopBar from '../TopBar';
// import TableContainer from '../TableComp/TableContainer';


const headers = [
    { label: "School/Corporate", key: "school" },
    { label: "Driver Name", key: "driverName" },
    { label: "Driver Code", key: "driverCode" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
    { label: "Proxy", key: "proxy" },
    { label: "Fatigue", key: "fatigue" },
    { label: "Over Speeding", key: "overSpeeding" },
    { label: "Improper Phone", key: "improperPhone" },
];

const columns = [
    {
        Header: 'School/Corporate',
        accessor: 'school',
        // disableSortBy: true
    },
    {
        Header: 'Driver Name',
        accessor: 'driverName',
        // disableSortBy: true
    },
    {
        Header: 'Driver Code',
        accessor: 'driverCode',
        // disableFilters: true
    },
    {
        Header: 'Start Date',
        accessor: 'startDate',
        Filter: DateColumnFilter
    },
    {
        Header: 'End Date',
        accessor: 'endDate',
        Filter: DateColumnFilter
    },
    {
        Header: 'Proxy',
        accessor: 'proxy',
        Filter: true,
    },
    {
        Header: 'Fatigue',
        accessor: 'fatigue',
        Filter: true,
    },
    {
        Header: 'Over Speeding',
        accessor: 'overSpeeding',
        Filter: true,
    },
    {
        Header: 'Improper-Phone',
        accessor: 'improperPhone',
        Filter: true,
    }
]

const Data = [
    { id: '1', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2021-12-01', endDate: '2021-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum' },
    { id: '2', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2021-12-05', endDate: '2021-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum Dolar' },
    { id: '3', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2021-12-12', endDate: '2021-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum' },
    { id: '4', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2021-12-12', endDate: '2021-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum Dolar Sit' },
    { id: '5', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2022-01-01', endDate: '2022-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum Dolar Sit' },
    { id: '6', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2022-01-03', endDate: '2022-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem' },
    { id: '7', school: 'Raja Driving School', driverName: 'one', driverCode: 'praveen', startDate: '2022-12-03', endDate: '2022-12-01', proxy: 'paid', fatigue: 'asf', overSpeeding: 'afasbh', improperPhone: 'Lorem Ipsum Amit' },

]

interface BBJourneyState {
    isSearch: boolean
}


class BBJourney extends Component<{}, BBJourneyState> {

    state: BBJourneyState = {
        isSearch: false
    }

    render() {
        return (
            <>
                <TopBar title="BB Journey" />

                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>BB Journey</h5>

                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            <CSVLink data={Data} headers={headers} className='btn btn-primary'/* style={stylesBtn()} */>Export CSV</CSVLink>
                        </div>
                    </div>
                    <div className="table show">
                        <TableContainer columns={columns} data={Data} />
                    </div>
                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="Name">Name</label>
                                            <input type="text" className='form-control' value="Data one" />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Due Date</label>
                                            <input type="date" className='form-control' />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Status</label>
                                            <input type="text" className='form-control' value="1" />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Paid Date</label>
                                            <input type="date" className='form-control' />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Rating</label>
                                            <select name="rating" id="" className='form-control'>
                                                <option value="">--Rating--</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <table className='table table-hover'>
                                        <tr>
                                            <th>Name</th>
                                            <th>Due Date</th>
                                            <th>Status</th>
                                            <th>Paid Date</th>
                                            <th>Rating</th>
                                        </tr>
                                        <tr>
                                            <td>Data one</td>
                                            <td>07-05-2021</td>
                                            <td>Paid</td>
                                            <td>12-12-22</td>
                                            <td>1</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default BBJourney;
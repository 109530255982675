import React from "react";
import { DatePicker, Divider, Select, Space, Table } from "antd";
import { EditFilled, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getClaims, RRServer } from "../../../utils/lib";
import { ClassScheduleInterface, UserInterface } from "../../../types";
import dayjs from "dayjs";
import {
  DateColumnFilter,
  SelectColumnFilter,
  TimeColumnFilter,
} from "../../TableComp/Filter";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import moment from "moment";
// import LiveClasses from './LiveClasses';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface ClassSchedulesListStateProps {
  isToggle: boolean
  classSchedules: ClassScheduleInterface[];
  selectedSchedule: ClassScheduleInterface | null;
  userCode: string;
  usersHash: { [key: string]: UserInterface };
  filterSchoolId: string;
  filterUserId: string;
  schools: DrivingSchoolInterface[];
  filterDates: any;
  enquiry: any[]
}

class EnquiryMini extends React.Component<any, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      classSchedules: [],
      selectedSchedule: null,
      userCode: "",
      usersHash: {},
      filterSchoolId: "",
      filterUserId: "",
      filterDates: null,
      schools: [],
      isToggle: false,
      enquiry: []
    };
    this.getSchool = this.getSchool.bind(this);
  }
  toggleCard = (e: any) => {
    this.setState({ isToggle: !this.state.isToggle });
  }

  componentDidMount() {

    const claimsString = localStorage.getItem('claims') || '{}';
    const claims: any = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || ''

    if (claims.drivingSchoolId === '*') {

      RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }).then((result: any) => {
        // console.log('dataaaa gett',result)
        let schools = [result?.data?.data];
        this.setState({ schools })
      });

      RRServer.list({ table: 'enquiries', forCurrentUser: false }).then((result: any) => {
        console.log('dataaaa listt enq', result)

        let enquiry = result.data.data;
        this.setState({ enquiry })
      });

    } else if (claims.drivingSchoolId === '#' && claims.userType === 'CLUSTER') {
      RRServer.get({ table: 'driving_schools', id: claims.clusterID }).then((result: any) => {
        // console.log('dataaaa gett',result)
        let schools = [result?.data?.data];
        this.setState({ schools })
      });

    } else if (claims.drivingSchoolId === '#' && claims.userType === 'ASSOCIATION') {
      RRServer.get({ table: 'driving_schools', id: claims?.associationID }).then((result: any) => {
        // console.log('dataaaa gett',result)
        let schools = [result?.data?.data];
        this.setState({ schools })
      });

    }

    else {
      RRServer.list({ table: 'enquiries', forCurrentUser: false, where: [["companyId", "==", claims.drivingSchoolId]] }).then((result: any) => {
        console.log('dataaaa listt enq', result)

        let enquiry = result.data.data;
        this.setState({ enquiry })
      });
    }
  }

  getSchool(schoolId: string) {
    const school = this.state.classSchedules.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  getUser = (id: string) => {
    if (this.requesting[id]) {
      return;
    }
    this.requesting[id] = true;
    RRServer.get({ table: "users", id }).then((result: any) => {
      let usersHash = this.state.usersHash;
      usersHash[id] = result.data.data;
      this.setState({
        usersHash,
      });
    });
  };

  render() {
    const {
      classSchedules,
      filterSchoolId,
      filterUserId,
      filterDates,
      schools,
    } = this.state;
    // let { url } = this.props.match;

    let dataSource = classSchedules.map((school) => ({
      key: school.id,
      ...school,
    }));

    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.companyId === filterSchoolId);
    }
    if (filterUserId) {
      dataSource = dataSource.filter((d) => d.studentId === filterUserId);
    }
    if (filterDates) {
      dataSource = dataSource.filter((ds) => {
        const [d, m, y] = ds.date.split("/");
        const [df1, mf1, yf1] = filterDates[0].format("DD/MM/YYYY").split("/");
        const [df2, mf2, yf2] = filterDates[1].format("DD/MM/YYYY").split("/");

        const sd = dayjs(new Date(+y, +m - 1, +d, 0, 0, 0, 0));
        const fd1 = dayjs(new Date(+yf1, +mf1 - 1, +df1, 0, 0, 0, 0));
        const fd2 = dayjs(new Date(+yf2, +mf2 - 1, +df2, 0, 0, 0, 0));

        return (
          sd.isSame(dayjs(fd1)) ||
          sd.isSame(dayjs(fd2)) ||
          (sd.isAfter(dayjs(+filterDates[0])) &&
            sd.isBefore(dayjs(+filterDates[1])))
        );
      });
    }


    let uniqueStudentsHash: { [key: string]: ClassScheduleInterface } = {};
    classSchedules.map((csh) => (uniqueStudentsHash[csh.studentId] = csh));
    const uniqueStudents = Object.values(uniqueStudentsHash);

    const getCurrentHours = () => {
      const d = new Date();
      const hour = d.getHours();
      // console.log('dd', hour)
      return hour > 12
        ? `${hour - 12}:00 PM to ${hour + 1 - 12}:00 PM`
        : `${hour}:00 AM to ${hour + 1}:00 AM`;
    };

    const liveClasses = this.state?.classSchedules.filter((element) => {
      if (element.date === GetFormattedDate()) {
        return element
      }
    })


    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month.toString().length === 1 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day.toString().length === 1 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return dayFormat + "/" + monthFormat + "/" + year;
    }



    return (
      <div className={this.state.isToggle ? `dashBotCard1 enquiry show` : `dashBotCard1 enquiry hide`} onClick={(e) => { this.toggleCard(e) }}>
        <div className="linkTo">
          <div className="titleTag">
            <h4>Enquiry</h4>
            <div className="arrow"></div>
          </div>

          <span className="">{GetFormattedDate()}</span>

          <a href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              className="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
              />
            </svg>
          </a>
        </div>
        <div className="tableA">

          <table className="table table-borderless">
            <thead>
              <tr>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Remarks</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state?.enquiry.filter((enq: any) => {
                if (enq.reminderDate.includes("T")) {
                  console.log(moment(enq.reminderDate.slice(0, 10)).format("DD/MM/YYYY") ,' yeet')
                  return moment(enq.reminderDate.slice(0, 10)).format("DD/MM/YYYY") === GetFormattedDate()

                } else {

                  return moment(enq.reminderDate.slice(0, 10)).format("DD/MM/YYYY") === GetFormattedDate()

                }
              }).map((enq: any, idx: any) => {
                return <tr key={idx}>
                  <td>{enq.name}</td>
                  <td>{enq.mobile}</td>
                  <td>{enq.remarks}</td>
                  {/* <td>{enq.status === 0 && "Cancel Enquiry"}</td> */}
                  {enq.status === 0 && <td>Cancel Enquiry</td>}
                  {enq.status === 1 && <td>Enquired</td>}
                  {enq.status === 2 && <td>Joined</td>}
                  {enq.status === 3 && <td>Re-Remainder</td>}
                  {enq.status === 4 && <td>Not Interested</td>}
                  {enq.status === 5 && <td>No Response</td>}
                  {/* <td>{enq.reminderDate.includes("T") ? moment(enq.reminderDate.slice(0,10)).format("DD/MM/YYYY") : enq.reminderDate.slice(0,10)}</td> */}
                </tr>
              })}

            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EnquiryMini;

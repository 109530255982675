import { Modal } from 'antd';
import React, { Component } from 'react';
import FormComponent from '../../FormComponent/FormComponent';
import styles from './NewsCard.module.css'

interface NewsCardState {
    formToggle: boolean;
    isModalVisible:boolean
}

interface NewsCardProps {
    language: string,
    destination: string,
    content: string,
    title: string,
    date: string,
    image?: string,
    video?: string
}




class NewsCard extends Component<NewsCardProps, NewsCardState>{

    state: NewsCardState = {
        formToggle: false,
        isModalVisible: false
    }

    

     showModal = () => {
        this.setState({isModalVisible: true});
      };
    
       handleOk = () => {
        this.setState({isModalVisible: false});
      };
    
       handleCancel = () => {
        this.setState({isModalVisible: false});
      };

    render() {
        return (
            <>
                <div className={styles.venueCard} style={{ cursor: "pointer" }} onClick={this.showModal}>
                <img src={this.props.image} alt="news image" />
                <div className={styles.info}>
                    <h2 className={styles.venueName}>{`${this.props.title.slice(0,10)}...`}</h2>
                    <p className={styles.venueLocation}>{`${this.props.content.slice(0,15)}...`}</p> &bull; <p className={styles.venueLocation}>{this.props.language}</p>  &bull; <p className={styles.venueLocation}>{this.props.destination}</p>
                    <p className={`${styles.venueLocation} ${styles.date}`}>{this.props.date}</p>
                    <a href={styles.detailsPage}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" className="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                    </a>
                </div>

            </div>

            <Modal  visible={this.state.isModalVisible}  onCancel={this.handleCancel} style={{padding:"0px"}} footer={null} keyboard={true} mask={true} maskClosable={true}>
            <div className="newsWrapper">
                                <div className="newsImg">
                                    {this.props.image && <img src={this.props.image} alt="news image" style={{width:"100%"}} />}
                                </div>
                                <div className="newsTitle" style={{padding:"10px"}}>
                                    <p style={{borderLeft:"3px solid #7D5AC0", padding:"5px", fontSize:"18px"}}>{this.props.title}</p>
                                </div>
                                <div className="newsDesc" style={{padding:"10px",fontSize:"14px"}}>
                                    <p>{this.props.content}</p>
                                </div>
                                {this.props.video && <div className="videoContainer">
                                   
                                <iframe width="420" height="315"
                                    src={this.props.video}>
                                    </iframe>
                                </div>}
                            </div>
            </Modal>
                {/* <!-- Modal --> */}
                {/* <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="newsWrapper">
                                <div className="newsImg">
                                    {this.props.image && <img src={this.props.image} alt="news image" style={{width:"100%"}} />}
                                </div>
                                <div className="newsTitle" style={{padding:"10px"}}>
                                    <p style={{borderLeft:"3px solid #7D5AC0", padding:"5px", fontSize:"18px"}}>{this.props.title}</p>
                                </div>
                                <div className="newsDesc" style={{padding:"10px",fontSize:"14px"}}>
                                    <p>{this.props.content}</p>
                                </div>
                                {this.props.video && <div className="videoContainer">
                                    <video controls style={{width:"100%"}} >

                                        <source src="/media/cc0-videos/flower.webm"
                                            type="video/webm" />

                                        <source src="/media/cc0-videos/flower.mp4"
                                            type="video/mp4" />

                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                </div>}
                            </div>
                
                        </div>
                    </div>
                </div > */}
            </>
        );
    }
}

export default NewsCard;
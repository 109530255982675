import React, { Component } from 'react';
import { SelectColumnFilter, DateColumnFilter, Filter, TimeColumnFilter } from '../TableComp/Filter';
import TableContainer from '../TableComp/TableContainer';
import { callOnServer, RRServer } from "../../utils/lib";

// import TableContainer from '../TableComp/TableContainer';
import { CSVLink } from "react-csv";
import TopBar from '../TopBar';
import { AnyMxRecord } from 'dns';
import moment from 'moment';
import { Button } from 'reactstrap';
import { message } from 'antd';






interface ReschedulingState {
    isAvailable: boolean,
    isSearch: boolean,
    isReschedule: boolean,
    classes: any[],
    reschedulingData: any[],
    selectedData: any,
    selectedDate: string,
    selectedTime: string,
    isSlotThere: boolean,
    selectedInstructor: any,
    users: any,
    selectedStudent: any,
    isLoading: boolean,
    isSalesUser: boolean
}


class Rescheduling extends Component<{}, ReschedulingState> {

    state: ReschedulingState = {
        isAvailable: false,
        isSearch: false,
        isReschedule: false,
        classes: [],
        reschedulingData: [],
        selectedData: null,
        selectedDate: "",
        selectedTime: "",
        isSlotThere: false,
        selectedInstructor: null,
        users: [],
        selectedStudent: null,
        isLoading: false,
        isSalesUser: false
    }

    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims: any = JSON.parse(claimsString);
        const isSalesUser = claims.drivingSchoolId === "#" && claims?.userType === "SALES"? true : false
        this.setState({isSalesUser})
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        if (claims.drivingSchoolId !== "*") {
            RRServer.list({
                table: "class_schedules",
                forCurrentUser: false,
                where: [["companyId", "==", claims.drivingSchoolId]],
            }).then((data) => {
                const filteredArr = data?.data.data.filter((cls: any) => {
                    return cls?.rescheduleRequest
                })
                this.setState({ classes: data?.data.data, reschedulingData: filteredArr })
            })
            RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', claims.drivingSchoolId]] }).then((res: any) => {
                this.setState({ users: res.data.data })
            })

        } else {
            RRServer.list({ table: "class_schedules", forCurrentUser: false }).then((data: any) => {
                const filteredArr = data.data.data.filter((cls: any) => {
                    return cls?.rescheduleRequest
                })
                this.setState({ classes: data?.data.data, reschedulingData: filteredArr })
            })
            RRServer.list({ table: "users", forCurrentUser: false }).then((res: any) => {
                this.setState({ users: res.data.data })
            })

        }
    }

    handleSelectedDate = async (id: any) => {
        // console.log('iddd', id)
        const selectedData = this.state.reschedulingData.filter((cls: any) => {
            return cls.id === id
        })
        await this.setState({ selectedData: selectedData[0] })

        await this.getInstructors(this.state.selectedData.companyId, this.state.selectedData.instructorId)
        this.checkSlotAvailability(this.state.selectedData?.date, this.state.selectedData.rescheduleRequestSlot)

    }
    convertTime12to24 = (time12h: any) => {
        const [time, modifier] = time12h.split(" ");

        let [hours, minutes] = time.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM" || modifier === "pm") {
            hours = parseInt(hours, 10) + 12;
        }

        return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
    };

    getInstructors(companyId: string, instructorId?: string) {

        return RRServer.list({
            table: "users",
            where: [
                ["companyId", "==", companyId],
                ["type", "==", "DRIVING_SCHOOL_INSTRUCTOR"],
            ],
            forCurrentUser: false,
        }).then((result: any) => {

            this.setState({
                selectedInstructor: result?.data.data.filter((data: any) => {
                    return data.id === this.state.selectedData?.instructorId
                })
            })
            return result?.data.data
        });
    }
    tConv24 = (time24: any) => {
        var ts = time24;
        var H = ts.substr(0, 2);
        var h: any = (H % 12) || 12;
        h = (h < 10) ? ("0" + h) : h;  // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? " AM" : " PM";
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
    };

    checkSlotAvailability = (date: any, time: any) => {
        const { selectedInstructor } = this.state

        const day = moment(date, 'DD/MM/YYYY').format("dddd").slice(0, 3)
        const classArr = this.state.classes.filter(
            (clss: any) => {
                return (
                    clss.date === date &&
                    clss.time === time &&
                    clss.instructorId === selectedInstructor[0]?.id
                );
            }
        );
        // console.log('classArrr', classArr)

        if (selectedInstructor[0]?.slots[day].some((timee: any) => timee === time)) {
            if (classArr.length >= 4) {
                this.setState({ isSlotThere: false })

            } else if (classArr.length === 0) {
                this.setState({ isSlotThere: true })
            } else {
                this.setState({ isSlotThere: true })
            }
        } else {
            this.setState({ isSlotThere: false })
        }
    }

    showAvailableSlots = (date: any) => {

        const instructorClasses = this.state.selectedInstructor ? this.state.selectedInstructor[0] : ""

        const day = moment(this.state.selectedDate, 'YYYY-MM-DD').format('ddd')

        const slots = instructorClasses && instructorClasses?.slots[`${day}`]

        return slots

    }

    updateSlots = (num : number) => {
        this.setState({isLoading: true})
        const { selectedData, selectedDate, selectedTime } = this.state
        // const selectedStudent = selectedData.studentId
        const selectedStudent = this.state.users.filter((user: any) => {
            return user.id === selectedData?.studentId
        })
        const selectedInstructor = this.state.users.filter((user: any) => {
            return user.id === selectedStudent[0].instructorId
        })
        const updatedClassSchedulesStudent = selectedStudent[0]?.classSchedules.map((cls: any) => {
            const date = moment(selectedDate).format("DD/MM/YYYY")
            if (cls.date === selectedData?.date && cls.time === selectedData?.time) {
                return { date: num === 0? selectedData?.date : date, time: num === 0? selectedData?.rescheduleRequestSlot : selectedTime }
            } else {
                return cls
            }
        })

        const studentObjToUpdate = {
            ...selectedStudent[0],
            classSchedules: updatedClassSchedulesStudent
        }

        const instructorObjToUpdate = {
            ...selectedInstructor[0],
            classSchedules: {
                ...selectedInstructor[0].classSchedules,
                [selectedStudent[0].id]: updatedClassSchedulesStudent
            }
        }

        const rescheduleObjToUpdate = {
            ...selectedData,
            rescheduleRequest: false,
            time: num === 0? selectedData?.rescheduleRequestSlot : this.state.selectedTime,
            date: num === 0? selectedData.date : this.state.selectedDate 
        }



        Promise.all([RRServer.update({
            table: RRServer.Tables.USERS,
            id: selectedStudent[0]?.id,
            ...studentObjToUpdate,
        }),
        RRServer.update({
            table: RRServer.Tables.USERS,
            id: selectedInstructor[0]?.id,
            ...instructorObjToUpdate,
        }),
        RRServer.update({
            table: RRServer.Tables.CLASS_SCHEDULES,
            id: selectedData?.id,
            ...rescheduleObjToUpdate,
        })]).then((res)=>{
            this.setState({isLoading: false})
            message.success("Class successfully updated", 3, ()=>window.location.reload())
            
        })
    }

    render() {

        const { reschedulingData, selectedData, isSlotThere } = this.state

        const slotss = this.showAvailableSlots(this.state.selectedDate)
        // console.log('slooots', slotss)

        const headers = [
            { label: "Schools", key: "school" },
            { label: "Instructor", key: "instructor" },
            { label: "User", key: "user" },
            { label: "No Of Installments", key: "noOfInstallments" },
            { label: "Status", key: "status" },
            { label: "Payment Start Date", key: "proposedDate" },
            { label: "Payment End Date", key: "proposedTime" },
            { label: "Action", key: "action" }
        ];
        const columns = [
            {
                Header: 'Schools',
                accessor: 'school',
                Filter: SelectColumnFilter,
                key: 'school',
                label: 'school'
                // disableSortBy: true
            },
            {
                Header: 'Instructor',
                accessor: 'instructor',
                Filter: SelectColumnFilter,
                key: 'instructor',
                label: 'instructor'
                // disableSortBy: true
            },
            {
                Header: 'User',
                accessor: 'student',
                Filter: SelectColumnFilter,
                key: 'student',
                label: 'student'
                // disableSortBy: true
            },
            {
                Header: 'Scheduled Date',
                accessor: 'date',
                key: 'date',
                label: 'date',
                Filter: DateColumnFilter,
                // Cell : (val: any) => {
                //     return moment(val.value).format('DD/MM/YYYY')
                //   }
            },
            {
                Header: 'Scheduled Time',
                accessor: 'time',
                key: 'time',
                label: 'time',
                Filter: TimeColumnFilter
            },

            {
                Header: 'Proposed Time',
                accessor: 'rescheduleRequestSlot',
                key: 'rescheduleRequestSlot',
                label: 'rescheduleRequestSlot',
                Filter: TimeColumnFilter
            },
            {
                Header: 'Action',
                accessor: 'id',
                key: 'id',
                label: 'id',
                Filter: true,
                Cell: (val: any) => {
                    return <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#rescheduleModal" onClick={() => this.handleSelectedDate(val.cell.value)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffffff" className="bi bi-pencil-square" viewBox="0 0 16 16">
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                        </svg>
                    </button>
                }
            },
        ] 

        const columnsWithNoAction = [
            {
                Header: 'Schools',
                accessor: 'school',
                Filter: SelectColumnFilter,
                key: 'school',
                label: 'school'
                // disableSortBy: true
            },
            {
                Header: 'Instructor',
                accessor: 'instructor',
                Filter: SelectColumnFilter,
                key: 'instructor',
                label: 'instructor'
                // disableSortBy: true
            },
            {
                Header: 'User',
                accessor: 'student',
                Filter: SelectColumnFilter,
                key: 'student',
                label: 'student'
                // disableSortBy: true
            },
            {
                Header: 'Scheduled Date',
                accessor: 'date',
                key: 'date',
                label: 'date',
                Filter: DateColumnFilter,
                Cell : (val: any) => {
                    return moment(val.value).format('DD/MM/YYYY')
                  }
            },
            {
                Header: 'Scheduled Time',
                accessor: 'time',
                key: 'time',
                label: 'time',
                Filter: TimeColumnFilter
            },

            {
                Header: 'Proposed Time',
                accessor: 'rescheduleRequestSlot',
                key: 'rescheduleRequestSlot',
                label: 'rescheduleRequestSlot',
                Filter: TimeColumnFilter
            },
 
        ]

        return (
            <>
                <TopBar title="Rescheduling" />

                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>Rescheduling List</h5>

                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            <CSVLink data={reschedulingData} headers={headers} className='btn btn-primary'/* style={stylesBtn()} */>Export CSV</CSVLink>
                        </div>
                    </div>
                    <div className="table show">
                        <TableContainer columns={this.state.isSalesUser? columnsWithNoAction : columns} data={reschedulingData} />
                    </div>
                    {/* Modal Starts */}
                    <div className="modal fade" id="rescheduleModal" data-bs-keyboard="false" aria-labelledby="rescheduleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="rescheduleModalLabel">Reschedule Time and Date</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p>School : <span> {selectedData?.school}</span></p>
                                    <div className="row">
                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="text" name="school" placeholder="School" maxLength={200} id="school" value={selectedData?.instructor} readOnly />
                                            <label htmlFor="school" className="px-4">Instructor</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="text" name="name" placeholder="User" maxLength={200} id="user" value={selectedData?.student} readOnly />
                                            <label htmlFor="user" className="px-4">User</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="text" name="scheduledDate" placeholder="Scheduled Date" maxLength={200} id="scheduledDate" value={selectedData?.date} readOnly />
                                            <label htmlFor="scheduledDate" className="px-4">Scheduled Date</label>
                                        </div>

                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="time" name="scheduledTime" placeholder="Scheduled Time" maxLength={200} id="scheduledTime" value={selectedData?.time} readOnly />
                                            <label htmlFor="scheduledTime" className="px-4">Scheduled Time</label>
                                        </div>

                                        <div className={`col-md-12  col-sm-12 mb-2 form-floating d-inline-block`}>
                                            <select className={`form-select form-control`} name='userType' id='userType' aria-label="Floating label select example" required={true} disabled>
                                                <option value={selectedData?.rescheduleRequestSlot} selected> {selectedData?.rescheduleRequestSlot} </option>

                                                {/* <option value={'10 AM - 12AM'}>10 AM - 12AM</option>
                                                <option value={'1PM - 2PM'}>1PM - 2PM</option>
                                                <option value={'3PM - 4PM'}>3PM - 4PM</option> */}

                                            </select>
                                            <label htmlFor='userType' className={`px-4`} >Requested Time</label>
                                        </div>
                                        {/* 
                                        <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" type="time" name="availableDate" placeholder="Available Date" maxLength={200} id="availableDate" />
                                            <label htmlFor="availableDate" className="px-4">Rescheduled Time</label>
                                        </div> */}


                                        <p className={isSlotThere ? 'available' : 'notAvailable'}>{isSlotThere ? 'Available' : 'Not Available'}</p>

                                        <div className="buttonWrapper" style={{ display: "flex", justifyContent: "space-around" }}>

                                            <button type="button" className="btn btn-primary" disabled={!isSlotThere || this.state.isLoading} onClick={()=>this.updateSlots(0)}>Accept</button>
                                            <button type="button" className="btn btn-primary" disabled={isSlotThere} onClick={() => this.setState({ isReschedule: true })}>Reschedule</button>
                                        </div>


                                        {!isSlotThere && this.state.isReschedule && <><div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                            <input className="form-control" onChange={(e) => this.setState({ selectedDate: e.target.value })} type="date" name="availableTime" min={new Date().toISOString().split('T')[0]} placeholder="Available Time" maxLength={200} id="availableTime" />
                                            <label htmlFor="availableTime" className="px-4">Available Date</label>
                                        </div>
                                            <div className={`col-md-6   col-sm-12 mb-2 form-floating d-inline-block`}>
                                                <select className={`form-select form-control`} name='userType' value={this.state.selectedTime} onChange={(e) => this.setState({ selectedTime: e.target.value })} id='userType' aria-label="Floating label select example" required={true}>

                                                    {slotss && slotss.map((slots: any, idx: any) => {
                                                        return <option value={slots}  >{slots}</option>
                                                    })}
                                                </select>
                                                <label htmlFor='userType' className={`px-4`}>Available Time</label>
                                            </div>
                                            {/* <div className="mb-3 form-floating  inline-block col-md-6 col-sm-6">
                                                <input className="form-control" type="time" name="availableDate" placeholder="Available Date" maxLength={200} id="availableDate" />
                                                <label htmlFor="availableDate" className="px-4">Available Time</label>
                                            </div>  */}
                                        </>}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <Button type="button" loading={this.state.isLoading} onClick={() => this.updateSlots(1)}>
                                        Submit
                                    </Button>
                                    {/* <button type="button" className="btn btn-primary" onClick={() => this.updateSlots()}>Submit</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Ends */}
                </div>
            </>
        );
    }
}

export default Rescheduling;
import React, { Component } from 'react';
import styles from './FormComponent.module.css';
import Field from './Field';
import { RRServer } from '../../utils/lib';
import { message } from 'antd';

interface FormComponentState {
    values: {
        association: string
        cluster: string
        code: string,
        credits: string,
        drivingSchoolName: string,
        password: string,
        pricePerCode: string,
        profile: string,
        sosNumber: string
    },
    schools: any[]
}



interface FormComponentProps {
    fields: {}[],
    toggleForm?: (e: any) => void,
    handleValues: any,
    buttonText: string
}

class FormComponent extends Component<FormComponentProps, FormComponentState> {

    state: FormComponentState = {
        values: {
            association: "",
            cluster: "",
            code: "",
            credits: "",
            drivingSchoolName: "",
            password: "",
            pricePerCode: "",
            profile: "",
            sosNumber: ""
        },
        schools: []
    }

    componentDidMount() {
        RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {

            let schools = result.data.data;
            this.setState({ schools })
        });
    }

    handleValue = ({ name, value }: any) => {
        this.setState({ values: { ...this.state.values, [name]: value } })
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.state.schools.some((skl: any) => skl.code === this.state.values.code)){
            message.error("Code Already exists. Kindly use different CODE", 2)
        }else{
            this.props.handleValues(this.state.values)
            {this.props.toggleForm && this.props.toggleForm(e)}
        }
    
    }

    render() {
        return (
            <div className={styles.formWrapper}>
                <form className='container needs-validation' onSubmit={this.handleSubmit}>

                    <div className="row">

                        {this.props.fields.map((field: any, index: any) => {
                            return <Field
                                key={index}
                                type={field.type}
                                name={field.name}
                                label={field.label}
                                isMandatory={field.isMandatory}
                                options={field.options}
                                isCode={field?.isCode}
                                size={6}
                                value={field.value}
                                gridSm={field.gridSm}
                                gridMd={field.gridMd}
                                handleValue={this.handleValue}
                                validation={field.validation} />
                        })}

                    </div>

                    <div className="text-right">
                        <button className={styles.btn} type='submit'>{this.props.buttonText}</button>
                    </div>
                </form>
            </div>
        );
    }
}

export default FormComponent;
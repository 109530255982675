import React, { Component } from 'react';
import { SelectColumnFilter, DateColumnFilter, Filter } from '../TableComp/Filter';
import TableContainer from '../TableComp/TableContainer';
import TopBar from '../TopBar';
// import TableContainer from '../TableComp/TableContainer';


const columns = [
    {
        Header: 'Schools',
        accessor: 'school',
        Filter: SelectColumnFilter,
        // disableSortBy: true
    },
    {
        Header: 'Name',
        accessor: 'name',
        Filter: SelectColumnFilter,
        // disableSortBy: true
    },
    {
        Header: 'Code',
        accessor: 'code',
        // disableFilters: true
    },
    {
        Header: 'Action',
        accessor: 'action',
        Filter: true
    },
]

const Data = [
    {
        isClickable: true, id: '1', school: 'Raja Driving School', name: 'one', code: 'praveen', action: <div style={{ display: 'flex', justifyContent: "space-around" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                <path data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ cursor: 'pointer' }} d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ cursor: 'pointer' }} d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg>
        </div>
    }
]


class Assessments extends Component {
    render() {
        return (
            <>
                <TopBar title="Assesments"/>
                <div className='contentContainer'>
                    <div className="table show">
                        <TableContainer columns={columns} data={Data} />
                    </div>

                    {/* Modal Starts */}
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">Quiz details</h5>
                                    {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                </div>
                                <div className="modal-body">
                                    <div className="classCard">
                                        <span className="number">1</span>
                                        <div className="info">
                                            <p className='date'>Time</p>
                                        </div>
                                        <p className="day">Correct</p>
                                    </div>
                                    <div className="classCard">
                                        <span className="number">2</span>
                                        <div className="info">
                                            <p className='date'>Time</p>
                                        </div>
                                        <p className="day">Incorrect</p>
                                    </div>
                                    <div className="classCard">
                                        <span className="number">3</span>
                                        <div className="info">
                                            <p className='date'>Time</p>
                                        </div>
                                        <p className="day">Correct</p>
                                    </div>
                                    <div className="classCard">
                                        <span className="number">4</span>
                                        <div className="info">
                                            <p className='date'>Time</p>
                                        </div>
                                        <p className="day">Incorrect</p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    {/* <button type="button" className="btn btn-primary">Understood</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal Ends */}

                </div>
            </>
        );
    }
}

export default Assessments;
import { Row, Col, Card, Tabs, Skeleton, Input } from "antd";
import Statistic from "antd/lib/statistic";
import firebase from "firebase";
import React from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  UserInterface,
} from "../../../types";

import dayjs from "dayjs";
/*import DrivingSchoolAdd from "../DrivingSchools/DrivingSchoolAdd";*/

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
/*import Style from './StudentStats.module.css';*/
import { RRServer } from "../../../utils/lib";
// import APToolbar from "./AP-Buttons-Toolbar";
import Search from "antd/lib/input/Search";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import { SelectColumnFilter } from "../../TableComp/Filter";
import StudentStatClone from "./StudentStatsList";
import StudentStatsList from "./StudentStatsList";
// import StudentStatClone from "./StudentStatClone";
// import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import ReactToPrint from "react-to-print";
import StatsToPrint from "./StatsToPrint";
import moment from "moment";

const { TabPane } = Tabs;

// const CORPORATE_CODE = "RKkKTihRtahnXPFWUN8O";

interface DashProps {
  schools: DrivingSchoolInterface[];
  users: UserInterface[];
  usersFiltered: UserInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;
  currentStudentId: string;
  isDetails: boolean;
  user: any;
  scores: any;
  classes: any;
}

class StudentStats extends React.Component<any, DashProps> {
  componentRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      schools: [],
      users: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
      usersFiltered: [],
      currentStudentId: "",
      isDetails: false,
      user : [],
      scores : [],
      classes: []
    };
  }

  GetFormattedDate() {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var monthFormat = month.toString().length === 1 ? `0${month}` : month
    var day = todayTime.getDate();
    var dayFormat = day.toString().length === 1 ? `0${day}` : day
    var year = todayTime.getFullYear();
    return dayFormat + "/" + monthFormat + "/" + year;
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

   

    if (claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#") {

      Promise.all([
        RRServer.list({
          table: RRServer.Tables.USERS,
          forCurrentUser: false,
        }),
        RRServer.list({ table: 'driving_schools', forCurrentUser: false })
      ])
        .then((r: any) => {
          const users = r[0].data.data.sort((a: UserInterface, b: UserInterface) =>
            a?.code?.localeCompare(b.code)
          );
          const schools = r[1].data.data


          const schoolHash: any = {}
          schools.map((skl: any) => schoolHash[skl.id] = skl)

          console.log(schoolHash, 'sklHash')

          const tempFilter = users.map((user: any) => {
            user.companyId = schoolHash[user?.companyId] ? schoolHash[user.companyId]?.drivingSchoolName : ""
            return user
          }).filter((user: any) => {
            return user.type === "DRIVING_SCHOOL_USER"
          })

          this.setState({
            schools,
            users,
            usersFiltered: tempFilter
          });
        });




    } else {
      RRServer.list({
        table: RRServer.Tables.USERS,
        forCurrentUser: false,
        where: [["companyId", "==", claims.drivingSchoolId]],
      }).then((r: any) => {
        const users = r.data.data.sort((a: UserInterface, b: UserInterface) =>
          a.code.localeCompare(b.code)
        );
        this.setState({
          users, usersFiltered: users.filter((user: any) => {
            return user.type === "DRIVING_SCHOOL_USER"
          })
        });
      });
    }


  }

  onSearch = (text: string) => {
    const { users } = this.state;

    const usersFiltered = users.filter(
      (u) => u.name.toLowerCase().indexOf(text.toLowerCase()) != -1
    );

    this.setState({ usersFiltered });
  };







  render() {
    const { users, usersFiltered } = this.state;

    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    const isSuperAdmin = claims.drivingSchoolId === "*" ? true : false
    const isSalesUser = claims.drivingSchoolId === "#" && claims?.userType === "SALES" ? true : false

    const columns = isSuperAdmin || isSalesUser ? [
      {
        Header: "Corporate ID",
        accessor: "code",
        // Filter: SelectColumnFilter,
        // disableSortBy: true
        Cell: (val: any) => {
          return this.state.usersFiltered.map((user) => {
            if (val.value === user.code) {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  key={user.id}
                  onClick={() => {
                    this.setState({
                      currentStudentId: user.id,
                      isDetails: true,
                    });
                  }}
                >
                  {user.code}
                </p>
              );
            }
          });
        },
        // Cell: (val:any) =>   {return usersFiltered.map(user => <a key={user.id} href={'/student-stat/' + user.id}>{user.code}</a>
      },
      {
        Header: "Student Name",
        accessor: "name",
      },
      {
        Header: "School Name",
        accessor: "companyId",
      }
    ] : [
      {
        Header: "Corporate ID",
        accessor: "code",
        Filter: SelectColumnFilter,
        // disableSortBy: true
        Cell: (val: any) => {
          return this.state.usersFiltered.map((user) => {
            if (val.value === user.code) {
              return (
                <p
                  style={{ cursor: "pointer" }}
                  key={user.id}
                  onClick={() => {
                    this.setState({
                      currentStudentId: user.id,
                      isDetails: true,
                    });
                  }}
                >
                  {user.code}
                </p>
              );
            }
          });
        },
        // Cell: (val:any) =>   {return usersFiltered.map(user => <a key={user.id} href={'/student-stat/' + user.id}>{user.code}</a>
      },
      {
        Header: "Student Name",
        accessor: "name",
      },
    ];




    return (
      <>
        {/* <PrintProvider> */}


        <TopBar title="Student Stats" />

        {/* <NoPrint> */}

        <div>

        </div>

        <div className="contentContainer">
          {<div className="contentTop">
            {!this.state.isDetails && <h5>Student Stats</h5>}

            {this.state.isDetails && <div className="tableBtn" style={{ marginLeft: "auto" }}>
          
              {<button
                className="btn btn-primary"
                onClick={(e) => this.setState({ isDetails: false, currentStudentId: "" })}
              >
                {this.state.isDetails && "BACK"}
              </button>}
            </div>}
          </div>}

          <div className="table show">
            {!this.state.isDetails && (
              <TableContainer
                columns={columns}
                data={this.state.usersFiltered}
              />
            )}
            {/* <Print single name="foo"> */}
            {this.state.isDetails && this.state.currentStudentId && (
              <StudentStatsList studentId={this.state.currentStudentId} />
            )}
            {/* </Print> */}

          </div>

        </div>
        ]{" "}
        {/* </NoPrint> */}
        {/* </PrintProvider> */}
      </>
    );
  }
}

export default StudentStats;

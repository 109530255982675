import  firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'


import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// ****** Dev **********

// const firebaseConfig = {
//   apiKey: "AIzaSyDu5tLf40F_Q_ljvP-gjQLL3tqj5OK1Da8",
//   authDomain: "roadrakshak-ds-dev.firebaseapp.com",
//   projectId: "roadrakshak-ds-dev",
//   storageBucket: "roadrakshak-ds-dev.appspot.com",
//   messagingSenderId: "615333576533",
//   appId: "1:615333576533:web:89eedac96d79c5f9f579dc",
//   measurementId: "G-N773YJDM0L"
// };


// ****** Prod ***********

const firebaseConfig = {
  apiKey: "AIzaSyC141FuI5M8cRqBPlyFHIXjrJWNIC1knIE",
  authDomain: "roadrakshak-ds.firebaseapp.com",
  databaseURL: "https://roadrakshak-ds-default-rtdb.firebaseio.com",
  projectId: "roadrakshak-ds",
  storageBucket: "roadrakshak-ds.appspot.com",
  messagingSenderId: "757673122157",
  appId: "1:757673122157:web:6fd44c0ea97b71c640e264",
  measurementId: "G-EFG7P9P5JD"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
firebase.auth().languageCode = 'en';
  
export default firebase;



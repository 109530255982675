import React from "react";
import firebase from "../../../utils/Firebase";
import { Divider, Select, Space, Table } from "antd";
import { EditFilled, EyeFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import TableContainer from "../../TableComp/TableContainer";
// import FormComponent from '../../FormComponent/FormComponent';
import { callOnServer, RRServer } from "../../../utils/lib";
import TopBar from "../../TopBar";
import { SelectColumnFilter } from "../../TableComp/Filter";
// import DrivingSchoolAdd from './DrivingSchoolAdd';
// import DrivingAdd from './DrivingAdd';

const { Option } = Select;

interface DrivingSchoolInterface {
  pricePerCode: string | number | readonly string[] | undefined;
  sosNumber: string | number | readonly string[] | undefined;
  password: string | number | readonly string[] | undefined;
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface UserStateProps {
  schools: DrivingSchoolInterface[];
  selectedSchool: DrivingSchoolInterface | null;
  userCode: string;
  filterSchoolId: string;
  isToggle: boolean;
  isSearch: boolean;
  schoolsObj: any;
  currentLoggedInSchool: any;
  password: {
    password: string,
    confirmPassword: any
  }
}

class Profile extends React.Component<any, UserStateProps> {

  unregisterAuthObserver: any;
  
  constructor(props: any) {
    super(props);
    this.state = {
      schools: [],
      selectedSchool: null,
      userCode: "",
      filterSchoolId: "",
      isToggle: true,
      isSearch: false,
      schoolsObj: null,
      currentLoggedInSchool: null,
      password: {
        password: "",
        confirmPassword: ""
      }
    };
    this.getSchool = this.getSchool.bind(this);
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    if (claims.drivingSchoolId !== "*") {
      RRServer.get({
        table: "driving_schools",
        id: claims.drivingSchoolId,
      }).then((result: any) => {
        // console.log("dataaaa get", result);
        let schools = [result?.data?.data];
        this.setState({ schools });
      });
    } else {
      RRServer.list({ table: "driving_schools", forCurrentUser: false }).then(
        (result: any) => {
          // console.log("data list", result);
          let schools = result.data.data;
          this.setState({ schools });
        }
      );
    }

    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user: any) => {
        if (!!user) {
          const uid = user.uid;
          RRServer.list({
            table: RRServer.Tables.DRIVING_SCHOOLS,
            forCurrentUser: false,
            where: [["firebaseUserUid", "==", uid]],
          })
            .then((r: any) =>
              this.setState({ currentLoggedInSchool: r?.data.data[0] })
            )
            .catch((r: any) => console.log(r));
          // this.setState({ displayName: user.displayName, corporate })
        } else {
          // this.setState({ isSignedIn: false })
        }
      });

    if (!claims.drivingSchoolId) {
      alert("Driving school Not Found");
      return;
    }

    if (claims.drivingSchoolId === "*") {
      Promise.all([
        RRServer.list({
          table: RRServer.Tables.DRIVING_SCHOOLS,
          forCurrentUser: false,
        }),
        RRServer.list({
          table: RRServer.Tables.USERS,
          forCurrentUser: false,
        }),
        RRServer.list({
          table: "corporate_codes",
          forCurrentUser: false,
        }),
      ]).then((results: any) => {
        this.setState({
          // schools: results[0].data.data,
          // users: results[1].data.data,
          // corporateCodesCount: results[2].data.data.length,
          // corporateCodes: results[2].data.data,
          // drivingSchoolCodesCount: results[2].data.data.filter(({ type }: { type: string }) => type ? type.indexOf('DRIVING_') != -1 : false).length,
          // studentsCount: results[2].data.data.filter(({ type }: { type: string }) => type ? (type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1) : false).length,
          // instructorCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
          // adminCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
        });
      });
    } else {
      Promise.all([
        RRServer.get({
          table: RRServer.Tables.DRIVING_SCHOOLS,
          id: claims.drivingSchoolId,
        }),
        RRServer.list({
          table: RRServer.Tables.USERS,
          forCurrentUser: false,
          where: [["drivingSchoolId", "==", claims.drivingSchoolId]],
        }),
        RRServer.list({
          table: "corporate_codes",
          forCurrentUser: false,
          where: [["companyId", "==", claims.drivingSchoolId]],
        }),
      ]).then((results: any) => {
        this.setState({
          // schools: [results[0].data.data],
          // users: results[1].data.data,
          // corporateCodesCount: results[2].data.data.length,
          // corporateCodes: results[2].data.data,
          // drivingSchoolCodesCount: results[2].data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1).length,
          // studentsCount: results[2].data.data.filter(({ type }: { type: string }) => type.indexOf('DRIVING_') != -1 && type.indexOf('DRIVING_SCHOOL_ADMIN') == -1 && type.indexOf('DRIVING_SCHOOL_INSTRUCTOR') == -1).length,
          // instructorCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_INSTRUCTOR').length,
          // adminCount: results[2].data.data.filter(({ type }: { type: string }) => type === 'DRIVING_SCHOOL_ADMIN').length,
        });
      });
    }
  }

  getSchool(schoolId: string) {
    const school = this.state.schools.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  toggleForm = (e: any) => {
    e.preventDefault();
    this.setState({ isToggle: !this.state.isToggle });
    // console.log(this.state.isToggle);
  };

  handleValues = (values: any) => {
    console.log(values);
  };

  handleChangePassword = (password: string, confirmPassword: string) => {

    const { currentLoggedInSchool } = this.state

    if (password === confirmPassword) {




      RRServer.callFn("users1-changePassword", { uid: currentLoggedInSchool.firebaseUserUid, password })
        .then((res) => {
          console.log(res, 'ress')
          RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: currentLoggedInSchool.id, password })
            .then((res) => {
              alert("Password updated succesfully")
              localStorage.clear()
              window.location.href = '/';
            }).catch((Err) => {
              console.log(Err, 'error')
            })
        })
        .catch((err) => {
          alert("Password not updated, something went wrong")
        })
    } else {
      alert("password mismatch. kindly check")
    }
  }

  render() {
    const { schools, filterSchoolId } = this.state;

    let dataSource = schools.map((school) => ({ key: school.id, ...school }));

    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.id === filterSchoolId);
    }

    const columns = [
      {
        Header: "Name",
        accessor: "drivingSchoolName",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Code",
        accessor: "code",
        // disableFilters: true
      },
      {
        Header: "Current Count",
        accessor: "currentCount",
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: () => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                cursor: "not-allowed",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-eye-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            </div>
          );
        },
      },
    ];

    return (
      <>

        {/* {console.log("user dataa", this.state)} */}
        <TopBar title="Profile" />

        <div className='contentContainer'>
          <div className='contentTop'>
            <h5>{this.state.isToggle ? 'Profile' : 'Add Driving School'}</h5>
          </div>

          <div className="profileContent">
            <div className="sectionLeft">
              <div className="cardProfile">
                <div className="profilePic">
                  {!this.state?.currentLoggedInSchool?.drivingSchoolUnionImg ?
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAANlBMVEX///+/v7+7u7vt7e3z8/P5+fnAwMD8/PzDw8PGxsbLy8vOzs7g4OD39/fr6+vV1dXb29vZ2dkWhsU1AAAFY0lEQVR4nO2c24KrIAxFj3hFEPX/f/YU6sy0FUh0FGxnr+dWYJuEEMB//wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACwpm3b2jFYKktpHOVQt7l7l4x2KI2eRtXLrmmKH8QrRdF0vRonbcqqzt3r86i0ksV9vHzc7xupZl1+nDTV3GwTwyNO109myD2Qw6jVr/R4VKZTuvqEWKOPEeRbl6LXb24vlTzISJ50kfqNrWU8XpFFFlXlHts+zDmCLLKoN3Sh9qjYGlbl3WZofbIiTpUp9yi3MJwQW32iFCb3SNlMSRRxqvTvEVaqLpkkxXs4UHvWBBwUpStzj5nANIklsaqMV87hTp+AQ+jcIw9iGrr353DVWJs8kjyrcsVYW2YUxIlyvVib1UgWVdSlYm2VLZI8IS4Ua1OsblgIeZVYm2sG9nGNWFt3uXV44gqxNvd8syZrXlsPpZ5zK+Aleaxt68roWcmm2LiLlQ7RJVKlvZnFNPZds3VHLweimc/bDLpZRWn0qGR3XbPwIxqly0N1aasfJd5KikduPVf6iFp2baZRvoWDsBCi/50sgx67D9HigZsse1WpJvl5etzZtxJqdYayYTrUDhMZP9VCFrqtigz9ZwtyQ2zbdc9bNEzFpvXyRepBZ7PFearcnU3EBudp/4aV3JjZmqjcXU0Hd+lj/kJ4vcNN2/6O5xTsKDvm7mdKBKtQW7E8R1xxiSx29KrnaNIzmu7tZQBjJlVcRhch5Ow6tXERLxibP5SZiGJ8NLdhSnoCKYRQj6fYWr1hXcLIZSkzWQdqk+bwXgQxriohFX/PjZSkjj7qe5dkMFpr81XwPfYA/Va+z5kMpevUok/JNGA6l43u8jZ3rzGq+bpqtFTwhozbfkuKUdowcu+UnO7D5C1j6RQllsJKZxbPZaZbeHGq5CssuPG/+K/oK/IFf0NWliIv3Enis0gndKY9c2f59fqNiJEtiqQ0CT+ka0ONCKc0PYefgEu5vLvTQtZMUQQhSRt8hnshgcPQwiY+OZYELhaUgT43AxELvh5CaDKENHGtB1V3lhLq24nYZsMJlbXsln4IpUmwARkftDv1kn6hVMcHbc2XXuTv1cT6bdQ5rGvVVOtHMxPO4YxbUk/Zq4k1kzmquMxgKDXlsKJmGMpOTYQhrcD+hLekPgxFW4GdkanHUJoEUnv7N+oCTs+x0yOx/ky+hVuYJU9NEZoE8hM7Xip9t3Y6Uc0fCmO81nqp+ZDM2bw5uo1VwVn68UdJp2POi7LOQ03HdFXJt3CyVkrPaSO1qj6YmdNeQwrHKVN7Bm9rUbRb9GlzWZ5dipbIZXmHUDwPrjnzbMex5eOwoYKRkA3xrrPKsb7JgxTb0Xn/expWE3qJZ3PJiIlzqrGWtax8O0msCcNOopqwD+Kvxbdq0mej5SV9J/o6G6YknjzIik2bqYqVGk5AcxJnm20G3V6wr6yv53NeiJ8SJ/c2tSfbs8YbnAzJbO2HdcWMkfm4JCbpPabO29UXpshCbcvRLbP+FFjMAhesb6rVP89koF+CHfZ6OAubjoJWr7iqJ9G8LWaUSanpAGa9oyrLr3EM9fJVvNuQDvmqTnyeFXm+xhKfDvlBdCdRQ8l15S4e5jYE0Z3EIgp7pj+aWETZeP51D204I0vQeojwZluSm8V1aKY/3W9jhLZlBf9Q428IXJHlHYA6Db+lJIt
                  wreeliC73x+J8peKUpqtfj2slMtEoq+8lpv5Q26MqwnNEKAvm4eaVWI5aJKWcentNspHjhb
                  6FNty/0dtI9c6fwwQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgT/Afu0hPLsB+Gd8AAAAASUVORK5CYII=" alt="" /> : <img src={this.state?.currentLoggedInSchool?.drivingSchoolUnionImg} alt="Profile Picture" width={100} />}
                </div>
                <div className="profileDetails">
                  <div className="detailItem">
                    <h4 className="title">My Profile</h4>
                    {/* <p className="loginDetails">Last Login on 12th Jan - 2022 <br/> Windows 10 Pro</p> */}
                  </div>

                  <div className="detailItem">
                    <span>{this.state?.currentLoggedInSchool?.drivingSchoolName}</span>
                    <span>+91 {this.state?.currentLoggedInSchool?.sosNumber}</span>
                  </div>

                  <div className="detailItem">
                    <span className="mail">{this.state?.currentLoggedInSchool?.profile}</span>
                  </div>

                  <div className="detailItem">
                    <span className="smsalert"> CODE : {this.state?.currentLoggedInSchool?.code}</span>
                    {/* <span className='active'></span> */}
                  </div>

                  <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Change Password
                  </button>

                </div>

              </div>
            </div>

          </div>
        </div>



        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Change Password</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">

                <div className="form-floating">
                  <input type="text" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => this.setState(({ password: { ...this.state.password, password: e.target.value } }))} />
                  <label htmlFor="floatingPassword">Password</label>
                </div>

                <div className="form-floating">
                  <input type="text" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => this.setState(({ password: { ...this.state.password, confirmPassword: e.target.value } }))} />
                  <label htmlFor="floatingPassword">Confirm Password</label>
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={() => this.handleChangePassword(this.state.password.password, this.state.password.confirmPassword)}>Update Password</button>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

// export default withRouter(UserAdd);
export default Profile;



import React, { useState } from "react"
import { Input } from "reactstrap"
import 'bootstrap/dist/css/bootstrap.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimePicker } from "antd";


export const Filter = ({ column }: any) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}


export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}: any) => {
  return (
    <Input
      value={filterValue || ""}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`search`}
    />
  )
}


export const SelectColumnFilter: any = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <Input
      id="custom-select"
      type="select"
      value={filterValue}
      onChange={(e: any) => {
        setFilter(e.target.value || undefined)
      }}
    >
      <option value="">All</option>
      {options.map((option: any) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Input>
  )
}

// export const DateColumnFilter: any = ({
//   column: { filterValue, setFilter, preFilteredRows, id },
// }: any) => {
//   const options = React.useMemo(() => {
//     const options = new Set()
//     preFilteredRows.forEach((row: any) => {
//       options.add(row.values[id])
//     })
//     return [...options.values()]
//   }, [id, preFilteredRows])

//   return (
// <Input
//   id="custom-select"
//   type="date"

//   value={filterValue}
// onChange={(e: any) => {
//   setFilter(e.target.value || undefined)
//   console.log('Dateee', e.target.value)
// }}
//   style={{ width: "100px" }}
//   placeholder="Date"
// >
//   {/* <option value="">All</option>
//     {options.map((option: any) => (
//       <option key={option} value={option}>
//         {option}
//       </option>
//     ))} */}
// </Input>
//   )
// }

const tConv24 = (time24 : any) => {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h : any = (H % 12) || 12;
  // h = (h < 10)?("0"+h):h;  // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? " AM" : " PM";
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};


export const TimeColumnFilter: any = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    // <TimePicker value={filterValue}
    //   id="custom-select"
    //   format="h:mm a" onChange={(time:any, timestring:any) => {
    //     // setFilter(timestring)
    //     console.log('Dateee', timestring)
    //   }} />
    <div style={{ position: "relative" }}>

    <Input
      id="custom-select"
      type="time"
      value={filterValue}
      onChange={(e: any) => {
        setFilter(tConv24(e.target.value) || undefined)
        console.log('Dateee', e.target.value)
      }}
      // style={{ width: "100px" }}
      placeholder="Date"
    >
    </Input>
    <button style={{ position: "absolute", right: "30px", top: "0", bottom: "0", border: "none", background: "none", outline: "none" }} onClick={(e: any) => setFilter([])}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
        </svg>
      </button>
    </div>
  )
}


export const DateColumnFilter: any = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const [date, setDate]: any = useState(filterValue);
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  function convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
  }

  return (
    <div style={{ position: "relative" }}>
      <DatePicker
        id='datepicker'
        selected={date}
        value={filterValue}
        placeholderText={filterValue ? `${filterValue}` : 'Search'}
        onChange={(date) => {
          setFilter(convert(date))
          console.log('Dateee', convert(date))
        }}
        isClearable={true}
      />
      <button style={{ position: "absolute", right: "0", top: "0", bottom: "0", border: "none", background: "none", outline: "none" }} onClick={(e: any) => setFilter([])}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
        </svg>
      </button>
    </div>
  )
}

export const DateColumnFilter2: any = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const [date, setDate]: any = useState(filterValue);
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  function convert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [ date.getFullYear(), mnth,day].join("-");
  }

  return (
    <div style={{ position: "relative" }}>
      <DatePicker
        id='datepicker'
        selected={date}
        value={filterValue}
        placeholderText={filterValue ? `${filterValue}` : 'Search'}
        onChange={(date) => {
          setFilter(convert(date))
          console.log('Dateee', convert(date))
        }}
        isClearable={true}
      />
      <button style={{ position: "absolute", right: "0", top: "0", bottom: "0", border: "none", background: "none", outline: "none" }} onClick={(e: any) => setFilter([])}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
        </svg>
      </button>
    </div>
  )
}


export const DateColumnFilter3: any = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) => {
  const [date, setDate]: any = useState(filterValue);
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  function convert(str: any) {
    var date = new Date(str),
      mnth = `${date.getMonth() + 1}`.slice(-2),
      day = `${date.getDate()}`.slice(-2);
    return [mnth, day , date.getFullYear()].join("/");
  }

  return (
    <div style={{ position: "relative" }}>
      <DatePicker
        id='datepicker'
        selected={date}
        value={filterValue}
        placeholderText={filterValue ? `${filterValue}` : 'Search'}
        onChange={(date) => {
          setFilter(convert(date))
          console.log('Dateee', convert(date))
        }}
        isClearable={true}
      />
      <button style={{ position: "absolute", right: "0", top: "0", bottom: "0", border: "none", background: "none", outline: "none" }} onClick={(e: any) => setFilter([])}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
          <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
        </svg>
      </button>
    </div>
  )
}


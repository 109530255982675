import React, { Component } from 'react'
import { RRServer } from '../../../utils/lib';
import FormComponent from '../../FormComponent/FormComponent'
import TopBar from '../../TopBar';

export default class Enquires extends Component {

    state = {
        name: "",
        mobile: "",
        remainderDate: "",
        reason: [],
        remarks: ""
    }

    handleReason = (e: any) => {
        if (e.target.checked) {
            this.setState({ reason: [...this.state.reason, e.target.value] })
        } else {
            this.setState({
                reason: this.state.reason.filter((reason) => {
                    return reason !== e.target.value
                })
            })
        }
    }


    handleValues = () => {

        const claimsString: any = localStorage.getItem('claims');
        const schoolData = JSON.parse(claimsString)

        const { name, mobile, remainderDate, reason , remarks} = this.state

        const payload = {
            mobile,
            remarks,
            status: 1,
            id: "",
            name,
            reminderDate : remainderDate,
            companyId: schoolData.drivingSchoolId
        }

        const fn = RRServer.add({ table: 'enquiries', data: payload });

        fn
            .then(function ({ data }: any) {
                if (data.s) {
                    console.log("dataa", data)
                } else {
                }
            })
    }


    render() {

        return (
            <>
                <TopBar title="Add Enquiry" />

                {/* <FormComponent fields={addFields} handleValues={this.handleValues} buttonText='Submit' /> */}

                <div className='contentContainer' style={{ width: "50%", margin: "auto" }}>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="name" id="name" placeholder="Name" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                        <label htmlFor="name">Name</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="mobile" id="Mobile" placeholder="Mobile" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                        <label htmlFor="Mobile">Mobile</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="date" className="form-control" name="remainderDate" id="Date" placeholder="Date" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                        <label htmlFor="Date">Date</label>
                    </div>

                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="remarks" id="Remarks" placeholder="Remarks" onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                        <label htmlFor="Remarks">Remarks</label>
                    </div>

                    <>
                        <h4>Reason: </h4>
                        <div style={{ fontSize: "17px" }} className="form-check">
                            <input className="form-check-input" type="checkbox" value={1} id="MCWG" onChange={(e) => this.handleReason(e)} />
                            <label className="form-check-label" htmlFor="MCWG">
                                MCWG
                            </label>
                        </div>
                        <div style={{ fontSize: "17px" }} className="form-check">
                            <input className="form-check-input" type="checkbox" value={2} id="MCWOG" onChange={(e) => this.handleReason(e)} />
                            <label className="form-check-label" htmlFor="MCWOG">
                                MCWOG
                            </label>
                        </div>
                        <div style={{ fontSize: "17px" }} className="form-check">
                            <input className="form-check-input" type="checkbox" value={3} id="LMV" onChange={(e) => this.handleReason(e)} />
                            <label className="form-check-label" htmlFor="LMV">
                                LMV
                            </label>
                        </div>
                        <div style={{ fontSize: "17px" }} className="form-check">
                            <input className="form-check-input" type="checkbox" value={4} id="LCV" onChange={(e) => this.handleReason(e)} />
                            <label className="form-check-label" htmlFor="LCV">
                                LCV
                            </label>
                        </div>
                        <div style={{ fontSize: "17px" }} className="form-check">
                            <input className="form-check-input" type="checkbox" value={5} id="OTHERS" onChange={(e) => this.handleReason(e)} />
                            <label className="form-check-label" htmlFor="OTHERS">
                                OTHERS
                            </label>
                        </div>
                    </>

                    <button className='btn btn-primary' onClick={()=>this.handleValues()}>Submit</button>
                </div>




            </>

        )
    }
}

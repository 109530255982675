import { Button, Dropdown, Select, Space } from 'antd';
import React, { Component } from 'react';

import { AppointmentPicker } from 'react-appointment-picker';
import { DownOutlined } from "@ant-design/icons";
import { Option } from 'antd/lib/mentions';


interface AddInstructorFormProps {
    updateIns: any,
    slots: any
}
const daysForOneHour = [
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "05:00" },
        { id: 3, number: 3, time: "06:00" },
        { id: 4, number: 4, time: "07:00" },
        { id: 5, number: 5, time: "08:00" },
        { id: 6, number: 6, time: "09:00" },
        { id: 7, number: 7, time: "10:00" },
        { id: 8, number: 8, time: "11:00" },
        { id: 9, number: 9, time: "12:00" },
        { id: 10, number: 10, time: "13:00" },
        { id: 11, number: 11, time: "14:00" },
        { id: 12, number: 12, time: "15:00" },
        { id: 13, number: 13, time: "16:00" },
        { id: 14, number: 14, time: "17:00" },
        { id: 15, number: 15, time: "18:00" },
        { id: 16, number: 16, time: "19:00" },
        { id: 17, number: 17, time: "20:00" },
        { id: 18, number: 18, time: "21:00" },
        { id: 19, number: 19, time: "22:00" },
        { id: 20, number: 20, time: "23:00" },
        { id: 21, number: 21, time: "24:00" },
        { id: 22, number: 22, time: "01:00" },
        { id: 23, number: 23, time: "02:00" },
        { id: 24, number: 24, time: "03:00" },
    ]
    ,
];
const daysForHalfHour = [
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
    [
        { id: 1, number: 1, time: "04:00" },
        { id: 2, number: 2, time: "04:30" },
        { id: 3, number: 3, time: "05:00" },
        { id: 4, number: 4, time: "05:30" },
        { id: 5, number: 5, time: "06:00" },
        { id: 6, number: 6, time: "06:30" },
        { id: 7, number: 7, time: "07:00" },
        { id: 8, number: 8, time: "07:30" },
        { id: 9, number: 9, time: "08:00" },
        { id: 10, number: 10, time: "08:30" },
        { id: 11, number: 11, time: "09:00" },
        { id: 12, number: 12, time: "09:30" },
        { id: 13, number: 13, time: "10:00" },
        { id: 14, number: 14, time: "10:30" },
        { id: 15, number: 15, time: "11:00" },
        { id: 16, number: 16, time: "11:30" },
        { id: 17, number: 17, time: "12:00" },
        { id: 18, number: 18, time: "12:30" },
        { id: 19, number: 19, time: "13:00" },
        { id: 20, number: 20, time: "13:30" },
        { id: 21, number: 21, time: "14:00" },
        { id: 22, number: 22, time: "14:30" },
        { id: 23, number: 23, time: "15:00" },
        { id: 24, number: 24, time: "15:30" },
        { id: 25, number: 25, time: "16:00" },
        { id: 26, number: 26, time: "16:30" },
        { id: 27, number: 27, time: "17:00" },
        { id: 28, number: 28, time: "17:30" },
        { id: 29, number: 29, time: "18:00" },
        { id: 30, number: 30, time: "18:30" },
        { id: 31, number: 31, time: "19:00" },
        { id: 32, number: 32, time: "19:30" },
        { id: 33, number: 33, time: "20:00" },
        { id: 34, number: 34, time: "20:30" },
        { id: 35, number: 35, time: "21:00" },
        { id: 36, number: 36, time: "21:30" },
        { id: 37, number: 37, time: "22:00" },
        { id: 38, number: 38, time: "22:30" },
        { id: 39, number: 39, time: "23:00" },
        { id: 40, number: 40, time: "23:30" },
        { id: 41, number: 41, time: "24:00" },
        { id: 42, number: 42, time: "00:00" },
        { id: 43, number: 43, time: "00:30" },
        { id: 44, number: 44, time: "01:00" },
        { id: 45, number: 45, time: "01:30" },
        { id: 46, number: 46, time: "02:00" },
        { id: 47, number: 47, time: "02:30" },
        { id: 48, number: 48, time: "03:00" },
    ],
];

export default class UpdateInsForm extends Component<AddInstructorFormProps> {
    state = {
        loading: false,
        continuousLoading: false,
        interval: "1 hour",
        // instructorSlots: 
        values: this.props.slots,
        submitLoading: false,
        oneHourData: daysForOneHour,
        halfHourData: daysForHalfHour,
        isUpdated: false
    };


    componentDidMount(): void {
        const { slots } = this.props
        const { oneHourData } = this.state
        const fullArr = oneHourData
        const MonArr = oneHourData[0].map((data: any, idx: any) => {
            if (slots.Mon.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const TueArr = oneHourData[1].map((data: any, idx: any) => {
            if (slots.Tue.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const WedArr = oneHourData[2].map((data: any, idx: any) => {
            if (slots.Wed.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const ThuArr = oneHourData[3].map((data: any, idx: any) => {
            if (slots.Thu.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const FriArr = oneHourData[4].map((data: any, idx: any) => {
            if (slots.Fri.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const SatArr = oneHourData[5].map((data: any, idx: any) => {
            if (slots.Sat.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })
        const SunArr = oneHourData[6].map((data: any, idx: any) => {
            if (slots.Sun.includes(data.time)) {
                return { ...data, isSelected: true }
            } else {
                return data
            }
        })

        fullArr[0] = MonArr
        fullArr[1] = TueArr
        fullArr[2] = WedArr
        fullArr[3] = ThuArr
        fullArr[4] = FriArr
        fullArr[5] = SatArr
        fullArr[6] = SunArr

        this.setState({ onHourData: fullArr, isUpdated: true })

    }

    

    convertTime12to24 = (time12h: any) => {
        const [time, modifier] = time12h.split(" ");

        let [hours, minutes] = time.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM" || modifier === "pm") {
            hours = parseInt(hours, 10) + 12;
        }

        return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
    };

    addAppointmentCallback = ({
        addedAppointment: { day, number, time, id },
        addCb
    }: any) => {
        this.setState(
            {
                loading: true
            },
            async () => {
                // await new Promise((resolve) => setTimeout(resolve, 2000));
                console.log(
                    `Added appointment ${number}, day ${day.slice(0, 3)}, time ${this.convertTime12to24(time)}, id ${id}`
                );
                const dayStr = day.slice(0, 3);
                const timeStr = this.convertTime12to24(time)

                if (dayStr === 'Mon') {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Mon, timeStr] } })
                } else if (dayStr === "Tue") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Tue, timeStr] } })
                } else if (dayStr === "Wed") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Wed, timeStr] } })
                } else if (dayStr === "Thu") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Thu, timeStr] } })
                } else if (dayStr === "Fri") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Fri, timeStr] } })
                } else if (dayStr === "Sat") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Sat, timeStr] } })
                } else if (dayStr === "Sun") {
                    this.setState({ values: { ...this.state.values, [dayStr]: [...this.state.values.Sun, timeStr] } })
                }

                addCb(day, number, time, id);
                this.setState({ loading: false });
            }
        );
    };

    removeAppointmentCallback = ({ day, number, time, id }: any, removeCb: any) => {
        this.setState(
            {
                loading: true
            },
            async () => {
                // await new Promise((resolve) => setTimeout(resolve, 2000));
                console.log(
                    `Removed appointment ${number}, day ${day.slice(0, 3)}, time ${this.convertTime12to24(time)}, id ${id}`
                );

                const dayStr = day.slice(0, 3);
                const timeStr = this.convertTime12to24(time)


                if (dayStr === 'Mon') {

                    const newArr = this.state.values.Mon.filter((time: any) => {
                        return time != timeStr
                    })
                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Tue") {
                    const newArr = this.state.values.Tue.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Wed") {
                    const newArr = this.state.values.Wed.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Thu") {
                    const newArr = this.state.values.Thu.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Fri") {
                    const newArr = this.state.values.Fri.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Sat") {
                    const newArr = this.state.values.Sat.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                } else if (dayStr === "Sun") {
                    const newArr = this.state.values.Sun.filter((time: any) => {
                        return time != timeStr
                    })

                    this.setState({ values: { ...this.state.values, [dayStr]: newArr } })
                }



                removeCb(day, number);
                this.setState({ loading: false });
            }
        );
    };

    handleFormSubmit = (e: any) => {
        this.setState({ submitLoading: true })
        const { values } = this.state
        this.props.updateIns(values)
        // console.log(values, 'vals')
        // this.props.handleSlots(values)
    }



    render() {
        const { slots } = this.props
        const { oneHourData, halfHourData } = this.state
        const { loading, continuousLoading } = this.state;


        return (
            <div style={{ position: "relative" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Select instructor slots</h3>

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "220px" }}>
                        <p style={{ margin: 0 }}>Select Interval : </p>
                        <Select defaultValue={this.state.interval} placeholder="select interval" style={{ width: 120 }} onChange={(val) => this.setState({
                            interval: val, oneHourData: daysForOneHour,
                            halfHourData: daysForHalfHour
                        })}>
                            <Option value="30 mins" >30 Minutes</Option>
                            <Option value="1 hour" >1 Hour</Option>
                        </Select>
                    </div>
                </div>

                {this.state.isUpdated && <AppointmentPicker
                    addAppointmentCallback={this.addAppointmentCallback}
                    removeAppointmentCallback={this.removeAppointmentCallback}
                    initialDay={new Date(2018, 11, 24, 0o4, 0o0, 0o0, 0)}
                    days={this.state.interval === "1 hour" ? oneHourData : halfHourData}
                    maxReservableAppointments={350}
                    alpha
                    visible
                    selectedByDefault
                    unitTime={this.state.interval === "1 hour" ? 3600000 : 1800000}
                    loading={loading}
                />}

                <Button type="primary" style={{ position: "absolute", left: "50%" }} loading={this.state.submitLoading} onClick={(e: any) => this.handleFormSubmit(e)}> Submit </Button>
                
            </div>
        );
    }
}
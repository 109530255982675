import { Button, Dropdown, Select, Space } from 'antd';
import React, { Component } from 'react';

import { AppointmentPicker } from 'react-appointment-picker';
import {DownOutlined} from "@ant-design/icons";
import { Option } from 'antd/lib/mentions';


interface AddInstructorFormProps {
  handleSlots : any,
}

export default class AddInsFormTwo extends Component<AddInstructorFormProps> {
  state = {
    loading: false,
    continuousLoading: false,
    interval: "1 hour",
    // instructorSlots: 
    values: {
      Mon: [],
      Tue:[],
      Wed:[],
      Thu:[],
      Fri:[],
      Sat:[],
      Sun:[]
    },
    submitLoading: false

    
  };

  convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
  };

  addAppointmentCallback = ({
    addedAppointment: { day, number, time, id },
    addCb
  } : any) => {
    this.setState(
      {
        loading: true
      },
      async () => {
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        console.log(
          `Added appointment ${number}, day ${day.slice(0,3)}, time ${this.convertTime12to24(time)}, id ${id}`
        );
        const dayStr = day.slice(0,3);
        const timeStr = this.convertTime12to24(time)

          if(dayStr === 'Mon'){
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Mon, timeStr]}})
          } else if(dayStr === "Tue") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Tue, timeStr]}})
          }else if(dayStr === "Wed") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Wed, timeStr]}})
          }else if(dayStr === "Thu") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Thu, timeStr]}})
          }else if(dayStr === "Fri") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Fri, timeStr]}})
          }else if(dayStr === "Sat") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Sat, timeStr]}})
          }else if(dayStr === "Sun") {
            this.setState({values:{...this.state.values, [dayStr]: [...this.state.values.Sun, timeStr]}})
          }


        addCb(day, number, time, id);
        this.setState({ loading: false });
      }
    );
  };

  removeAppointmentCallback = ({ day, number, time, id } : any, removeCb : any) => {
    this.setState(
      {
        loading: true
      },
      async () => {
        // await new Promise((resolve) => setTimeout(resolve, 2000));
        console.log(
          `Removed appointment ${number}, day ${day.slice(0,3)}, time ${this.convertTime12to24(time)}, id ${id}`
        );

        const dayStr = day.slice(0,3);
        const timeStr = this.convertTime12to24(time)


        if(dayStr === 'Mon'){

          const newArr = this.state.values.Mon.filter((time:any)=>{
            return time != timeStr
        })
        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        } else if(dayStr === "Tue") {
          const newArr = this.state.values.Tue.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }else if(dayStr === "Wed") {
          const newArr = this.state.values.Wed.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }else if(dayStr === "Thu") {
          const newArr = this.state.values.Thu.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }else if(dayStr === "Fri") {
          const newArr = this.state.values.Fri.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }else if(dayStr === "Sat") {
          const newArr = this.state.values.Sat.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }else if(dayStr === "Sun") {
          const newArr = this.state.values.Sun.filter((time:any)=>{
            return time != timeStr
        })

        this.setState({values:{...this.state.values, [dayStr]:  newArr }})
        }



        removeCb(day, number);
        this.setState({ loading: false });
      }
    );
  };

  handleFormSubmit = (e:any) =>{
    this.setState({submitLoading: true})
    const {values} = this.state
    this.props.handleSlots(values)
  }

 

  render() {
    const daysForOneHour = [
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
      ],
    ];
    const daysForHalfHour = [
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
      [
        { id: 1, number: 1 },
        { id: 2, number: 2 },
        { id: 3, number: 3 },
        { id: 4, number: 4 },
        { id: 5, number: 5 },
        { id: 6, number: 6 },
        { id: 7, number: 7 },
        { id: 8, number: 8 },
        { id: 9, number: 9 },
        { id: 10, number: 10 },
        { id:11, number: 11 },
        { id: 12, number: 12 },
        { id: 13, number: 13 },
        { id: 14, number: 14 },
        { id: 15, number: 15 },
        { id: 16, number: 16 },
        { id: 17, number: 17 },
        { id: 18, number: 18 },
        { id: 19, number: 19 },
        { id: 20, number: 20 },
        { id: 21, number: 21 },
        { id: 22, number: 22 },
        { id: 23, number: 23 },
        { id: 24, number: 24 },
        { id: 25, number: 25 },
        { id: 26, number: 26 },
        { id: 27, number: 27 },
        { id: 28, number: 28 },
        { id: 29, number: 29 },
        { id: 30, number: 30 },
        { id: 31, number: 31 },
        { id: 32, number: 32 },
        { id: 33, number: 33 },
        { id: 34, number: 34 },
        { id:35, number: 35 },
        { id: 36, number: 36 },
        { id: 37, number: 37 },
        { id: 38, number: 38 },
        { id: 39, number: 39 },
        { id: 40, number: 40 },
        { id: 41, number: 41 },
        { id: 42, number: 42 },
        { id: 43, number: 43 },
        { id: 44, number: 44 },
        { id: 45, number: 45 },
        { id: 46, number: 46 },
        { id: 47, number: 47 },
        { id: 48, number: 48 },
      ],
    ];
    const { loading, continuousLoading } = this.state;
    
    
    return (
      <div style={{position:"relative"}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <h3>Select instructor slots</h3>

        <div style={{display:"flex", justifyContent:"space-between",alignItems:"center", width:"220px"}}>
          <p style={{margin:0}}>Select Interval : </p>
            <Select defaultValue={this.state.interval} placeholder="select interval" style={{ width: 120 }} onChange={(val)=>this.setState({interval: val})}>
              <Option value="30 mins" >30 Minutes</Option>
              <Option value="1 hour" >1 Hour</Option>
            </Select>
        </div>
        </div>

        <AppointmentPicker
          addAppointmentCallback={this.addAppointmentCallback}
          removeAppointmentCallback={this.removeAppointmentCallback}
          initialDay={ new Date(2018, 11, 24, 0o4, 0o0, 0o0, 0)}
          days={this.state.interval === "1 hour"? daysForOneHour : daysForHalfHour}
          maxReservableAppointments={350}
          alpha
          visible
          selectedByDefault
          unitTime={this.state.interval === "1 hour"? 3600000 : 1800000  }
          loading={loading}
        />

            <Button type="primary" style={{position:"absolute",left:"50%"}} loading={this.state.submitLoading} onClick={(e:any)=>this.handleFormSubmit(e)}> Submit </Button>

      </div>
    );
  }
}
import React from "react";
import { DatePicker, Divider, message, Select, Space, Table, TimePicker } from "antd";
import { getClaims, RRServer } from "../../../utils/lib";
import { ClassScheduleInterface, CorporateInterface, UserInterface } from "../../../types";
import { format } from "date-fns";
import {
  DateColumnFilter,
  SelectColumnFilter,
  TimeColumnFilter,
} from "../../TableComp/Filter";
import TableContainer from "../../TableComp/TableContainer";
import TopBar from "../../TopBar";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface ClassSchedulesListStateProps {
  classSchedules: ClassScheduleInterface[];
  selectedSchedule: ClassScheduleInterface | null;
  userCode: string;
  usersHash: { [key: string]: UserInterface };
  filterSchoolId: string;
  filterUserId: string;
  schools: DrivingSchoolInterface[];
  filterDates: any;
  filterTime: any;
  users: any[];
  classes: any[];
  selectedClass: any;
  selectedIns: any;
  selectedDate: string;
  showInsReschedule: boolean;
  updatedInstructor: string,
  updatedDate: string,
  updatedTime: string,
  isLoading: boolean,
  checkList: string[],
  selectedDatee: string,
  selectedTimee: string
}

class BulkRescheduling extends React.Component<any, ClassSchedulesListStateProps> {
  requesting: { [key: string]: boolean } = {};
  constructor(props: any) {
    super(props);
    this.state = {
      classSchedules: [],
      selectedSchedule: null,
      userCode: "",
      usersHash: {},
      filterSchoolId: "",
      filterUserId: "",
      filterDates: "",
      filterTime: "",
      schools: [],
      users: [],
      classes: [],
      selectedClass: {},
      selectedIns: {},
      selectedDate: "",
      showInsReschedule: true,
      updatedInstructor: "",
      updatedDate: "",
      updatedTime: "",
      isLoading: false,
      checkList: [],
      selectedDatee: "",
      selectedTimee: ""
    };
    this.getSchool = this.getSchool.bind(this);
  }

  componentDidMount() {
    getClaims().then((claims) => {
      if (!claims || !!!claims["drivingSchoolId"]) {
        console.log("No Claim - cs");
        return null;
      }
      const drivingSchoolId = claims ? claims["drivingSchoolId"] : "*";

    });
    const claimsStr = localStorage.getItem('claims');
    const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
    const drivingSchoolId = claims.drivingSchoolId || '';

    function GetFormattedDate() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month.toString().length === 1 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day.toString().length === 1 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return dayFormat + "/" + monthFormat + "/" + year;
    }

    let promiseArr: any = [];
    if (drivingSchoolId === "*" || claims.drivingSchoolId === "#") {
      promiseArr = [
        RRServer.list({ table: "scores", forCurrentUser: false }),
        RRServer.list({ table: "class_schedules", forCurrentUser: false }),
        RRServer.list({ table: "driving_schools", forCurrentUser: false }),
        RRServer.list({ table: "users", forCurrentUser: false }),
        RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
      ]
    } else {
      promiseArr = [
        RRServer.list({ table: "scores", forCurrentUser: false, where: [['cid', '==', drivingSchoolId]] }),
        RRServer.list({
          table: "class_schedules",
          forCurrentUser: false,
          where: [["companyId", "==", drivingSchoolId]],
        }),
        RRServer.list({
          table: "driving_schools",
          forCurrentUser: false,
          where: [["companyId", "==", drivingSchoolId]],
        }),
        RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
        RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
      ]
    }

    Promise.all(promiseArr)
      .then((results: any) => {
        const scores = results[0].data.data;
        let classSchedules = results[1].data.data;
        const classes = results[1].data.data;
        const users = results[3].data.data as UserInterface[];
        const schools = results[2].data.data as DrivingSchoolInterface[];
        const corporates = results[3].data.data as CorporateInterface[];

        this.setState({ classes: classes })
        let userHashed: { [key: string]: UserInterface } = {};
        users.map(user => userHashed[user.id] = user);

        let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
        schools.map(school => schoolsHashed[school.id] = school);

        let corpHashed: { [key: string]: CorporateInterface } = {};
        corporates.map(school => corpHashed[school.id] = school);

        const updatedSchedules = classSchedules.map((c: any, idx: any) => {


          if (moment(c.date, "DD/MM/YYYY").isBefore() && c.hasOwnProperty('classStarted') === false) {
            return {
              ...c,
              status: 'Not attended'
            }
          } else if (c.hasOwnProperty('classStarted') && c.hasOwnProperty('classEndTime') === false) {
            return {
              ...c,
              status: "On Board"
            }
          } else if (c.hasOwnProperty('classEndTime')) {
            return {
              ...c,
              status: "Completed"
            }

          } else if (moment(c.date, "DD/MM/YYYY").isAfter()) {
            return {
              ...c,
              status: "Pending"
            }
          } else if (c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === false) {
            return {
              ...c,
              status: "Yet to Board"
            }
          } else if (c.date === GetFormattedDate() && c.hasOwnProperty('classStarted') === true) {
            return {
              ...c,
              status: "On Going"
            }
          } else {
            return {
              ...c,
              status: 'Yet to board'
            }
          }

        })

        this.setState({ users, classSchedules: updatedSchedules })

        let usersHash: any = {};

        users.map((a: any) => (usersHash[a.id] = a));

        this.setState({
          classSchedules: this.state.classSchedules.map(
            (c: any) => { ((c.studentId = usersHash[c.studentId] ? usersHash[c.studentId].typeOfVehicle : '')); return c }
          ),
          users: users,
        });
      })



  }




  getSchool(schoolId: string) {
    const school = this.state.classSchedules.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  getUser = (id: string) => {
    if (this.requesting[id]) {
      return;
    }
    this.requesting[id] = true;
    RRServer.get({ table: "users", id }).then((result: any) => {
      let usersHash = this.state.usersHash;
      usersHash[id] = result.data.data;
      this.setState({
        usersHash,
      });
    });
  };


  showAvailableSlots = (date: any) => {

    const instructorClasses = this.state.selectedIns ? this.state.selectedIns : ""
    const day = moment(date).format('ddd')
    const slots = instructorClasses.slots && instructorClasses?.slots[`${day}`]
    return slots

  }

  showAvailableSlotsIns = (date: any, ins: any) => {

    const instructorClasses = this.state.users.filter((user) => {
      return user.id === ins
    })
    const day = moment(date).format('ddd')
    const slots = instructorClasses[0]?.slots && instructorClasses[0].slots[`${day}`]
    return slots
  }

  handleReschedule = (id: any) => {

    const selectedClass = this.state.classes.filter((cls: any) => {
      return cls.id === id
    })
    const userObj = this.state.users.filter((user: any) => {
      return user.id === selectedClass[0].studentId
    })
    const insObj = this.state.users.filter((ins: any) => {
      return ins.id === selectedClass[0].instructorId
    })

    this.setState({ selectedClass: selectedClass[0], selectedIns: insObj[0], selectedDate: selectedClass[0].date })
    this.setState({ updatedInstructor: selectedClass[0].instructorId, updatedDate: moment(selectedClass[0].date, "DD/MM/YYYY").format("YYYY-MM-DD") })

  }

  handleClassUpdate = (id: any) => {

    this.setState({ isLoading: true })

    const selectedClass = this.state.classes.filter((cls: any) => {
      return cls.id === id
    })

    const userObj = this.state.users.filter((user: any) => {
      return user.id === selectedClass[0].studentId
    })

    const insObj = this.state.users.filter((ins: any) => {
      return ins.id === selectedClass[0].instructorId
    })

    console.log({ selectedClass: selectedClass[0], user: userObj[0], instructor: insObj[0] })

    const userObjToUpdate = {
      ...userObj[0],
      classSchedules: userObj[0].classSchedules.map((cls: any) => {
        if (cls.date === this.state.selectedClass.date && cls.time === this.state.selectedClass.time) {
          return { date: moment(this.state.updatedDate).format("DD/MM/YYYY"), time: this.state.updatedTime }
        } else {
          return cls
        }
      })
    }

    const insObjToUpdate = {
      ...insObj[0],
      classSchedules: {
        ...insObj[0].classSchedules,
        [userObj[0].id]: userObj[0].classSchedules.map((cls: any) => {
          if (cls.date === this.state.selectedClass.date && cls.time === this.state.selectedClass.time) {
            return { date: moment(this.state.updatedDate).format("DD/MM/YYYY"), time: this.state.updatedTime }
          } else {
            return cls
          }
        })
      }
    }

    const classObjToUpdate = {
      ...selectedClass[0],
      date: moment(this.state.updatedDate).format("DD/MM/YYYY"),
      time: this.state.updatedTime
    }

    console.log({ updatedUser: userObjToUpdate, updateIns: insObjToUpdate, updatedClass: classObjToUpdate })


    if (this.state.selectedClass.instructorId !== this.state.updatedInstructor) {

      const newIns = this.state.users.filter((user: any) => {
        return user.id === this.state.updatedInstructor
      })

      const classess = newIns[0]?.classSchedules?.hasOwnProperty(userObj[0].id)  ? newIns[0]?.classSchedules[userObj[0].id] : false

      const oldInsUpdate = {
        ...insObj[0],
        classSchedules: {
          ...insObj[0].classSchedules,
          [userObj[0].id]: userObj[0].classSchedules.filter((cls: any) => {
            return cls.date !== this.state.updatedDate && cls.time !== this.state.updatedTime
          })
        }
      }


      const newInsUpdate = classess ? {
        ...newIns[0],
        classSchedules: {
          ...newIns[0].classSchedules,
          [userObj[0].id]: [
            ...classess,
            { date: this.state.updatedDate, time: this.state.updatedTime }
          ]
        }
      } : {
        ...newIns[0],
        classSchedules: {
          ...newIns[0].classSchedules,
          [userObj[0].id]: [
            { date: this.state.updatedDate, time: this.state.updatedTime }
          ]
        }
      }

      const objUpdate = {
        ...selectedClass[0],
        instructorId: this.state.updatedInstructor,
        instructor: newIns[0].name,
        date: moment(this.state.updatedDate).format("DD/MM/YYYY"),
        time: this.state.updatedTime
      }

      Promise.all([RRServer.update({
        table: RRServer.Tables.USERS,
        id: userObj[0]?.id,
        ...userObjToUpdate,
      }),
      RRServer.update({
        table: RRServer.Tables.USERS,
        id: insObj[0]?.id,
        ...oldInsUpdate,
      }),
      RRServer.update({
        table: RRServer.Tables.USERS,
        id: this.state.updatedInstructor,
        ...newInsUpdate
      }),
      RRServer.update({
        table: RRServer.Tables.CLASS_SCHEDULES,
        id: selectedClass[0]?.id,
        ...objUpdate,
      })]).then((res: any) => {
        this.setState({ isLoading: false })
        message.success("Class successfully Rescheduled", 3, () => window.location.reload())
      })

    } else {

      Promise.all([RRServer.update({
        table: RRServer.Tables.USERS,
        id: userObj[0]?.id,
        ...userObjToUpdate,
      }),
      RRServer.update({
        table: RRServer.Tables.USERS,
        id: insObj[0]?.id,
        ...insObjToUpdate,
      }),
      RRServer.update({
        table: RRServer.Tables.CLASS_SCHEDULES,
        id: selectedClass[0]?.id,
        ...classObjToUpdate,
      })]).then((res) => {
        this.setState({ isLoading: false })
        message.success("Class successfully Rescheduled", 3, () => window.location.reload())
      })

    }
  }

  handleCheckList = (e: any, id: any, state: any) => {
    console.log(state, 'statee')


    if (e.target.checked) {
      this.setState({ checkList: [...this.state.checkList, id] })
    } else {
      this.setState({
        checkList: this.state.checkList.filter((val) => {
          return val !== id
        })
      })
    }
  }

  checkIfDataAndTimeSame = (arr: any) => {

    const selectedClasses = this.state?.classSchedules.filter((cls: any) => {
      return arr.includes(cls?.id)
    })

    const date = selectedClasses[0]?.date
    const time = selectedClasses[0]?.time

    return selectedClasses.every((cls) => cls.date === date && cls.time === time)

  }

  handleUpdateAll = () => {
    this.state.checkList.map((cls) => {
      this.handleClassUpdate(cls)
    })
  }


  render() {
    const {
      classSchedules,
      filterSchoolId,
      filterUserId,
      filterDates,
      filterTime,
      schools,
      checkList
    } = this.state;

    let dataSource = classSchedules.map((school: any) => ({
      key: school.id,
      ...school,
    }));


    if (filterSchoolId) {
      dataSource = dataSource.filter((d) => d.date === filterSchoolId);
    }
    if (filterUserId) {
      dataSource = dataSource.filter((d) => d.studentId === filterUserId);
    }
    if (filterDates) {
      dataSource = dataSource.filter((d) => d.date === filterDates);

      // dataSource = dataSource.filter(item => {
      //   let itemDate = item.date.split('/').reverse().join("/")
      //   let startDateModified = filterDates[0].split('/').reverse().join("/")
      //   let endDateModified = filterDates[1].split('/').reverse().join("/")
      //   return (new Date(itemDate).getTime() >= new Date(startDateModified).getTime() && new Date(itemDate).getTime() <= new Date(endDateModified).getTime());
      // })

    }

    if (filterTime) {
      dataSource = dataSource.filter((d) => d.time === filterTime);
    }

    const columns = [
      {
        Header: "Schools",
        accessor: "school",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Instructor",
        accessor: "instructor",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Category",
        accessor: "studentId",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "User",
        accessor: "student",
        // disableFilters: true
      },

      {
        Header: "Date",
        accessor: "date",
        Filter: DateColumnFilter,
        disableFilters: true

      },


      {
        Header: "Time",
        accessor: "time",
        Filter: TimeColumnFilter,
        disableFilters: true

      },
      {
        Header: "Status",
        accessor: "status",
        Filter: SelectColumnFilter,

      },
      {
        Header: "Rating",
        accessor: "rating",
      },
      {
        Header: "Comments",
        accessor: "remarks",
      },
      {
        Header: "Reschedule",
        accessor: "id",
        disableFilters: true,
        Cell: (val: any) => {
          return <div className="form-check">
            <input className="form-check-input" checked={this.state.checkList.includes(val.value)} onChange={(e) => this.handleCheckList(e, val.value, this.state)} type="checkbox" value="" id="flexCheckCheckedDisabled" />
          </div>
        }
      },

    ];
    let uniqueStudentsHash: { [key: string]: ClassScheduleInterface } = {};
    classSchedules.map((csh) => (uniqueStudentsHash[csh.studentId] = csh));
    const uniqueStudents = Object.values(uniqueStudentsHash);

    const slotss = this.showAvailableSlots(this.state.selectedClass.date)

    const customSlotss = this.showAvailableSlotsIns(this.state.updatedDate, this.state.updatedInstructor)

    return (
      <>
        <TopBar title="Classes" />

        <div className="contentContainer">
          <div className="contentTop">
            <h5>Class List</h5>
            {/* <RangePicker format={"DD/MM/YYYY"} onChange={(date, dateString) => this.setState({ filterDates: dateString })} /> */}

          </div>


          <div className="table show">
            <div>
              <DatePicker
                style={{ width: 200, marginLeft: "50px" }}
                format="DD/MM/YYYY"
                // value={this.state.filterDates}
                onChange={(date, dateString) => this.setState({ filterDates: dateString })} />

              <TimePicker format={"HH:mm"} onChange={(time, timeString) => this.setState({ filterTime: timeString })} />

              {/* <Select
              showSearch
              style={{ width: 200, marginLeft: "50px" }}
              placeholder="Filter By user"
              optionFilterProp="children"
              onChange={(id) => this.setState({ filterSchoolId: id + "", })}

              filterOption={(input: any, option: any) => {
                return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
              }
              }
            >

            </Select> */}
              {/* <button style={{
              width: "200px",
              position: "relative",
              left: "70%"
            }}
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#rescheduleModal" disabled={!this.checkIfDataAndTimeSame(this.state.checkList)} >Reschedule</button> */}


              <button style={{
                width: "200px",
                // position: "relative",
                // left: "70%"
              }}
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#rescheduleModal" disabled={!this.state.filterDates || !this.state.filterTime} >Reschedule</button>
            </div>

            <TableContainer columns={columns} data={dataSource} />

          </div>

          {/* Modal Starts */}
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Comment
                  </h5>
                </div>
                <div className="modal-body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  {/* <button type="button" className="btn btn-primary">Understood</button> */}
                </div>
              </div>
            </div>
          </div>
          {/* Modal Ends */}


          {/* Modal Starts */}
          <div
            className="modal fade"
            id="rescheduleModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="rescheduleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="rescheduleModalLabel">
                    Reschedule
                  </h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">



                </div>


                {this.state.showInsReschedule &&
                  <>
                    <div className="col-lg">

                      <div className="form-floating  mb-3 pt-2">
                        <select value={this.state.updatedInstructor} onChange={(e) => this.setState({ updatedInstructor: e.target.value })} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                            <option value="">Select Instructor form dropdown</option>
                          {this.state.users.map((user: any) => {
                            if (user.type === "DRIVING_SCHOOL_INSTRUCTOR") {
                              return <option value={user.id}>{user.name}</option>
                            }
                          })}

                        </select>
                        <label htmlFor="floatingSelect">Instructors</label>
                      </div>
                    </div>

                    <div className="row g-2">

                      <div className="col-md">

                        <div className="form-floating mb-3">
                          <input type="date" className="form-control" value={this.state.updatedDate} onChange={(e) => this.setState({ updatedDate: e.target.value })} id="floatingInput" placeholder="name@example.com" />
                          <label htmlFor="floatingInput">Date</label>
                        </div>
                      </div>


                      <div className="col-md">

                        <div className="form-floating  mb-3 pt-2">
                          <select value={this.state.updatedTime} onChange={(e) => this.setState({ updatedTime: e.target.value })} className="form-select" id="floatingSelect" aria-label="Floating label select example">
                            {customSlotss && customSlotss.map((time: any, idx: any) => {
                              if (idx === 0) {
                                return <option selected value={time}>{time}</option>
                              } else {
                                return <option value={time}>{time}</option>
                              }
                            })}
                          </select>
                          <label htmlFor="floatingSelect">Time</label>
                        </div>
                      </div>
                    </div>
                  </>
                }


                {!this.state.showInsReschedule && <p
                  style={
                    {
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "end",
                      padding: "0 20px"
                    }}
                  onClick={() => this.setState({ showInsReschedule: !this.state.showInsReschedule })}>Click here to reschedule to another instructor</p>}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    // data-bs-dismiss="modal"
                    disabled={this.state.isLoading}
                    onClick={() => this.handleUpdateAll()}
                  >
                    Submit
                  </button>
                  {/* <button type="button" className="btn btn-primary">Understood</button> */}
                </div>
              </div>
            </div>
          </div>
          {/* Modal Ends */}


          <div
            className="modal fade"
            id="exampleModal"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="Name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Data one"
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Due Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Status</label>
                      <input type="text" className="form-control" value="1" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Paid Date</label>
                      <input type="date" className="form-control" />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="Name">Rating</label>
                      <select name="rating" id="" className="form-control">
                        <option value="">--Rating--</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal1"
            aria-labelledby="exampleModalLabel1"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <table className="table table-hover">
                    <tr>
                      <th>Name</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Paid Date</th>
                      <th>Rating</th>
                    </tr>
                    <tr>
                      <td>Data one</td>
                      <td>07-05-2021</td>
                      <td>Paid</td>
                      <td>12-12-22</td>
                      <td>1</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BulkRescheduling;

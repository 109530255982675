import React, { Component } from 'react'
import { RRServer } from '../../../utils/lib';
import { SelectColumnFilter } from '../../TableComp/Filter';
import TableContainer from '../../TableComp/TableContainer'
import TopBar from '../../TopBar'
import Enquires from './Enquires';

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
}

interface UserStateProps {
    schools: DrivingSchoolInterface[]
    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    filterSchoolId: string,
    isToggle: boolean,
    isSearch: boolean,
    claims:any,
    isSalesUser: boolean,
    enquiry: any[],
    isAdmin: string
}

export default class EnquiresList extends Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            selectedSchool: null,
            userCode: '',
            filterSchoolId: '',
            isToggle: true,
            isSearch: false,
            claims: null,
            isSalesUser: false,
            enquiry: [],
            isAdmin: ""

        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims: any = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        this.setState({isAdmin : claims.drivingSchoolId})
    
        if (claims.drivingSchoolId === '*') {
    
          RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }).then((result: any) => {
            // console.log('dataaaa gett',result)
            let schools = [result?.data?.data];
            this.setState({ schools })
          });
    
          RRServer.list({ table: 'enquiries', forCurrentUser: false }).then((result: any) => {
            console.log('dataaaa listt enq', result)
    
            let enquiry = result.data.data;
            this.setState({ enquiry })
          });
    
        } else if (claims.drivingSchoolId === '#' && claims.userType === 'CLUSTER') {
          RRServer.get({ table: 'driving_schools', id: claims.clusterID }).then((result: any) => {
            // console.log('dataaaa gett',result)
            let schools = [result?.data?.data];
            this.setState({ schools })
          });
    
        } else if (claims.drivingSchoolId === '#' && claims.userType === 'ASSOCIATION') {
          RRServer.get({ table: 'driving_schools', id: claims?.associationID }).then((result: any) => {
            // console.log('dataaaa gett',result)
            let schools = [result?.data?.data];
            this.setState({ schools })
          });
    
        }
    
        else {
          RRServer.list({ table: 'enquiries', forCurrentUser: false, where: [["companyId", "==", claims.drivingSchoolId]] }).then((result: any) => {
            console.log('dataaaa listt enq', result)
    
            let enquiry = result.data.data;
            this.setState({ enquiry })
          });
        }
      }
    

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }


    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle });
        // console.log(this.state.isToggle);
    }

    handleValues = (values: any) => {
        console.log(values)
    }
    render() {

        const { schools, filterSchoolId } = this.state;
        // let { url } = this.props.match;

        const assocSchools = schools.filter((skl:any)=>{
            return skl?.association === 'UdfmX6hM1agck2ThdmOW'
            // return skl?.association === 'GFEHL3HrDqba8yEXBdnt8DPXQ1E2'

        })



        // console.log(assocSchools,'asso skl')

        let dataSource = schools.map(school => ({ key: school?.id, ...school }))

        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d?.id === filterSchoolId)
        }

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Filter: SelectColumnFilter,
                // disableSortBy: true
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
                // disableFilters: true
            },
            {
                Header: 'Remarks',
                accessor: 'remarks',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
           
        ]
        return (
            <>
                <TopBar title="Driving Schools" />
                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>{this.state.isToggle ? 'Enquiry List' : 'Add Enquiry'}</h5>

                         {this.state.isAdmin !== "*" && <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            {/* <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>{this.state.isToggle ? 'ADD DRIVING SCHOOL' : 'CANCEL'}</button> */}
                            {<button className='btn btn-primary' onClick={(e) => window.location.href = '/enquires/add'}>Add Enquiry</button>}

                        </div> }
                    </div>
                    <div className={`${this.state.isToggle ? 'table show' : 'table hide'}`}>

                        <TableContainer columns={columns} data={this.state.enquiry} />

                    </div>
                    <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
                        {/* <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Submit' /> */}
                        {/* <DrivingSchoolAdd/> */}
                        {/* <DrivingAdd/> */}
                        <Enquires/>
                    </div>
                </div>
            </>
        )
    }
}

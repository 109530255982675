import React, { Component } from "react";
import styles from "../../../FormComponent/FormComponent.module.css";
import Slider from "react-slick";
import moment from "moment";
import { RRServer } from "../../../../utils/lib";
import { time } from "console";
import { Button, message } from "antd";

interface AddUserFormState {
  monday: any;
  tuesday: any;
  wednesday: any;
  thursday: any;
  friday: any;
  saturday: any;
  sunday: any;

  studentClasses: any;
  isAllClasses: number;

  isClasses: boolean;

  instructor: string;
  selectDate: string;
  noOfClasses: any;
  noOfInstallments: string;
  installmentDates: any;
  scheduledClasses: any;
  submitLoading: boolean
}

interface AddUserFormProps {
  instructors: any;
  handleSlots: any;
}

class AddUserForm extends Component<AddUserFormProps, AddUserFormState> {
  state: AddUserFormState = {
    monday: [
    
    ],
    tuesday: [
   
    ],
    wednesday: [
     
    ],
    thursday: [
     
    ],
    friday: [
     
    ],
    saturday: [

    ],
    sunday: [
   
    ],

    isClasses: false,

    studentClasses: {
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    },
    isAllClasses: 0,

    instructor: "",
    selectDate: "",
    noOfClasses: null,
    noOfInstallments: "",
    installmentDates: [],
    scheduledClasses:[],
    submitLoading: false
  };

  componentDidMount = () =>{
    RRServer.list({ table: 'class_schedules',  forCurrentUser: false }).then((result: any) => {
      console.log('dataaaa listt',result)

      let scheduledClasses = result.data.data;
      this.setState({ 
        

       })
  });
  }

  convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
  };

  tConv24 = (time24: any) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h: any = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  handleSlot = (e: any, timing: any) => {
    e.preventDefault();

    console.log("eee", e);
    console.log("timee", timing);

    if (e.target.classList.contains("selected")) {
      this.setState({ isAllClasses: this.state.isAllClasses - 1 });

      if (e.target.name === "monday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Mon: [
              ...this.state.studentClasses.Mon.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "tuesday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Tue: [
              ...this.state.studentClasses.Tue.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "wednesday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Wed: [
              ...this.state.studentClasses.Wed.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "thursday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Thu: [
              ...this.state.studentClasses.Thu.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "friday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Fri: [
              ...this.state.studentClasses.Fri.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "saturday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sat: [
              ...this.state.studentClasses.Sat.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      } else if (e.target.name === "sunday") {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sun: [
              ...this.state.studentClasses.Sun.filter(
                (val: any) => val !== this.convertTime12to24(timing)
              ),
            ],
          },
        });
      }

      e.target.firstElementChild.innerText =
        parseInt(e.target.firstElementChild.innerText) + 1;
      e.target.classList.remove("selected");
    } else {
      if (
        e.target.name === "monday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Mon: [
              ...this.state.studentClasses.Mon,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "tuesday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Tue: [
              ...this.state.studentClasses.Tue,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "wednesday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Wed: [
              ...this.state.studentClasses.Wed,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "thursday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Thu: [
              ...this.state.studentClasses.Thu,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "friday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Fri: [
              ...this.state.studentClasses.Fri,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "saturday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sat: [
              ...this.state.studentClasses.Sat,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      } else if (
        e.target.name === "sunday" &&
        this.state.noOfClasses > this.state.isAllClasses
      ) {
        this.setState({
          studentClasses: {
            ...this.state.studentClasses,
            Sun: [
              ...this.state.studentClasses.Sun,
              timing,
            ],
          },
        });
        this.setState({ isAllClasses: this.state.isAllClasses + 1 });
      }

      if (this.state.noOfClasses > this.state.isAllClasses) {
        e.target.firstElementChild.innerText =
          e.target.firstElementChild.innerText - 1;
        e.target.classList.add("selected");
      }
    }
  };

  setSlots = (instructorId: string) => {
    const insSlots = this.props.instructors.filter(
      (ins: any) => ins.id === instructorId
    );
    console.log("insss", insSlots[0].slots);
    this.setState({
      monday: [...insSlots[0].slots.Mon],
      tuesday: [...insSlots[0].slots.Tue],
      wednesday: [...insSlots[0].slots.Wed],
      thursday: [...insSlots[0].slots.Thu],
      friday: [...insSlots[0].slots.Fri],
      saturday: [...insSlots[0].slots.Sat],
      sunday: [...insSlots[0].slots.Sun],
    });
  };

  handleIns = (e: any) => {
    this.setState({ instructor: e.target.value , studentClasses :{
      Mon: [],
      Tue: [],
      Wed: [],
      Thu: [],
      Fri: [],
      Sat: [],
      Sun: [],
    }, isAllClasses: 0});
    
    // var elements :any= document.getElementsByClassName('timingSlots');
    // elements.classList.remove("selected");


    Array.from(document.querySelectorAll('.timingSlots.selected')).forEach(function(el) { 
      el.classList.remove('selected');
  });
    
    const scheduledClassesUpt = this.state.scheduledClasses.filter((cls:any)=>{
       return cls.instructorId === e.target.value
    })
    this.setState({scheduledClasses: scheduledClassesUpt})
    this.setSlots(e.target.value);
  };

  handleSubmit = (e: any) => {
    e.preventDefault();

    const {
      selectDate,
      studentClasses,
      installmentDates,
      noOfClasses,
      instructor,
    } = this.state;

    this.props.handleSlots({
      startDate: selectDate,
      slots: studentClasses,
      installmentDates: installmentDates,
      noOfClasses: noOfClasses,
      instructorId: instructor,
    });
 
  };

  getDaysFromClass = (studentClasses: any) => {
    const arr: any = [];
    const properties = Object.keys(studentClasses);
    properties.map((day) => {
      if (studentClasses[day].length >= 1) {
        arr.push(day);
      }
    });
    return arr;
  };
  getDates = (num: any) => {
    const dates: any = [];
    if (dates.length >= num) return dates;
    for (let i = 0; i <= num; i++) {
      const days = this.getDaysFromClass(this.state.studentClasses);
      const dateee = moment(this.state.selectDate)
        .add(i, "days")
        .format("DD-MM-YYYY");
      const day = moment(this.state.selectDate).add(i, "days").format("dddd");
      //  console.log('datee',dateee, 'day', day)
      if (days.includes(day.slice(0, 3))) {
        dates.push(dateee);
      }
    }
    return dates;
  };

   checkArraysLength  = (arrays : any) => {

   const result =  arrays.filter((arr : any)=>{
      return  arr.length >= 1
    })

    return result.length
  }

  handleFormSubmit = (e:any) =>{

  

    const {Mon, Tue, Wed, Thu, Fri, Sat, Sun} = this.state.studentClasses


    if(this.checkArraysLength([Mon, Tue, Wed, Thu, Fri, Sat, Sun]) === 0) {
      message.error("Please Select atleast one class" , 3)
    } else {

    this.setState({submitLoading: true})
    this.handleSubmit(e);
    }

  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
    };

    const inputs = [];

    for (let i = 1; i <= parseInt(this.state.noOfInstallments); i++) {
      inputs.push(
        <div className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}>
          <input
            className={`form-control`}
            type={"date"}
            name={`InstallmentDate-${i}`}
            onChange={(e) =>{
              const newArr = this.state.installmentDates.filter((ins:any)=>{
                return ins.id !== i
              })
              this.setState({
                installmentDates: [
                  ...newArr,
                  {
                    id: i,
                    installment: e.target.name,
                    dueDate: e.target.value,
                    status: 0,
                    paidOn: -1,
                  },
                ],
              })}
            }
            placeholder="select Date"
            maxLength={200}
            id="selectDate"
            required={true}
          />
          <label
            htmlFor="selectDate"
            className={`px-4`}
          >{`Select Date for installment ${i}`}</label>
        </div>
      );
    }

    //   const convertToDate = (dateString:any) => {
    //     //  Convert a "dd/MM/yyyy" string into a Date object
    //     let d = dateString.split("/");
    //     let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    //     return dat;
    // }

    const getKey = (obj: any) => {
      var arr = [];
      for (var x of Object.keys(obj)) {
        if (this.state.studentClasses[x].length > 0) {
          arr.push(x);
        }
      }
      return arr;
    };

    // if(this.state.noOfClasses) {
    //       var dates : any  = [];
    //      var i = 0
    //   console.log('len', dates.length)

    //   while(dates.length > this.state.noOfClasses){
    //     const keys = getKey(this.state.studentClasses);
    //     const date = moment(this.state.selectDate).format('L');
    //     if(keys.includes(moment(moment(date).add(i, 'days').calendar()).format('dddd').slice(0,3))){
    //       dates.push(moment(date).add(i, 'days').calendar())
    //     }
    //   }
    // }

    // do {

    //   i++
    // } while (dates.length === this.state.noOfClasses);

    const getDates = (num: any) => {
      var dates: any = [];
      const keys = getKey(this.state.studentClasses);
      const startDate = this.state.selectDate;

      if (startDate && keys.length >= 1) {
        for (var i = 0; dates.length < num; i++) {
          // if(keys.includes((moment(moment(startDate).add(i, 'days').format('L')).format('dddd')).slice(0,3))){
          let date = moment(startDate).add(i, "days").format("L");
          const day = moment(moment(startDate).add(i, "days").format("L"))
            .format("dddd")
            .slice(0, 3);
          // console.log('keee', keys, 'istru', keys.includes(day))
          if (keys.includes(`${day}`)) {
            dates.push(date);
          }
          // dates.push({date: moment(startDate).add(i, 'days').format('L'), day: (moment(moment(startDate).add(i, 'days').format('L')).format('dddd'))})
        }
      }
      return dates;
    };

    return (
      <div className="formTwoWrapper">
        <div className="formInputs formInputsA" style={{ height: "400px" }}>
          <div className="formWrapper user">
            <div className={styles.formWrapper}>
              <form className="container needs-validation">
                <div className="row">
                  <div
                    className={`col-md-12   col-sm-12 mb-2 form-floating d-inline-block`}
                  >
                    <select
                      className={`form-select form-control`}
                      value={this.state.instructor}
                      name="instructor"
                      onChange={(e) => this.handleIns(e)}
                      id="instructor"
                      aria-label="Floating label select example"
                      required={true}
                    >
                      <option value="">Select Value from dropdown...</option>
                      {this.props.instructors.map((ins: any) => {
                        return (
                          <option key={ins.id} value={ins.id}>
                            {ins.name}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="instructor" className={`px-4`}>
                      Choose Instructor
                    </label>
                  </div>
                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type="date"
                      name={"selectDate"}
                      value={this.state.selectDate}
                      onChange={(e) =>
                        this.setState({ selectDate: e.target.value })
                      }
                      placeholder="select Date"
                      maxLength={200}
                      id="selectDate"
                      required={true}
                    />
                    <label htmlFor="selectDate" className={`px-4`}>
                      'Select Date
                    </label>
                  </div>

                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type={"number"}
                      name={"noOfClasses"}
                      value={this.state.noOfClasses}
                      onChange={(e) =>
                        this.setState({
                          noOfClasses: parseInt(e.target.value),
                          isClasses: true,
                        })
                      }
                      placeholder="select No of Classes"
                      maxLength={200}
                      id="noOfClasses"
                      required={true}
                    />
                    <label htmlFor="noOfClasses" className={`px-4`}>
                      No of Classes
                    </label>
                  </div>

                  <div
                    className={`mb-3 form-floating  inline-block col-md-12 col-sm-12`}
                  >
                    <input
                      className={`form-control`}
                      type={"text"}
                      name={"noOfInstallments"}
                      value={this.state.noOfInstallments}
                      onChange={(e) =>
                        this.setState({ noOfInstallments: e.target.value, installmentDates: []  })
                      }
                      placeholder="noOfInstallments"
                      maxLength={200}
                      id="noOfInstallments"
                      required={true}
                    />
                    <label htmlFor="noOfInstallments" className={`px-4`}>
                      No of Installments
                    </label>
                  </div>

                  {inputs.map((input) => {
                    return input;
                  })}
                </div>
                <div
                  className="text-right"
                  id="btns btnsA"
                  style={{ position: "absolute", right: 0, bottom: "-4rem" }}
                >
                  {this.state.instructor &&
                    this.state.selectDate &&
                    this.state.noOfClasses &&
                    this.state.noOfInstallments && (
                      <button
                        className={`${styles.btn} btn btn-primary`}
                        style={{padding:"4px 15px", height:"32px"}}
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#userPreview"
                      >
                        Preview
                      </button>
                    )}
                  {/* <button
                    className={styles.btn}
                    onClick={(e) => {
                      this.handleSubmit(e);
                    }}
                    type="submit"
                  >
                    Submit
                  </button> */}

                  <Button type="primary" className={`${styles.btn} btn btn-primary`} loading={this.state.submitLoading} onClick={(e:any)=>this.handleFormSubmit(e)}> Submit </Button>

                </div>
              </form>
            </div>
          </div>
          <div className="cardPreview user">
            {/* <p>Select Class</p> */}
            <div className="instructorTable">
              <div
                className={`${
                  this.state.isClasses
                    ? "instructorView show"
                    : "instructorView hide"
                }`}
              >
                <div className="tableFormat">
                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Monday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.monday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="monday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Tuesday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.tuesday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="tuesday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Wednesday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.wednesday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="wednesday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Thursday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.thursday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="thursday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Friday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.friday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="friday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Saturday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.saturday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="saturday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>

                  <div className="row" style={{ minHeight: "54px" }}>
                    <div className="title">
                      <p>Sunday</p>
                    </div>
                    <Slider {...settings}>
                      {this.state.sunday.sort().map((timing: any) => {
                        return (
                          <div>
                            <button
                              name="sunday"
                              className="timingSlots"
                              onClick={(e: any) => this.handleSlot(e, timing)}
                            >
                              {this.tConv24(timing)}{" "}
                              <span style={{display:"none"}}>{timing.slots}</span>
                            </button>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="userPreview"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" style={{ zIndex: "100" }}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="userPreview">
                  Preview
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ maxHeight: "70vh", overflowY: "scroll" }}
              >
                <div
                  className="previewContainer"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    className="classesScheduled"
                    style={{ width: "50%", padding: "10px" }}
                  >
                    <h2>Your Classes: </h2>

                    {/* startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD"); */}
                    {getDates(this.state.noOfClasses).map(
                      (date: any, idx: any) => {
                        const daay = moment(moment(date).format("L"))
                          .format("dddd")
                          .slice(0, 3);

                        return (
                          <>

                            <div className="classCard">
                              <span className="number">{idx + 1}</span>
                              <div className="info">
                                <p className="date">{moment(date).format("DD/MM/YYYY")}</p>
                                {daay === "Mon" &&
                                  this.state.studentClasses.Mon.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Tue" &&
                                  this.state.studentClasses.Tue.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Wed" &&
                                  this.state.studentClasses.Wed.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Thu" &&
                                  this.state.studentClasses.Thu.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Fri" &&
                                  this.state.studentClasses.Fri.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Sat" &&
                                  this.state.studentClasses.Sat.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                                    {daay === "Sun" &&
                                  this.state.studentClasses.Sun.map(
                                    (time: any, idx: any) => {
                                      return (
                                        <p className="time" key={idx}>
                                          {" "}
                                          {time},{" "}
                                        </p>
                                      );
                                    }
                                  )}
                              </div>
                              <p className="day">
                              {moment(date)
                                .format("dddd")}
                            </p>
                            </div>
                          </>
                        );
                      }
                    )}

                    
                  </div>
                  <div
                    className="installmentPreview"
                    style={{ width: "50%", padding: "10px" }}
                  >
                    <h2>Installments details:</h2>

                    {this.state.installmentDates.sort((a :any,b:any)=>{
                      return a.dueDate - b.dueDate
                    }).map((date: any, idx: any) => {
                      return (
                        <div className="classCard">
                          <span className="number">{idx + 1}</span>
                          <div className="info">
                            {/* <p className='date'>{date.value}</p> */}
                            <p className="day">
                              {date.dueDate
                                // .add(idx, "days")
}
                            </p>

                            {/* <p className="time">10:00 am - 12:00 pm </p> */}
                          </div>
                          <p style={{ margin: "0" }}>
                            {moment(date.value).format("dddd")}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddUserForm;

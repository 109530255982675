import moment from 'moment';
import React, { Component } from 'react'
import { CorporateCodeInterface, DrivingSchoolInterface, ScoreInterface } from '../../../types';
import { RRServer } from '../../../utils/lib';
import { SelectColumnFilter } from '../../TableComp/Filter';
import TableContainer from '../../TableComp/TableContainer';
import RRLogo from "../../../assets/RRlogo.png"

interface StatsToPrintProps {
    scores: any;
    schoolLogo: any;
    totalScore: any;
    name: string;
    code:string;
    llrNumber:string;
    llrDate:string;
    dlNumber:string;
    dlDate:string;
    dob:string;
    classesCompleted: any;
    numberOfClasses:any
}

    interface DashProps {
    schools: DrivingSchoolInterface[];
    corporateCodes: CorporateCodeInterface[];
    corporateCodesCount: number;
    drivingSchoolCodesCount: number;
    studentsCount: number;
    instructorCount: number;
    adminCount: number;
    loading: boolean;
    classes: any[],
        user: any;
    scores: ScoreInterface[];
}

export default class StatsToPrint extends Component<StatsToPrintProps, DashProps> {



    render() {

        const columns = [
            {
                Header: "Date",
                accessor: "addedOn",
                disableFilters: true

            },
            {
                Header: "Game",
                accessor: "game",
                disableFilters: true
            },
            {
                Header: "Attempt",
                accessor: "attemptNumber",
                disableFilters: true
            },
            {
                Header: "Score",
                accessor: "score",
                disableFilters: true
            },
        ];


        return (
            <div style={{padding:"20px"}}>

                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <img src={this.props.schoolLogo} alt="" />
                    <img src={RRLogo} alt="" />
                </div>

                <div>

                    <h5 style={{ position: "relative" }}>{`Student Stat for ${this.props.name} (${this.props.code})`}
                        <span style={{ position: "absolute", right: 0 }}>{`Total Score : ${this.props.totalScore}`}</span>
                        <p style={{ padding: "0", margin: "1px" }}> {`Date of Birth : ${this.props.dob}`} </p>

                        { this.props.llrNumber && <p style={{ padding: "0", margin: "1px" }}>
                            {`LLR No - ${this.props.llrNumber}  - LLR Date :  ${this.props.llrDate}`}
                        </p>}
                        { this.props.dlNumber && <p style={{ padding: "0", margin: "1px" }}>
                            {`DL No - ${this.props.dlNumber}  - LLR Date :  ${this.props.dlDate}`}
                        </p>}

                        { <p style={{ padding: "0", margin: "1px" }}> {`Total Classes attended : ${this.props.classesCompleted} /${this.props.numberOfClasses}`}  </p>}
                    </h5>

                </div>


                <TableContainer columns={columns} data={this.props.scores.map((score: any) => {
                    return { ...score, game: `${score.game} - Level ${score.level}`, addedOn: ((new Date(score.addedOn).toISOString().substr(0, 10))).replaceAll('-', "/").split("/").reverse().join("/").toString() }
                })} />
            </div>
        )
    }
}

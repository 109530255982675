import { Row, Col, Tabs, Skeleton } from "antd";
import React, { ReactInstance } from "react";
import {
  CorporateCodeInterface,
  DrivingSchoolInterface,
  ScoreInterface,
  UserInterface,
} from "../../../types";
import { RRServer } from "../../../utils/lib";

import dayjs from "dayjs";
import { match } from "assert";
import TopBar from "../../TopBar";
import { SelectColumnFilter } from "../../TableComp/Filter";
import TableContainer from "../../TableComp/TableContainer";
import moment from "moment";
import ReactToPrint from "react-to-print";
import StatsToPrint from "./StatsToPrint";


const { TabPane } = Tabs;

interface DashProps {
  schools: DrivingSchoolInterface[];
  corporateCodes: CorporateCodeInterface[];
  corporateCodesCount: number;
  drivingSchoolCodesCount: number;
  studentsCount: number;
  instructorCount: number;
  adminCount: number;
  loading: boolean;
  classes: any[],
  user: any;
  scores: ScoreInterface[];
  schoolLogo: any;
}

interface studentStatProps {
  studentId: string;
}

class StudentStatsList extends React.Component<studentStatProps, DashProps> {
  componentRef: any | null;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      schools: [],
      corporateCodes: [],
      corporateCodesCount: 0,
      drivingSchoolCodesCount: 0,
      studentsCount: 0,
      instructorCount: 0,
      adminCount: 0,
      classes: [],
      user: null,
      scores: [],
      schoolLogo: ""
    };
  }


  GetFormattedDate() {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var monthFormat = month.toString().length === 1 ? `0${month}` : month
    var day = todayTime.getDate();
    var dayFormat = day.toString().length === 1 ? `0${day}` : day
    var year = todayTime.getFullYear();
    return dayFormat + "/" + monthFormat + "/" + year;
  }

  componentDidMount() {
    const id = this.props.studentId;
    console.log("iddd", id);

    const user: any = localStorage.getItem('user')
    this.setState({ schoolLogo: JSON.parse(user).user.photoURL })


    Promise.all([
      RRServer.get({ table: RRServer.Tables.USERS, id }),
      RRServer.list({
        table: RRServer.Tables.SCORES,
        forCurrentUser: false,
        where: [["uid", "==", id]],
      }),
      RRServer.list({
        table: "class_schedules",
        forCurrentUser: false,
        where: [["studentId", "==", id]],
      }),
    ]).then((results: any[]) => {
      this.setState({
        user: results[0].data.data,
        scores: results[1].data.data,
        classes: results[2].data.data.map((c: any, idx: any) => {


          if (moment(c.date, "DD/MM/YYYY").isBefore() && c.hasOwnProperty('classStarted') === false) {
            return {
              ...c,
              status: 'Not attended'
            }
          } else if (c.hasOwnProperty('classStarted') && c.hasOwnProperty('classEndTime') === false) {
            return {
              ...c,
              status: "On Board"
            }
          } else if (c.hasOwnProperty('classEndTime')) {
            return {
              ...c,
              status: "Completed"
            }

          } else if (moment(c.date, "DD/MM/YYYY").isAfter()) {
            return {
              ...c,
              status: "Pending"
            }
          } else if (c.date === this.GetFormattedDate() && c.hasOwnProperty('classStarted') === false) {
            return {
              ...c,
              status: "Yet to Board"
            }
          } else if (c.date === this.GetFormattedDate() && c.hasOwnProperty('classStarted') === true) {
            return {
              ...c,
              status: "On Going"
            }
          } else {
            return {
              ...c,
              status: 'Yet to board'
            }
          }

        }).filter((cls: any) => {
          return cls.status === "Completed"
        })
      });
    });
  }



  render() {
    const columns = [
      {
        Header: "Date",
        accessor: "addedOn",

        Filter: SelectColumnFilter,
        // Cell:(val:any)=>{
        //   return moment(val).format("L")
        // }
      },
      {
        Header: "Game",
        accessor: "game",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Attempt",
        accessor: "attemptNumber",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Score",
        accessor: "score",
        Filter: SelectColumnFilter,
      },
    ];

    const { user, scores } = this.state;

    //Total score from attempt 1
    var totalScore: number = 0;

    for (var i = 0; i < scores.length; i++) {
      if (scores[i].attemptNumber == 1) {
        totalScore += scores[i].score;
      }
    }

    return (
      <>
        <h5 style={{ position: "relative" }}>{`Student Stat for ${this.state.user?.name} (${this.state.user?.code})`}
          <span style={{ position: "absolute", right: 0 }}>{`Total Score : ${totalScore}`}</span>
          <p style={{ padding: "0", margin: "1px" }}> {`Date of Birth : ${this.state.user?.dob}`}</p>

          {this.state.user?.llr && this.state.user?.llrNumber && <p style={{ padding: "0", margin: "1px" }}>
            {`LLR No - ${this.state.user?.llrNumber}  - LLR Date :  ${this.state.user?.llrDate}`}
          </p>}

          {this.state.user?.dl && this.state.user?.dlNumber && <p style={{ padding: "0", margin: "1px" }}>
            {`DL No - ${this.state.user?.dlNumber}  - LLR Date :  ${this.state.user?.dlDate}`}
          </p>}

          {this.state.user?.numberOfClasses && <p style={{ padding: "0", margin: "1px" }}> {`Total Classes attended : ${this.state.classes.length} /${this.state.user?.numberOfClasses}`}  </p>}
        </h5>

        {<>
          <ReactToPrint
            trigger={() => <button style={{ width: "110px" }} className="btn btn-primary">Print</button>}
            content={() => this.componentRef}
          />


          <div style={{ display: 'none' }}>
            <StatsToPrint
              totalScore={totalScore}
              dob={this.state.user?.dob}
              name={this.state.user?.name}
              code={this.state.user?.code}
              llrNumber={this.state.user?.llrNumber}
              llrDate={this.state.user?.llrDate}
              dlNumber={this.state.user?.dlNumber}
              dlDate={this.state.user?.dlDate}
              schoolLogo={this.state.schoolLogo}
              classesCompleted={this.state.classes.length}
              numberOfClasses={this.state.user?.noOfClasses}
              scores={this.state.scores.map((score) => {
                return { ...score, game: `${score.game} - Level ${score.level}`, addedOn: ((new Date(score.addedOn).toISOString().substr(0, 10))).replaceAll('-', "/").split("/").reverse().join("/").toString() }
              })} ref={(el: any) => (this.componentRef = el)} />
          </div>
        </>}

        <div className="table show">
          <TableContainer columns={columns} data={this.state.scores.map((score) => {
            return { ...score, game: `${score.game} - Level ${score.level}`, addedOn: ((new Date(score.addedOn).toISOString().substr(0, 10))).replaceAll('-', "/").split("/").reverse().join("/").toString() }
          })} />
        </div>
      </>
    );
  }
}

export default StudentStatsList;

import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton } from 'antd';
import { callOnServer, getClaims, RRServer } from '../../../utils/lib';
import FormComponent from '../../FormComponent/FormComponent';
import TopBar from '../../TopBar';
import { useParams } from 'react-router-dom';
// import MaskedInput from 'antd-mask-input'
// import { callOnServer, RRServer } from '../../utils/lib';




interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
    pricePerCode: number
    password: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
    isToggle: boolean,
    associations: any,
    clusters: any,
    claims: any
    schools: any[]
}

class DrivingSchoolAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            mode: window.location.href.includes("edit") ? 'edit' : 'add',
            isToggle: false,
            associations: null,
            clusters: null,
            claims: null,
            schools: []
        }
        // this.onFinish = this.onFinish.bind(this);
        this.addDrivingSchool = this.addDrivingSchool.bind(this);
    }


    componentDidMount() {


        const claimsString = localStorage.getItem('claims') || '{}';
        this.setState({ claims: JSON.parse(claimsString) })
        var rest = window.location.href.substring(0, window.location.href.lastIndexOf("/") + 1);
        var last = window.location.href.substring(window.location.href.lastIndexOf("/") + 1, window.location.href.length);


        if (this.state.mode === 'edit') {
            Promise.all([
                RRServer.get({ table: 'driving_schools', id: last }),
                callOnServer('find', { table: 'corporate_codes', where: [['companyId', '==', last]] })
            ]).then(async (results: any) => {
                // console.log('prev statee',this.state)
                await this.setState({
                    currentSchool: results[0].data.data,
                    // corporateCode: results[1].data.data
                })
                // console.log('after statee',this.state)
            })
        }

        RRServer.list({ table: 'associations', forCurrentUser: false }).then((result: any) => {
            let schools = result.data.data;
            this.setState({ associations: schools })
        });

        RRServer.list({ table: 'clusters', forCurrentUser: false }).then((result: any) => {
            let clusters = result.data.data;
            this.setState({ clusters: clusters })
        });

        RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {
            // console.log('dataaaa listt',result)

            let schools = result.data.data;
            this.setState({ schools })
        });
    }


    addDrivingSchool(school: DrivingSchoolInterface & { password?: string, credits: number }) {
        const { mode, currentSchool, corporateCode } = this.state;
        const currentCount = school.code.split('').slice(school.code.length - 3).join('');

        if (mode === 'edit') {
            let _school = school;
            school.id = currentSchool!.id;
            const password = _school.password;
            // delete _school.password;
            Promise.all([
                RRServer.update({ ...school, table: 'driving_schools' }),
                RRServer.update({ password: password, id: corporateCode!.id, table: 'corporate_codes' })
            ])
                .then((results: any) => {
                    if (results[0].data.s && results[1].data.s) {
                        message.success('Successfully Updated Driving School');
                        setTimeout(() => window.location.href = '/drivingschool', 200)
                    }
                })
        } else {
            const params = {
                ...school,
                currentCount: "B00",
                currentCountAdmin: "A00",
                instructorCode: "123456A20",
                instructorCurrentCount: "A20",
                officeAdminCurrentCount: "A10",
                adminAndInstructorCurrentCount: "A50"
            };

            const fnNameOLD = 'addDrivingSchool';
            const fnName = 'ds1-add';
            RRServer.callFn(fnName, params).then((result: any) => {
                if (result.data.s) {
                    if (school.credits) {
                        RRServer.add({
                            table: 'payments', data: {
                                razorpayPaymentId: 'FREE CREDIT',
                                razorpayOrderId: 'FREE CREDIT',
                                amount: 0,
                                codesToBuy: school.credits,
                                pricePerCode: school.pricePerCode,
                                schoolId: result.data.id,
                                schoolName: school.drivingSchoolName
                            }
                        })
                            .then(() => {
                                message.success('Successfully Added Driving School');
                                // console.log('addedd')
                                setTimeout(() => window.location.href = '/drivingschools', 300)
                            })
                    } else {
                        message.success('Successfully Added Driving School');
                        // console.log('added')
                        setTimeout(() => window.location.href = '/drivingschools', 300)
                    }
                    message.success('Successfully Added User Driving School', 1.5, () => {
                        window.location.reload()
                    });

                }
            }).catch(function (error) {
                alert(error);
            })
        }
    }


    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ isToggle: !this.state.isToggle });
        // console.log(this.state.isToggle);
    }

    handleValues = (values: any) => {

        const codesAvailable = this.state.schools?.map((skl) => {
            return skl.code
        })

        if (codesAvailable.includes(values.code)) {
            message.error("Code is already declared. Kindly use new code", 2)
        } else {
            this.addDrivingSchool(values)
        }

    }

    render() {
        const { mode, currentSchool, corporateCode } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const addFields: any = [
            { type: 'text', name: 'drivingSchoolName', label: 'Name', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'password', label: 'Password', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'code', isCode: true, label: 'Code', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'sosNumber', label: 'SOS Phone Number', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'pricePerCode', label: 'Price Per Code', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'credits', label: 'Credits', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            {
                type: 'select', name: 'association', label: 'Association', options: this.state.associations?.map((ass: any, idx: any) => {

                    return { value: ass.id, label: ass.name }
                }), isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true
            },
            {
                type: 'select', name: 'cluster', label: 'Cluster', options: this.state.clusters?.map((cluster: any, idx: any) => {
                    return { value: cluster.id, label: cluster.name }
                }), isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true
            },
            { type: 'text', name: 'profile', value: "we are the best school in the city", label: 'Profile', isMandatory: false, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'file', name: 'drivingSchoolUnionImg', label: 'Union Image', isMandatory: false, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
        ]

        const editFields: any = [
            { type: 'text', name: 'drivingSchoolName', value: this.state.currentSchool?.drivingSchoolName, label: 'Name', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'password', value: this.state.currentSchool?.password, label: 'Password', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'code', isCode: true,   value: this.state.currentSchool?.code, label: 'Code', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'sosNumber', value: this.state.currentSchool?.sosNumber, label: 'SOS Phone Number', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'pricePerCode', value: this.state.currentSchool?.pricePerCode, label: 'Price Per Code', isMandatory: true, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'credits', label: 'Credits', isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'select', name: 'association', label: 'Association', options: ['one', 'two', 'three'], isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'select', name: 'cluster', label: 'Cluster', options: ['one', 'two', 'three'], isMandatory: false, gridMd: 6, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'text', name: 'profile', label: 'Profile', isMandatory: false, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
            { type: 'file', name: 'drivingSchoolUnionImg', label: 'Union Image', isMandatory: false, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
        ]


        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        const isLoggedIn = localStorage.getItem('claims');
        const isSuperAdmin = claims.drivingSchoolId === "*" ? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER" ? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION" ? true : false
        const isSkl = claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#" ? true : false;


        return (
            <>
                <>
                    <TopBar title="Driving Schools" />

                    <div className='contentContainer'>
                        <div className='contentTop'>
                            <h5>{this.state.isToggle ? 'Driving School List' : 'Add Driving School'}</h5>

                            {this.state.claims?.drivingSchoolId === '*' ? <div className='tableBtn' style={{ marginLeft: "auto" }}>
                                {/* <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>{this.state.isToggle ? 'ADD DRIVING SCHOOL' : 'CANCEL'}</button> */}
                                <button className='btn btn-primary' onClick={(e) => window.location.href = 'drivingschools/add'}>ADD DRIVING SCHOOL</button>

                            </div> : ""}
                        </div>
                        {/* <div className={`${this.state.isToggle ? 'table show' : 'table hide'}`}>
                            <TableContainer columns={columns} data={this.state.schools} />
                        </div> */}
                        <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
                            {/* <FormComponent fields={fields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Submit' /> */}
                            {/* <DrivingSchoolAdd/> */}
                            <FormComponent fields={this.state.mode === 'edit' ? editFields : addFields} toggleForm={(e: any) => this.toggleForm(e)} handleValues={this.handleValues} buttonText='Submit' />
                            {/* <DrivingAdd/> */}
                        </div>
                    </div>
                </>


            </>
        );
    }
}

export default DrivingSchoolAdd;
import React from 'react';
import { Button, Divider, Form, Input, message, Skeleton } from 'antd';
import { callOnServer, RRServer } from '../../../utils/lib';
// import AssociationAddForm from './Association-Add';
// import { AssociationsInterface } from '../../../types';
import FormComponent from '../../FormComponent/FormComponent';
import TopBar from '../../TopBar';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
    pricePerCode: number,
    name: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    corporateCode: null | CorporateCodeInterface
    mode: 'edit' | 'add',
    isToggle: boolean
}

class SalesAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            corporateCode: null,
            mode: window.location.href.includes("edit") ? 'edit' : 'add',
            isToggle: false
        }
        this.onFinish = this.onFinish.bind(this);
    }

    componentDidMount() {
        var last = window.location.href.substring(window.location.href.lastIndexOf("/") + 1, window.location.href.length);

        // if (this.state.mode === 'edit') {
        //     Promise.all([
        //         RRServer.get({ table: 'associations', id: last }),
        //     ]).then((results: any) => {
        //         this.setState({
        //             currentSchool: results[0].data.data,
        //         })
        //     })
        // }
    }


    addAssociation = (association: any) => {
        const { mode, currentSchool, corporateCode } = this.state;


        if (mode === 'edit') {
            let _school = association;
            association.id = currentSchool!.id;
            Promise.all([
                RRServer.update({ ...association, table: 'sales' }),
            ])
                .then((results: any) => {
                    // console.log(results);
                    if (results[0].data.s && results[1].data.s) {
                        message.success('Successfully Updated Association');
                        // setTimeout(() => window.location.href = '/associations', 200)
                    }
                })

        } else {
            const params = {
                ...association,
            };

            console.log(params)

            RRServer.add({ table: 'sales', data: params }).then((result: any) => {

                message.success('Successfully Added sales user');
                setTimeout(() => window.location.href = '/sales', 200)
                alert('Successfully Added sales user');
            })
        }
    }

    onFinish(values: any) {
        console.log('association', values)
        this.addAssociation(values);
        // this.setState({ isToggle: !this.state.isToggle });


    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {

    }


    toggleForm = (e: any) => {
        e.preventDefault();
        // this.onFinish()
        // console.log(this.state.isToggle);
    };

    render() {

        const addFields: any = [
            { type: 'text', name: 'name', label: 'Sales Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
        ]

        const editFields: any = [
            { type: 'text', name: 'name', value: this.state.currentSchool?.name, label: 'Sales Name', isMandatory: true, gridMd: 12, gridSm: 12, validation: (value: any) => !value ? false : true },
        ]

        return (
            <>
                <TopBar title="Sales" />

                <div className="contentContainer">
                    <div className="contentTop">
                        <h5>
                            {this.state.isToggle
                                ? "Sales List"
                                : "Add Sales List"}
                        </h5>

                        <div className="tableBtn" style={{ marginLeft: "auto" }}>
                            {/* <button
                    className="btn btn-primary"
                    onClick={(e) => this.toggleForm(e)}
                  >
                    {this.state.isToggle ? "Add Association" : "CANCEL"}
                  </button> */}
                            {/* <button
                                className="btn btn-primary"
                                onClick={(e) => window.location.href = 'association/add'}
                            >
                                {this.state.isToggle ? "Add Association" : "CANCEL"}
                            </button> */}
                        </div>
                    </div>
                    <div
                        className={`${this.state.isToggle ? "table show" : "table hide"}`}
                    >

                        {/* <TableContainer columns={columns} data={this.state.associations} /> */}
                    </div>
                    <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
                        {/* <AssociationAdd/> */}
                        <FormComponent
                            fields={this.state.mode === 'edit' ? editFields : addFields}
                            toggleForm={(e: any) => this.toggleForm(e)}
                            handleValues={this.onFinish}
                            buttonText="Submit"
                        />
                    </div>
                </div>
            </>
        )
    }


}

export default SalesAdd;
import React from 'react';
import { PageHeader } from 'antd';
import { Button, Divider, Form, Input, message, Select, Skeleton } from 'antd';
// import { Link, Route, withRouter, Switch } from 'react-router-dom';
// import MaskedInput from 'antd-mask-input'
import { callOnServer, RRServer } from '../../../utils/lib';
import { InstallmentInterface, StudentInterface, UserInterface } from '../../../types';
import { promises } from 'fs';
import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
// import DrivingSchools from '../DrivingSchools/DrivingSchools';
import TextArea from 'antd/lib/input/TextArea';
import { OrderedListOutlined, UserAddOutlined } from '@ant-design/icons';
// import EngagementAddCorporate from './EngagementAddCorporate';

// import EngagementList from './EngagementList';


interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    sosNumber: string
}
interface CorporateCodeInterface {
    password: string
    id: string
}

interface DrivingSchoolAddInterface {
    currentSchool: null | DrivingSchoolInterface,
    currentInstallment: null | InstallmentInterface,
    currentUser: null | UserInterface,
    schools: DrivingSchoolInterface[]
    recepients: StudentInterface[],
    recepientsSelected: any[],
    all: boolean
}

const { Option } = Select;

class EngagementAdd extends React.Component<any, DrivingSchoolAddInterface> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentSchool: null,
            currentInstallment: null,
            currentUser: null,
            schools: [],
            recepients: [],
            recepientsSelected: [],
            all: false
        }
        this.onFinish = this.onFinish.bind(this);
        // this.editInstallment = this.editInstallment.bind(this);
    }

    componentDidMount() {
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        const promises = [];
        if (claims.drivingSchoolId !== '*') {
            promises.push(RRServer.get({ table: RRServer.Tables.DRIVING_SCHOOLS, id: claims.drivingSchoolId }))
            promises.push(RRServer.list({ table: RRServer.Tables.ENGAGEMENTS, forCurrentUser: false, where: [['drivingSchoolId', '==', claims.drivingSchoolId]] }))
        } else {
            promises.push(RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }))
            promises.push(RRServer.list({ table: RRServer.Tables.ENGAGEMENTS, forCurrentUser: false }))
        }

        Promise.all(promises)
            .then((results: any) => {
                // console.log(results);
                const schools = results[0].data.data;


                this.setState({ schools: schools.length ? schools : [schools] })
            })
    }

    onFinish(values: any) {
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''
        values.recepients = values.recepients[0] == 'all' ? this.state.recepients.map(r => r.id) : values.recepients;
        values.body=values.content;
        values.title='RoadRakshak';
        values.condition=[
            ['companyId','==',values.drivingSchoolId]
        ];
        // console.log('valuess',values)
        RRServer.callFn('notification1-push', values)
    };

    onFinishFailed(errorInfo: any) {
        console.log('Failed:', errorInfo);
    };

    onSearch() {
    }

    getStudents = (drivingSchoolId: string) => {

        RRServer.list({table:RRServer.Tables.USERS,forCurrentUser:false,where:[['companyId','==',drivingSchoolId],['type','==','DRIVING_SCHOOL_USER']]})
        .then((r:any)=>this.setState({recepients:r.data.data}))

    }




    render() {
        // let { path, url } = this.props.match;
        const { currentSchool, currentInstallment, currentUser, schools, recepients, recepientsSelected } = this.state

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        // console.log(recepientsSelected, recepients)

        return (
            <main style={{margin:"auto"}}>
                <Divider />
                <div style={{ width: '400px' }}>

                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{
                            ...currentSchool,
                            ...currentUser,
                            recepients: recepientsSelected,
                            type: 'PUSH_NOTIFICATION'

                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        onValuesChange={(changedValues, allValues) => {
                            changedValues.drivingSchoolId && this.getStudents(changedValues.drivingSchoolId)
                            if (changedValues.recepients) {
                                this.setState({ all: changedValues.recepients[0] == 'all' })
                            }
                        }}
                    >
                        <Form.Item
                            label="School"
                            name="drivingSchoolId"
                            rules={[{ required: true, message: 'School Name' }]}

                        >
                            <Select placeholder="Please select a School" defaultValue={schools.length == 1 ? schools[0].id : 'Please select a School'}>
                                {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Recepients"
                            name="recepients"
                            rules={[{ required: true, message: 'Select Recepients' }]}
                        >
                            <Select mode="multiple" placeholder="Please select a Recepient"   >
                                <Option key='all' value='all'>Select All</Option>
                                {recepients.map(recepient => <Option key={recepient.id} value={recepient.id} disabled={this.state.all }>{recepient.name}</Option>)}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: true, message: 'Type' }]}
                        >
                            <Select placeholder="Please select a Type" >
                                <Option value={'PUSH_NOTIFICATION'}>Push Notification</Option>)
                                {/*<Option value={'SMS'}>SMS</Option>)                                
                                <Option value={'WHATSAPP'}>WhatsApp</Option>)
                                <Option value={'EMAIL'}>Email</Option>)*/}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Content"
                            name="content"
                            rules={[{ required: true, message: 'Content' }]}
                        >
                            <TextArea></TextArea>
                        </Form.Item>
                        {/* <Select placeholder="Please select a School" defaultValue={schools.length == 1 ? schools[0].id : 'Please select a School'}>
                            {schools.map(school => <Option value={school.id}>{school.drivingSchoolName}</Option>)}
                        </Select> */}

                        {/* <Form.Item
                                label="School"
                                name="drivingSchoolName"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="User"
                                name="name"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} bordered={false} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                label="Installment"
                                name="installment"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Input disabled={true} bordered={false} />
                            </Form.Item>

                            <Form.Item
                                label="Paid"
                                name="status"
                                rules={[{ required: false, message: 'SOS Number' }]}
                            >
                                <Switch
                                    defaultChecked={!!currentInstallment!.status}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item> */}



                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" >
                                Send
                                </Button>
                        </Form.Item>
                    </Form>

                </div>
            </main>
        );
    }
}

export default EngagementAdd;
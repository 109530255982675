import React from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Typography,
  Steps,
  Modal,
  Table,
  Select,
  Card,
  Checkbox,
  DatePicker,
  TimePicker,
  Tag,
} from "antd";
import {
  DrivingSchoolInterface,
  InstructorInterface,
  UserInterface,
  DAYS,
  DayType,
  StudentInterface,
  EMPTY_SLOTS,
  DateTimeType,
  DRIVING_SCHOOL_USER_TYPES,
  InstallmentInterface,
} from "../../../types";
// import Select, { OptionsType } from 'react-select'
// import commonStyles from '../../common/css/common.module.css';
import { formatTime, getClaims, RRServer } from "../../../utils/lib";
// import TableSelect from './sub-componets/TableSelect';
import dayjs from "dayjs";

import firebase from "../../../utils/Firebase";

// import Styles from './DrivingSchoolUserAdd.module.css';
import moment from "moment";
import TableSelect from "./subComponents/TableSelect";
// import Installments from '../Installments/Installments';
import UserForm from "./subComponents/UserForm";
import AddInstructorForm from "./subComponents/AddInstructorForm";
import AddUserForm from "./subComponents/AddUserForm";
import AddInsFormTwo from "./subComponents/AddInsFormTwo";

const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

interface DrivingSchoolUserAddProps {
  schools: DrivingSchoolInterface[];
  selectedSchool: DrivingSchoolInterface | null;
  selectedUserType: DRIVING_SCHOOL_USER_TYPES | null;
  userCode: string;
  mode: "edit" | "add";
  currentStep: number;
  user: any;
  showSelectSchoolModal: boolean;
  showInstructorModal: boolean;
  instructors: InstructorInterface[];
  selectedInstructor: InstructorInterface | null;
  currentUser: UserInterface | null;
  numberOfClasses: number;
  numberOfInstallments: number;
  startDate: string;
  loading: boolean;
  installments: InstallmentLocalInterface[];
  drivingSchoolId: string;
  scheduledClasses: any;
  isScheduleUpdateModal: boolean;
  checkDate: string;
  checkTime: string;
  isSlotThere: boolean;
  clsSchedules: any[];
  updateDate: string;
  updateTime: string;
  isSlotsOkay: any;
  submitLoading: boolean;
  isEdit: boolean

}

interface InstallmentCreateInterface {
  // numberOfInstallments: number
  uid: string;
  companyId: string;
}

export interface InstallmentLocalInterface {
  dueDate: string;
  id?: string;
  uid?: string;
  companyId?: string;
  status: number;
  paidOn: number;
  installment: string;
}

class UserAdd extends React.Component<any, DrivingSchoolUserAddProps> {
  dsDropdown: React.RefObject<unknown>;
  CLASS_SCHEDULES: DateTimeType[] = [];
  unregisterAuthObserver: firebase.Unsubscribe | undefined;

  constructor(props: any) {
    super(props);

    this.state = {
      schools: [],
      selectedSchool: null,
      selectedUserType: null,
      userCode: "",
      mode: "add",
      currentStep: 0,
      user: this.props.student ? this.props.student : null,
      showSelectSchoolModal: false,
      showInstructorModal: false,
      instructors: [],
      selectedInstructor: null,
      currentUser: null,
      numberOfClasses: 0,
      numberOfInstallments: 0,
      installments: [],
      startDate: "",
      loading: false,
      drivingSchoolId: "",
      scheduledClasses: [],
      isScheduleUpdateModal: false,
      checkDate: "",
      checkTime: "",
      isSlotThere: false,
      clsSchedules: [],
      updateDate: '',
      updateTime: '',
      isSlotsOkay: null,
      submitLoading: false,
      isEdit: false,
    };
    this.getSchool = this.getSchool.bind(this);
    this.onDrivingSchoolChange = this.onDrivingSchoolChange.bind(this);
    this.onFinish = this.onFinish.bind(this);
    this.dsDropdown = React.createRef();
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(() => {
      getClaims().then((claims) => {
        if (!claims || !!!claims["drivingSchoolId"]) {
          console.log("No Claim");
          return null;
        }
        const drivingSchoolId = claims ? claims["drivingSchoolId"] : "";

        if (this.state.mode === "edit") {
          let reqs = [
            RRServer.get({ table: "users", id: this.props.match.params.id }),
          ];
          if (drivingSchoolId === "*") {
            reqs.push(
              RRServer.list({ table: "driving_schools", forCurrentUser: false })
            );
          } else {
            reqs.push(
              RRServer.get({ table: "driving_schools", id: drivingSchoolId })
            );
          }
          Promise.all(reqs).then((results: any) => {
            const currentUser = results[0].data.data;
            const currentSchool = results[1].data.data.length
              ? results[1].data.data.find(
                (school: DrivingSchoolInterface) =>
                  school.id === currentUser.companyId
              )
              : [results[1].data.data].find(
                (school: DrivingSchoolInterface) =>
                  school.id === currentUser.companyId
              );
            // debugger;
            if (currentUser.type === "DRIVING_SCHOOL_INSTRUCTOR" || currentUser.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
              currentUser.slots = currentUser.slots || EMPTY_SLOTS;
            }

            // console.log('AAA', currentUser)
            this.setState({
              currentUser,
              user: currentUser, //@todo Dont know why we do this, we might as well use currentUser
              schools: [results[1].data.data],
              selectedSchool: currentSchool,
              startDate: currentUser.startDate,
              numberOfClasses: currentUser.numberOfClasses,
              selectedUserType: currentUser.type,
              drivingSchoolId,
            });
            this.getInstructors(
              currentUser.companyId,
              currentUser.instructorId
            );
          });
        } else {
          const promise =
            drivingSchoolId === "*"
              ? RRServer.list({
                table: "driving_schools",
                forCurrentUser: false,
              })
              : RRServer.get({ table: "driving_schools", id: drivingSchoolId });
          promise.then((result: any) => {
            let schools = result.data.data.length
              ? result.data.data
              : [result.data.data];
            this.setState({
              schools,
              //reset
              currentUser: null,
              user: null, //@todo Dont know why we do this, we might as well use currentUser
              selectedSchool: null,
              startDate: "",
              numberOfClasses: 0,
              selectedUserType: null,
            });
          });
        }
      });
    });
    // this.setState({currentStep: this.props.isEdit? 1 : 0})
    RRServer.list({ table: "class_schedules", forCurrentUser: false }).then(
      (result: any) => {
        console.log("dataaaa listt", result);

        let scheduledClasses = result.data.data;
        this.setState({ scheduledClasses });
      }
    );
    if (this.props.isEdit) {
      this.setState({ isEdit: true })
    } else {
      this.setState({ isEdit: false })
    }
  }

  componentWillUnmount() {
    this.unregisterAuthObserver!();
  }

  getSchool(schoolId: string) {
    const school = this.state.schools.filter(
      (school) => (school.id = schoolId)
    );
    return school[0];
  }

  getInstructors(companyId: string, instructorId?: string) {

    return RRServer.list({
      table: "users",
      where: [
        ["companyId", "==", companyId],
        ["type", "==", "DRIVING_SCHOOL_INSTRUCTOR"],
      ],
      forCurrentUser: false,
    }).then((result: any) => {
      let _d: any = {
        instructors: result.data.data,
      };
      if (instructorId) {
        _d.selectedInstructor = result.data.data.find(
          (ins: InstructorInterface) => ins.id === instructorId
        );
      }
      this.setState(_d);
    });
  }

  saveInstallments = (params: InstallmentCreateInterface) => {
    const { uid, companyId } = params;
    let insObjs = this.state.installments.map((ins) => {
      return {
        ...ins,
        uid,
        companyId,
        // dueDate: moment(ins.dueDate).format("DD-MM-YYYY"),
      };
    });
    // for (let i = 0; i < params.numberOfInstallments; i++) {
    //     insObjs.push({
    //         installment: "Installment " + (i + 1),
    //         status: 0,
    //         paidOn: -1,
    //         uid,
    //         companyId
    //     })
    // }

    return RRServer.addMultiple({
      table: RRServer.Tables.INSTALLMENTS,
      data: insObjs,
    }).then((r) => {
      console.log(r);
    });
  };

  simulateSaveInstallments = () => {
    this.saveInstallments({
      // numberOfInstallments: 4,
      uid: "axATfTonn9kiWXFNHcns",
      companyId: "3a9jkpr2LbU90jIYb9a5",
    });
  };

  addUserToSchool(
    user: UserInterface & {
      sendsms: boolean;
      typeOfVehicle: string;
      dob: string;
    }
  ) {
    const {
      name,
      mobile,
      companyId,
      code,
      password,
      type,
      sendsms,
      typeOfVehicle,
      dob,
    } = user;
    this.setState({ loading: true });

    const { drivingSchoolId } = this.state;

    const link =
      "https://play.google.com/store/apps/details?id=io.pyps.roadrakshak";

    if (this.props.isEdit) {
      console.log('successfulyl updated', user)
      this.setState({
        user: { ...user },
        currentStep: 1,
        loading: false,
      })

      const { numberOfInstallments } = this.state;

      // this.saveInstallments({ companyId: user.companyId, uid: user.id });

      this.getInstructors(user.companyId);

    } else {
      RRServer.callFn("users1-add", {
        name: name,
        mobile: mobile,
        companyId: companyId,
        userCode: code,
        password,
        type,
        sendsms,
        typeOfVehicle,
        dob,
        BoardedDate: new Date().toLocaleString().split(",")[0]
      })
        .then((result: any) => {
          if (result.data.s) {
            // alert('Successfully Added User Driving School');
            message.success("Successfully Added User to Driving School");
            if (type === "DRIVING_SCHOOL_ADMIN") {
              window.location.reload()
            } else {
              this.setState({
                user: { ...user, id: result.data.id },
                currentStep: 1,
                loading: false,
              });
            }


            const { numberOfInstallments } = this.state;

            this.saveInstallments({ companyId, uid: result.data.id });

            this.getInstructors(user.companyId);

            if (sendsms || drivingSchoolId !== "*") {
              RRServer.callFn("notification1-sms", {
                tmpl: "3hyrqXRel60y1sU8G4sP",
                phone: `91${mobile}`,
                params: {
                  flow_id: '625a60372cbdeb130766d494',
                  sender: 'REDCHA',
                  name: name,
                  password: password,
                  code: code,
                  link: 'https://play.google.com/store/apps/details?id=io.pyps.roadrakshakds'
                },
              });
            }
          }
        })
        .catch(function (error) {
          alert(error);
        });
    }


  }

  onDrivingSchoolChange(value: string) {
    const school = this.getSchool(value);
    let ctr = school.currentCount;
    let newCtr = this.incrementCounter(ctr);
    let userCode = school.code.substring(0, 6) + newCtr;
    this.setState({
      selectedSchool: school,
      userCode,
    });
  }

  getCodeBasedOnType = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {
    const { selectedSchool } = this.state;
    if (selectedUserType && selectedSchool) {
      let ctr;
      if (selectedUserType === "DRIVING_SCHOOL_USER") {
        ctr = selectedSchool!.currentCount;
      } else if (selectedUserType === "DRIVING_SCHOOL_ADMIN") {
        ctr = selectedSchool!.currentCountAdmin;
      } else if (selectedUserType === "DRIVING_SCHOOL_INSTRUCTOR") {
        ctr = selectedSchool!.instructorCurrentCount;
      } else if (selectedUserType === "DRIVING_SCHOOL_OFFICE_ADMIN") {
        ctr = selectedSchool!.officeAdminCurrentCount;
      } else if (selectedUserType === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
        ctr = selectedSchool!.adminAndInstructorCurrentCount;
      }
      return ctr;
    } else {
      return "";
    }
  };

  getCodeForSchool = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {
    const { selectedSchool } = this.state;
    if (selectedUserType && selectedSchool) {
      let ctr,
        newCtr,
        userCode = "";

      if (selectedUserType === "DRIVING_SCHOOL_ADMIN") {
        let t = selectedSchool!.currentCountAdmin.split("");
        let num = +t.slice(1).join("");
        if (num > 10) {
          return false;
        }
      } else if (selectedUserType === "DRIVING_SCHOOL_INSTRUCTOR") {
        let t = selectedSchool!.currentCountAdmin.split("");
        let num = +t.slice(1).join("");
        if (num > 99) {
          return false;
        }
      } else if (selectedUserType === "DRIVING_SCHOOL_OFFICE_ADMIN") {
        let t = selectedSchool!.currentCountAdmin.split("");
        let num = +t.slice(1).join("");
        if (num > 20) {
          return false;
        }
      } else if (selectedUserType === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
        let t = selectedSchool!.adminAndInstructorCurrentCount.split("");
        let num = +t.slice(1).join("");
        if (num > 20) {
          return false;
        }
      }

      ctr = this.getCodeBasedOnType(selectedUserType);
      newCtr = this.incrementCounter(ctr);
      userCode = selectedSchool!.code.substring(0, 6) + newCtr;
      return userCode;
    } else {
      return "";
    }
  };

  onSelectSchool = (school: DrivingSchoolInterface) => {
    let ctr = school.currentCount;
    this.setState({
      selectedSchool: school,
      // userCode
    });
  };
  onSelectInstructor = (instructor: InstructorInterface) => {
    this.setState({
      selectedInstructor: instructor,
    });
  };

  onFinish(values: any) {
    values.code = this.state.userCode;
    values.companyId = this.state.selectedSchool?.id;
    console.log("Finish", values);
    this.addUserToSchool(values);
  }

  onFinishFailed(errorInfo: any) {
    console.log("Failed:", errorInfo);
  }

  onSearch() { }

  onFormValueChange = (form: { [key: string]: any }) => {
    let key = Object.keys(form)[0];
    if (key === "companyId") {
      this.onDrivingSchoolChange(form[key]);
    } else if (key === "type") {
      const code = this.getCodeForSchool(form[key]);
      if (code === false) {
        alert("Max Limit of users reached");
        return;
      }
      this.setState({
        selectedUserType: form[key],
        userCode: code,
      });
    }
  };

  incrementCounter(s: string) {
    let ss = s.split("");
    let alpha = ss.shift();
    let num = +ss.join("");
    num += 1;
    if (num > 99) {
      alpha = String.fromCharCode(alpha!.charCodeAt(0) + 1);
      num = 0;
    }
    const numStr = num < 10 ? "0" + num : num;
    return alpha + "" + numStr;
  }

  nextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };

  simulateSave = () => {
    // {"key":"buwdAf26G5MduywjTBnH","currentCount":"789","drivingSchoolName":"Q Edited","modifiedOn":1602353833445,"currentCountAdmin":"A02","profile":"We are the best dirving school in the city.","code":"123456A00","modifiedBy":"MRxrOnhO7YgA4HgKWZtSwCCdQJf1","id":"buwdAf26G5MduywjTBnH"}
    const user: UserInterface = {
      type: "DRIVING_SCHOOL_USER",
      name: "111",
      mobile: "222",
      password: "222",
      code: "134567794",
      companyId: "buwdAf26G5MduywjTBnH",
      id: "szlyFWXY57EcqFbDUbfy",
      instructorId: "",
    };
    this.setState({
      user: user,
      currentStep: 1,
    });
    this.getInstructors(user.companyId);
  };

  showSelectSchoolModalToggle = () => {
    this.setState({
      showSelectSchoolModal: !this.state.showSelectSchoolModal,
    });
  };
  showInstructorModalToggle = () => {
    this.setState({
      showInstructorModal: !this.state.showInstructorModal,
    });
  };

  selectSlot = (day: DayType, slot: string) => {
    let user = this.state.user!;
    user.slots = user.slots || EMPTY_SLOTS;
    const f = user.slots[day].some((c: any) => c === slot);
    if (f) {
      user.slots[day] = user.slots[day].filter((c: any) => c !== slot);
    } else {
      user.slots[day].push(slot);
    }
    this.setState({ user });
  };

  saveSlotsForUser = () => {
    const {
      user,
      selectedInstructor,
      numberOfClasses,
      startDate,
      selectedSchool,
    } = this.state;
    // if (!selectedSchool) {
    //     alert('Selected School Not Found')
    //     return;
    // }
    const slots = user?.slots;
    let successMessage = "Successfully Assigned Instructor to User";
    let objToUpdate = {};
    if (user?.type === "DRIVING_SCHOOL_USER") {
      objToUpdate = {
        slots,
        id: user?.id,
        instructorId: selectedInstructor?.id,
        classSchedules: this.state.clsSchedules,
        numberOfClasses,
        startDate,
      };
    } else if (user?.type === "DRIVING_SCHOOL_INSTRUCTOR" || user?.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
      objToUpdate = {
        slots,
      };
      successMessage = "Successfully Saved Instructor Schedule";
    }

    let promises = [
      RRServer.update({
        table: RRServer.Tables.USERS,
        id: user?.id,
        ...objToUpdate,
      }),
    ];

    //Update the instructor with the students schedules
    if (user?.type === "DRIVING_SCHOOL_USER") {
      let sch = selectedInstructor?.classSchedules || {};
      sch[user!.id] = this.state.clsSchedules;
      console.log(sch, "shhh");

      promises.push(
        RRServer.update({
          table: RRServer.Tables.USERS,
          id: selectedInstructor!.id,
          classSchedules: sch,
        })
      );
      promises.push(
        RRServer.remove({
          table: RRServer.Tables.CLASS_SCHEDULES,
          where: [
            ["studentId", "==", user!.id],
            ["instructorId", "==", selectedInstructor!.id],
          ],
        })
      );
    }

    this.setState({ loading: true });
    Promise.all(promises).then(() => {
      message.success(successMessage);
      this.setState({ loading: false });
      //Add to class schedules table

      if (user?.type === "DRIVING_SCHOOL_USER") {
        // console.log('add multiple', user)

        Promise.all([
          RRServer.list({ table: 'users', forCurrentUser: false, where: [['code', '==', user?.code]] })
        ])
          .then((res: any) => {
            const currentUser = res[0]?.data.data[0]
            RRServer.addMultiple({
              table: RRServer.Tables.CLASS_SCHEDULES,
              data: currentUser.hasOwnProperty('fcmToken') ? this.state.clsSchedules.map((sch) => ({
                studentId: user?.id,
                student: user?.name,
                fcmToken: currentUser?.fcmToken,
                instructorId: selectedInstructor!.id,
                instructor: selectedInstructor!.name,
                companyId: selectedSchool!.id,
                school: selectedSchool!.drivingSchoolName,
                date: sch.date,
                time: sch.time,
              })) : this.state.clsSchedules.map((sch) => ({
                studentId: user?.id,
                student: user?.name,
                instructorId: selectedInstructor!.id,
                instructor: selectedInstructor!.name,
                companyId: selectedSchool!.id,
                school: selectedSchool!.drivingSchoolName,
                date: sch.date,
                time: sch.time,
              })),
            }).then(() => {
              this.saveInstallments({
                uid: user.id,
                companyId: selectedSchool!.id,
              }).then(() =>
                window.location.reload()
                // console.log('addedd clasSchedules')
              )
                .catch((err) => {
                  console.log(err)
                  alert("somthing went wrong")
                })
              console.log("submitt");
            });


          })
          .catch(err => {
            console.log(err)
            alert("something went wrong")
          })


      } else {
        console.log("submitted");
        window.location.reload()
      }
    });
  };

  skipForm = () => {
    this.nextStep();
  };

  changeNumberOfClasses = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ numberOfClasses: Number(e.currentTarget.value) });
  };

  changeNumberOfInstallments = (e: React.FormEvent<HTMLInputElement>) => {
    let installments = [];
    for (let i = 0; i < Number(e.currentTarget.value); i++) {
      installments.push({
        installment: "Installment " + (i + 1),
        status: 0,
        paidOn: -1,
        dueDate: dayjs().add(i, "day").toISOString(), //.format('DD-MM-YYYY')
      });
    }

    this.setState({
      numberOfInstallments: Number(e.currentTarget.value),
      installments,
    });
  };
  setDueDate = (insstallment: InstallmentLocalInterface, date: string) => {
    const installments = this.state.installments.map((ins) =>
      ins.installment == insstallment.installment
        ? { ...ins, dueDate: date }
        : ins
    );
    this.setState({ installments });
  };
  changeStartDate = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ startDate: e.currentTarget.value });
  };

  makeSuperUser = () => {
    const id = (document.getElementById("user-id") as HTMLInputElement)!.value;
    RRServer.callFn("setDSID", {
      uid: id,
      DSid: "*",
      admin: true,
    })
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleValues = (val: any) => {
    console.log("Finish", val);
    this.addUserToSchool(val);
  };

  handleInstructorSlots = async (val: any) => {
    // console.log("ins slots", val);
    await this.setState({ user: { ...this.state.user, slots: { ...val } } });
    this.saveSlotsForUser();
  };
  handleUserSlots = async (val: any) => {
    console.log("valll", val);

    console.log("userr details", {
      user: { ...this.state.user, slots: { ...val.slots } },
      numberOfClasses: val.noOfClasses,
      startDate: val.startDate,
      selectedInstructor: this.state.instructors.filter(
        (ins) => ins.id === val.instructorId
      )[0],
    });

    const instructor = this.state.instructors.filter(
      (ins) => ins.id === val.instructorId
    )[0];
    const school = this.state.schools.filter(
      (skl) => skl.id === this.state.user.companyId
    )[0];
    console.log("inss", instructor, "skl", school);

    await this.setState({
      user: { ...this.state.user, slots: { ...val.slots } },
      numberOfClasses: val.noOfClasses,
      startDate: val.startDate,
      installments: val.installmentDates,
      selectedInstructor: instructor,
      selectedSchool: school,
      currentStep: 2,
      scheduledClasses: this.state.scheduledClasses.filter((cls: any) => {
        return cls.instructorId === instructor.id;
      }),
    });

    this.setState({ clsSchedules: [...this.CLASS_SCHEDULES] })


    // this.saveSlotsForUser();
  };

  convertTime12to24 = (time12h: any) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
  };

  checkSlotAvailability = (date: any, time: any) => {
    const { selectedInstructor } = this.state
    const day = moment(date, 'DD/MM/YYYY').format("dddd").slice(0, 3)
    console.log(day, 'ddd', time, 'timee')
    const isInstructorAvailable = selectedInstructor?.slots
    const classArr = this.state.scheduledClasses.filter(
      (clss: any) => {
        return (
          clss.date === date &&
          clss.time === this.convertTime12to24(time) &&
          clss.instructorId === selectedInstructor?.id
        );
      }

    );

    if (selectedInstructor?.slots[day].some((timee: any) => timee.startTime === time)) {
      if (classArr.length >= 4) {
        this.setState({ isSlotThere: false })

        return false
      } else if (classArr.length === 0) {
        this.setState({ isSlotThere: false })
        return false
      } {
        this.setState({ isSlotThere: true })
        return true
      }
    } else {
      this.setState({ isSlotThere: false })
      return false
    }
  }

  updateClassSchedules = (date: string, time: string) => {
    const arr = this.state.clsSchedules.map((element, index) => {
      return element.date === date && element.time === time ? { date: this.state.checkDate, time: this.convertTime12to24(this.state.checkTime) } : element
    });
    // arr.push({date: this.state.checkDate,time: this.state.checkTime})
    const isSlotsOkay = arr.every((cls) => {
      const classArr = this.state.scheduledClasses.filter(
        (clss: any) => {
          return (
            clss.date === cls.date &&
            clss.time === cls.time &&
            clss.instructorId === this.state.selectedInstructor?.id
          );
        }

      );
      if (classArr.length === 0) return false
      else if (classArr.length !== 0) return true
    })


    this.setState({ clsSchedules: [...arr], isSlotsOkay, isScheduleUpdateModal: false })
  }

  handleFormPreviewSubmit = () => {
    this.setState({ submitLoading: true })
    this.saveSlotsForUser()
  }

  render() {
    const {
      drivingSchoolId,
      installments,
      schools,
      selectedSchool,
      selectedUserType,
      instructors,
      selectedInstructor,
      userCode,
      currentStep,
      user,
      currentUser,
      mode,
      startDate,
      numberOfClasses,
      loading,
    } = this.state;


    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    if (user) {
      user.slots = user.slots || EMPTY_SLOTS;
    }

    const columnsSchools = [
      {
        title: "Name",
        dataIndex: "drivingSchoolName",
        render: (text: React.ReactNode) => <span>{text}</span>,
      },
    ];
    const dataSchools = schools.map((school) => ({
      key: school.id,
      ...school,
    }));
    const columnsInstructors = [
      {
        title: "Name",
        dataIndex: "name",
        render: (text: React.ReactNode) => <span>{text}</span>,
      },
    ];
    const dataInstructors =
      instructors &&
      instructors.map((school) => ({
        key: school.id,
        ...school,
      }));

    let slots = EMPTY_SLOTS;
    let len = Math.max(...DAYS.map((day: DayType) => slots[day].length));
    let trs = [];
    if (user?.type == "DRIVING_SCHOOL_USER" && selectedInstructor) {
      slots = selectedInstructor.slots || EMPTY_SLOTS;
      len = selectedInstructor
        ? Math.max(...DAYS.map((day: DayType) => slots[day].length))
        : 0;
      for (let i = 0; i < len; i++) {
        trs.push(
          DAYS.map((day: DayType) => {
            const _slots = slots[day];
            let cell = _slots[i] || "";
            return (
              <td
                key={day + "-" + i}
                className={
                  user!.slots
                    ? user!.slots[day].some((c: any) => c === cell)
                      ? "selected"
                      : ""
                    : ""
                }
                onClick={() => this.selectSlot(day, cell)}
              >
                {cell}
              </td>
            );
          })
        );
      }
    }

    /**
     * @todo Move to another file
     */
    { console.log("props from middle", this.props) }
    const fform = (props: any) => (
      this.props.isEdit ? <UserForm handleValues={this.handleValues} student={this.props.student} isEdit={this.props.isEdit} schools={this.state.schools} />
        : <UserForm handleValues={this.handleValues} schools={this.state.schools} />

    );

    const lis = [];
    if (startDate && selectedInstructor && user && !this.state.isScheduleUpdateModal) {
      let _startDate = dayjs(startDate, "MM-DD-YYYY");
      const instructorSlots = selectedInstructor.slots || EMPTY_SLOTS;
      user.slots = user.slots || EMPTY_SLOTS;

      // console.log('Start Day', DAYS[_startDate.day()])
      // lis.push(<li><Divider /></li>)
      _startDate = dayjs(startDate, "MM-DD-YYYY");
      let classes = 0,
        iterations = 100;
      this.CLASS_SCHEDULES = [];
      do {
        iterations--;

        const day = DAYS[_startDate.day()];
        const instructorTimes = instructorSlots[day];
        const userTimes = user.slots[day];
        const doesUserWantClassOnDay = !!!userTimes?.length;

        if (!instructorTimes.length || doesUserWantClassOnDay) {
          // console.log(iterations)
          _startDate = _startDate.add(1, "d");
          continue;
        } else {
          classes += user.slots[day].length;
        }
        for (let j = 0; j < userTimes.length; j++) {
          this.CLASS_SCHEDULES.push({
            date: _startDate.format("DD/MM/YYYY"),
            time: userTimes[j],
          });
          lis.push(
            <li key={"_" + j + classes}>
              {_startDate.format("DD/MM/YYYY")} {userTimes[j]}
            </li>
          );
        }

        _startDate = _startDate.add(1, "d");
      } while (classes < numberOfClasses && iterations > 0);
    }

    const step2Title = selectedUserType
      ? selectedUserType === "DRIVING_SCHOOL_USER"
        ? "Assign Instructor"
        : selectedUserType === "DRIVING_SCHOOL_INSTRUCTOR" || selectedUserType === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR"
          ? "Instructor Schedule"
          : selectedUserType === "DRIVING_SCHOOL_ADMIN"
            ? "Admin Step 2"
            : ""
      : "";
    const START = 6;
    const SLOTS = 18;

    const getTimes = () => {

      let d = new Date(),
        times = [];
      for (let i = START; i < SLOTS; i++) {
        d.setHours(i);
        d.setMinutes(0);
        times.push(formatTime(d.toISOString()));
        d.setMinutes(30);
        times.push(formatTime(d.toISOString()));
      }
      return times;
    };

    let times = getTimes();

    const convertTime12to24 = (time12h: any) => {
      const [time, modifier] = time12h.split(" ");

      let [hours, minutes] = time.split(":");

      if (hours === "12") {
        hours = "00";
      }

      if (modifier === "PM" || modifier === "pm") {
        hours = parseInt(hours, 10) + 12;
      }

      return hours.length <= 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`;
    };

    const tConv24 = (time24: any) => {
      var ts = time24;
      var H = +ts.substr(0, 2);
      var h: any = H % 12 || 12;
      h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
    };

    return (
      <main>
        <Divider />
        {/* <button onClick={this.simulateSaveInstallments}>Save Installments</button> */}
        {console.log('class schedules', this.CLASS_SCHEDULES)}

        <Title>{this.props.isEdit ? "Edit " : "Add "} Driving School User </Title>

        <Steps current={currentStep}>
          <Step key={"Create User"} title={"Create User"} />
          <Step key={step2Title} title={step2Title} />
          {this.state.user?.type == "DRIVING_SCHOOL_USER" && (
            <Step
              key={"check slot availability"}
              title={"check slot availability"}
            />
          )}
        </Steps>

        <div>
          <Divider />
          {/* 
                    {claims.drivingSchoolId == "*" ?
                        <Card>
                            <input placeholder='user' id='user-id' /> <button onClick={this.makeSuperUser}>Add</button>
                        </Card>
                        : ""} */}
          {currentStep === 0 && currentUser && fform({ user: currentUser })}
          {currentStep === 0 && !currentUser && fform({ user: null })}

          {/* Move to Another File and Import */}
          {currentStep === 1 && user!.type == "DRIVING_SCHOOL_USER" && (
            <AddUserForm
              instructors={this.state.instructors}
              handleSlots={this.handleUserSlots}
            />
          )}

          {currentStep === 2 && user!.type == "DRIVING_SCHOOL_USER" && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {console.log("hellooo cl", this.CLASS_SCHEDULES)}

                {this.state.clsSchedules.map((cls: any, idx: any) => {
                  const classArr = this.state.scheduledClasses.filter(
                    (clss: any) => {
                      return (
                        clss.date === cls.date &&
                        clss.time === cls.time &&
                        clss.instructorId === selectedInstructor?.id
                      );
                    }
                  );

                  if (false) {
                    return (
                      <div key={idx} className="schedulePreview warning">
                        <p className="date">{cls.date}</p>
                        <p className="time">{tConv24(cls.time)}</p>
                        <svg
                          type="button" data-bs-toggle="modal" data-bs-target="#scheduleClassUpdate"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffffff"
                          className="bi bi-pen-fill"
                          viewBox="0 0 16 16"
                          onClick={() => this.setState({ updateDate: cls.date, updateTime: cls.time })}
                        >
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z" />
                        </svg>

                        <div className="availableSlots">
                          {/* {AvailableSlots.map((avl:any, idx:any)=>{
                            return <button
                            className="timingSlots"
                            onClick={(e: any) => console.log(e, cls.time)}
                          >
                            {avl.time}{" "}
                          </button>
                          })} */}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={idx} className="schedulePreview">
                        <p className="date">{cls.date}</p>
                        <p className="time">{tConv24(cls.time)}</p>
                      </div>
                    );
                  }
                })}
              </div>
              {/* <Button type="primary" onClick={()=>this.saveSlotsForUser()} >Submit</Button> */}
              <Button type="primary" loading={this.state.submitLoading} onClick={() => this.handleFormPreviewSubmit()}> Submit </Button>
            </>
          )}

          {/* Move to Anothe rFile and Import */}
          {currentStep === 1 && (user!.type == "DRIVING_SCHOOL_INSTRUCTOR" || user!.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") && (
            // <AddInstructorForm handleSlots={this.handleInstructorSlots} />
            <AddInsFormTwo handleSlots={this.handleInstructorSlots} />
          )}
        </div>

        <Modal
          title="Choose School"
          visible={this.state.showSelectSchoolModal}
          onOk={this.showSelectSchoolModalToggle}
          onCancel={this.showSelectSchoolModalToggle}
        >
          <Table
            rowSelection={{
              type: "radio",
              onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectSchool(selectedRows[0]);
              },
            }}
            columns={columnsSchools}
            dataSource={dataSchools}
          />
        </Modal>

        <Modal
          title="Choose Instructor"
          visible={this.state.showInstructorModal}
          onOk={this.showInstructorModalToggle}
          onCancel={this.showInstructorModalToggle}
        >
          <Table
            rowSelection={{
              type: "radio",
              onChange: (selectedRowKeys, selectedRows) => {
                this.onSelectInstructor(selectedRows[0]);
              },
            }}
            columns={columnsInstructors}
            dataSource={dataInstructors}
          />
        </Modal>
        <div
          className="modal fade"
          id="scheduleClassUpdate"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="scheduleClassUpdateLabel"
          aria-hidden="true"
          style={{ zIndex: "1055" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="scheduleClassUpdateLabel">
                  Check availability and Update class schedule
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="slotsContainer">

                </div>
                <div className="dateUpdateDiv">
                  Date :  <DatePicker format={['DD/MM/YYYY', 'DD/MM/YY']} onChange={(date, dateString) => this.setState({ checkDate: dateString, isSlotThere: false })} />
                  Time :  <TimePicker use12Hours format="h:mm a" onChange={(time, timeString) => this.setState({ checkTime: timeString, isSlotThere: false })} />
                  {this.state.isSlotThere ? <Tag color="cyan"> Slot  Available</Tag> : <Tag color="red"> Slot Not Available</Tag>}
                </div>
              </div>
              <div className="modal-footer">

                <button type="button" className="btn btn-primary" onClick={() => this.checkSlotAvailability(this.state.checkDate, this.state.checkTime)}>
                  Check Availability
                </button>
                {this.state.isSlotThere && <button type="button" className="btn btn-primary" onClick={() => this.updateClassSchedules(this.state.updateDate, this.state.updateTime)}>
                  Update
                </button>}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

// export default withRouter(UserAdd);
export default UserAdd;

import React from "react";
import {
  Button,
  Card,
  Divider,
  Input,
  message,
  PageHeader,
  Select,
} from "antd";
import { getClaims, RRServer } from "../../../utils/lib";
import { DrivingSchoolInterface, UserInterface } from "../../../types";
import TopBar from "../../TopBar";
// import TopBar from '../../TopBar';
// import TopBar from './../../TopBar';

const { Option } = Select;

type schoolHashedType = { [key: string]: DrivingSchoolInterface };

interface UserStateProps {
  smsTemplate: string;
  smsTemplateID: string;
  phone: string;
  schoolId: string;
  schools: DrivingSchoolInterface[];
  users: UserInterface[];
  schoolsHashed: schoolHashedType;
  userId: string;
  studentValue: {
    school: string;
    student: string;
  };
  claims: any;
}

class Settings extends React.Component<any, UserStateProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      smsTemplate: "",
      smsTemplateID: "",
      phone: "",
      schoolId: "",
      schools: [],
      users: [],
      schoolsHashed: {},
      userId: "",
      studentValue: {
        school: "",
        student: "",
      },
      claims: null,
    };
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    this.setState({ claims: JSON.parse(claimsString) });

    RRServer.list({ table: "settings", forCurrentUser: false }).then(
      (r: any) => {
        const settings = r.data.data;
        let _settings = { ...this.state };
        settings.map((s: { id: any; value: any }) => {
          switch (s.id) {
            case "sms-template-boarding":
              _settings["smsTemplate"] = s.value;
              break;
          }
        });
        this.setState(_settings);
      }
    );

    getClaims().then((claims) => {
      if (!claims || !!!claims["drivingSchoolId"]) {
        return null;
      }
      const drivingSchoolId = claims ? claims["drivingSchoolId"] : "";

      const userPromise =
        drivingSchoolId === "*" || claims.drivingSchoolId === "#"
          ? RRServer.list({ table: "users", forCurrentUser: false })
          : RRServer.list({
            table: "users",
            forCurrentUser: false,
            where: [["companyId", "==", drivingSchoolId]],
          });
      const dsPromise =
        drivingSchoolId === "*" || claims.drivingSchoolId === "#"
          ? RRServer.list({ table: "driving_schools", forCurrentUser: false })
          : RRServer.get({ table: "driving_schools", id: drivingSchoolId });

      Promise.all([
        // RRServer.list({ table: 'users', forCurrentUser: false, where:[['companyId','==',id]] }),
        userPromise,
        dsPromise,
        RRServer.list({ table: "corporate_users", forCurrentUser: false }),
        RRServer.list({ table: "corporate_codes", forCurrentUser: false }),
      ]).then((results: any) => {
        let users = results[0].data.data;

        let schools: DrivingSchoolInterface[] = results[1].data.data.length
          ? results[1].data.data
          : [results[1].data.data];
        let _s: schoolHashedType = {};
        schools.map((school) => (_s[school.id] = school));

        this.setState({ users, schools, schoolsHashed: _s });
      });
    });
  }

  saveSmsTemplate = () => {
    const { smsTemplate } = this.state;
    RRServer.update({
      table: "settings",
      id: "sms-template-boarding",
      value: smsTemplate,
    }).then((r: any) => {
      if (r.data.s) {
        message.success("Successfully Saved Template");
      }
    });
  };

  sendSampleSMS = () => {
    RRServer.callFn("notification1-sms", {
      tmpl: "sms-template-boarding",
      phone: this.state.phone,
      params: {
        code: "123",
        pass: "sssss",
      },
    });
  };

  setTmpl = (smsTemplate: string) => this.setState({ smsTemplate });
  setPhone = (phone: string) => this.setState({ phone });

  clearDevice = () => {
    RRServer.update({
      table: RRServer.Tables.USERS,
      id: this.state.userId,
      deviceUUID: "",
    }).then((r: any) => {
      if (r.data.s) {
        message.success("Successfully Cleared");
      }
    });
  };

  makeSuperUser = (e: any) => {
    e.preventDefault()
    const id = (document.getElementById('user-id1') as HTMLInputElement)?.value;
    RRServer.callFn('users1-setDSID', {
      uid: id,
      DSid: '*',
      admin: true
    })
      .then(r => {
        console.log(r)
      })
      .catch(err => {
        console.error(err)
      })
  }
  // makeSuperUserHash = (e:any) => {
  //     e.preventDefault()

  //     const id = (document.getElementById('user-id2') as HTMLInputElement)?.value;
  //     RRServer.callFn('users1-setDSID', {
  //         uid: id,
  //         DSid: '#',
  //         admin: true
  //     })
  //         .then(r => {
  //             console.log(r)
  //         })
  //         .catch(err => {
  //             console.error(err)
  //         })
  // }
  assignUserToCluster = (e: any) => {
    e.preventDefault()

    const id = (document.getElementById('user-id3') as HTMLInputElement)?.value;
    const cid = (document.getElementById('cluster-id') as HTMLInputElement)?.value;

    RRServer.callFn('users1-setClusterUser', {
      uid: id,
      cid: cid,
      DSid: '#',
      admin: true
    })
      .then(r => {
        console.log(r)
      })
      .catch(err => {
        console.error(err)
      })
  }
  assignUserToAssociation = (e: any) => {
    e.preventDefault()
    const id = (document.getElementById('user-id4') as HTMLInputElement)?.value;

    const aid = (document.getElementById('ass-id') as HTMLInputElement)?.value;
    RRServer.callFn('users1-setAssociationUser', {
      uid: id,
      aid: aid,
      DSid: '#',
      admin: true
    })
      .then(r => {
        console.log(r)
      })
      .catch(err => {
        console.error(err)
      })
  }

  assignUserToSales = (e: any) => {
    e.preventDefault()
    const id = (document.getElementById('user-id5') as HTMLInputElement)?.value;

    const aid = (document.getElementById('sales-id') as HTMLInputElement)?.value;
    RRServer.callFn('users1-setSalesUser', {
      uid: id,
      aid: aid,
      DSid: '#',
      admin: true
    })
      .then(r => {
        console.log(r)
      })
      .catch(err => {
        console.error(err)
      })
  }

  render() {
    const { smsTemplate, phone, schools, users, schoolId, userId } = this.state;
    const usersFiltered = schoolId
      ? users.filter((user) => user.companyId == schoolId)
      : users;

    return (
      <>
        <TopBar title="Settings" />
        {/* {console.log("statee", this.state)} */}
        <div className="contentContainer">
          <div className="settingsForm">
            <div className="form">
              <h3>Clear Device</h3>
              {/* {console.log("statee", this.state)} */}
              <div className="row">
                <div className="col-md-4">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="school"
                      name="schoolId"
                      onChange={(e) =>
                        this.setState({ schoolId: e.target.value })
                      }
                      aria-label="Floating label select example"
                    >
                      <option selected>Open this select menu</option>
                      {this.state.schools.map((skl, idx) => {
                        return (
                          <option key={idx} value={skl.id}>
                            {skl.drivingSchoolName}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor="floatingSelect">Select School</label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-floating">
                    {/* {console.log("userrr", this.state.users)} */}
                    <select
                      className="form-select"
                      id="student"
                      onChange={(e) =>
                        this.setState({
                          studentValue: {
                            ...this.state.studentValue,
                            [e.target.name]: e.target.value,
                          },
                          userId: e.target.value,
                        })
                      }
                      aria-label="Floating label select example"
                    >
                      <option selected>Open this select menu</option>

                      {this.state.users
                        .filter(
                          (student: any) =>
                            student.companyId === this.state.schoolId
                        )
                        .map((user, idx) => {
                          return (
                            <option key={idx} value={user.id}>
                              {user.name}
                            </option>
                          );
                        })}
                    </select>
                    <label htmlFor="floatingSelect">Select Student</label>
                  </div>
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.clearDevice()}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>



          </div>
          {this.state.claims?.drivingSchoolId === '*' && <div className="form">
            <h3>Make super admin</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-id1"
                  placeholder="name@example.com"
                />
                <label htmlFor="user-id1">Enter User ID</label>
              </div>
              <button className="btn btn-primary" onClick={(e) => this.makeSuperUser(e)}>ADD *</button>
            </form>
          </div>}
          {/* {this.state.claims?.drivingSchoolId === '*' && <div className="form">
            <h3>Make super admin hash</h3>
            <form>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="user-id2"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="user-id2">Enter User ID</label>
                  </div>
                  <button className="btn btn-primary" onClick={(e)=>this.makeSuperUserHash(e)}>ADD #</button>
                </form>
          </div>} */}
          {this.state.claims?.drivingSchoolId === '*' && <div className="form">
            <h3>Add Cluster User</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-id3"
                  placeholder="name@example.com"
                />
                <label htmlFor="user-id3">Auth UID</label>

              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="cluster-id"
                  placeholder="name@example.com"
                />
                <label htmlFor="‘cluster-id">Cluster ID</label>

              </div>
              <button className="btn btn-primary" onClick={(e) => this.assignUserToCluster(e)}>Add Cluster User</button>
            </form>
          </div>}
          {this.state.claims?.drivingSchoolId === '*' && <div className="form">
            <h3>Add Association User</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-id4"
                  placeholder="name@example.com"
                />
                <label htmlFor="user-id4">Auth UID</label>

              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="‘cluster-id"
                  placeholder="name@example.com"
                />
                <label htmlFor="cluster-id">Association ID</label>

              </div>
              <button className="btn btn-primary" onClick={(e) => this.assignUserToAssociation(e)}>Add Association User</button>
            </form>
          </div>}

          {/* {this.state.claims?.drivingSchoolId === '*' && */}
           <div className="form">
            <h3>Add Sales User</h3>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="user-id5"
                  placeholder="name@example.com"
                />
                <label htmlFor="user-id4">Auth UID</label>

              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="sales-id"
                  placeholder="name@example.com"
                />
                <label htmlFor="sales-id">Sales ID</label>

              </div>
              <button className="btn btn-primary" onClick={(e) => this.assignUserToSales(e)}>Add Sales User</button>
            </form>
          </div>
          {/* } */}

        </div>
      </>
    );
  }
}

export default Settings;

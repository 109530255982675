import React, { Component } from 'react';
import { RRServer } from '../../utils/lib';
import styles from './FormComponent.module.css';


interface FieldProps {
    type: string,
    name: string
    label: string,
    isMandatory: boolean,
    validation: (value: any) => boolean,
    size: number,
    options?: any[],
    gridSm: number,
    gridMd: number
    handleValue: ({ name, value }: any) => void,
    value?: any,
    isCode: boolean

}

interface FieldState {
    value: string,
    name: string,
    isInvalid: boolean,
    schools: any[],
    setShowList: boolean
}

class Field extends Component<FieldProps, FieldState> {

    state: FieldState = {
        value: '',
        name: '',
        isInvalid: false,
        schools: [],
        setShowList: false
    }



    componentDidMount() {

        setTimeout(() => {
            this.setState({ value: this.props.value })
        }, 2000)

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || ''

        if (claims.drivingSchoolId !== '*') {

            RRServer.get({ table: 'driving_schools', id: claims.drivingSchoolId }).then((result: any) => {
                // console.log('dataaaa gett',result)
                let schools = [result?.data?.data];
                this.setState({
                    schools: schools.map((skl) => {
                        return skl.code
                    })
                })
            });

        } else if (claims.drivingSchoolId === '#' && claims.userType === 'CLUSTER') {
            RRServer.get({ table: 'driving_schools', id: claims.clusterID }).then((result: any) => {
                // console.log('dataaaa gett',result)
                let schools = [result?.data?.data];

                this.setState({
                    schools: schools.map((skl) => {
                        return skl.code
                    })
                })
                // this.setState({ schools })
            });

        } else if (claims.drivingSchoolId === '#' && claims.userType === 'ASSOCIATION') {
            RRServer.get({ table: 'driving_schools', id: claims?.user_id }).then((result: any) => {
                // console.log('dataaaa gett',result)
                let schools = [result?.data?.data];
                // this.setState({ schools })

                this.setState({
                    schools: schools.map((skl) => {
                        return skl.code
                    })
                })
            });

        }

        else {
            RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {

                let schools = result.data.data;
                // this.setState({ schools })

                this.setState({
                    schools: schools.map((skl: any) => {
                        return skl.code
                    })
                })
                
            });
        }
    }


    handleChange = (e: any) => {
        const { name, value, type } = e.target

        if (this.state.value?.length > 2) {
            this.setState({ setShowList: true })
        } else {
            this.setState({ setShowList: false })
        }

        if (type === "checkbox") {
            const { checked } = e.target
            this.setState({ name: e.target.name, value: checked })
            this.props.handleValue({ name, value: checked })
        } else {
            this.setState({ name: e.target.name, value: e.target.value })
            if (this.props.validation && this.props.validation(value)) {
                this.setState({ isInvalid: false })
                this.props.handleValue({ name, value })
            } else {
                this.setState({ isInvalid: true })
            }
        }
    }

    render() {

        const { options } = this.props

        return (
            <>
                {(this.props.type === 'text' || this.props.type === 'number' || this.props.type === 'date') &&
                    <>
                        {!this.props.isCode ? <div className={`mb-3 form-floating  inline-block col-md-${this.props.gridMd} col-sm-${this.props.gridSm}`}>
                            <input className={`form-control ${this.state.isInvalid && `is-invalid`}`} value={this.state.value} type={this.props.type} name={this.props.name} onChange={(e) => this.handleChange(e)} placeholder={this.props.label} maxLength={200} id={this.props.name} required={this.props.isMandatory} />
                            <label htmlFor={this.props.name} className={`px-4 ${this.props.isMandatory && `${styles.mandatory}`}`}>{this.props.label}</label>
                        </div> :
                            <>
                                <div className={`mb-3 form-floating  inline-block col-md-${this.props.gridMd} col-sm-${this.props.gridSm}`}>
                                    <label htmlFor={this.props.name} className={`px-4 ${this.props.isMandatory && `${styles.mandatory}`}`}>{this.props.label}</label>
                                    <input className={`form-control ${this.state.isInvalid && `is-invalid`}`} value={this.state.value} list="browsers" name={this.props.name} onChange={(e) => this.handleChange(e)} placeholder={this.props.label} maxLength={200} id={this.props.name} required={this.props.isMandatory} />
                                </div>


                                {this.state.setShowList && <datalist id="browsers">

                                    {this.state.schools.map((skl: any) => {
                                        return <option value={skl} />

                                    })}

                                </datalist>}
                            </>
                        }


                    </>
                }

                {
                    this.props.type === 'select' && <div className={`col-md-${this.props.gridMd}   col-sm-${this.props.gridSm} mb-3 form-floating d-inline-block`}>
                        <select className={`form-select form-control ${this.state.isInvalid && `is-invalid`}`} value={this.state.value} name={this.props.name} onChange={(e) => this.handleChange(e)} id={this.props.name} aria-label="Floating label select example" required={this.props.isMandatory}>
                            <option selected value={""}>Choose from dropdown</option>
                            {options && options.map((opt: any, index: any) => {
                                return <option value={opt.value} key={index}>{opt.label}</option>
                            })}
                        </select>
                        <label htmlFor={this.props.name} className={`px-4 ${this.props.isMandatory && `${styles.mandatory}`}`}>{this.props.label}</label>
                    </div>
                }

                {
                    this.props.type === 'checkbox' && <div className={`col-md-${this.props.gridMd} col-sm-${this.props.gridSm} m-3 form-check d-inline-block`}>
                        <input className={`form-check-input form-control ${this.state.isInvalid && `is-invalid`}`} name={this.props.name} type={this.props.type} onChange={(e: any) => this.handleChange(e)} id={this.props.name} required={this.props.isMandatory} />
                        <label className={`${this.props.isMandatory && `${styles.mandatory}`} form-check-label`} htmlFor={this.props.name}>
                            {this.props.label}
                        </label>
                    </div>
                }

              

                {
                    this.props.type === 'file' && <div className={`col-md-${this.props.gridMd} col-sm-${this.props.gridSm}`}>
                        <label htmlFor={this.props.name} className="form-label">{this.props.label}</label>
                        <input className="form-control" type="file" id={this.props.name} multiple />
                    </div>
                }

                {
                    (this.props.type === 'password') &&
                    <div className={`mb-3 form-floating  inline-block col-md-${this.props.gridMd} col-sm-${this.props.gridSm}`}>
                        <input className={`form-control ${this.state.isInvalid && `is-invalid`}`} type={this.props.type} name={this.props.name} value={this.state.value} onChange={(e) => this.handleChange(e)} placeholder={this.props.label} maxLength={200} id={this.props.name} required={this.props.isMandatory} />
                        <label htmlFor={this.props.name} className={`px-4 ${this.props.isMandatory && `${styles.mandatory}`}`}>{this.props.label}</label>
                    </div>
                }
            </>
        );
    }
}

export default Field;
import React, { Component } from 'react';
import redChariotLogo from '../assets/redChariot.png';


class Footer extends Component {
    render() {
        return (
            <div style={{display:"flex",padding:"20px", position:"relative", bottom:"0"}}>
                    <img style={{marginLeft:"auto"}} src={redChariotLogo} height={45} alt="" />
            </div>
        );
    }
}

export default Footer;
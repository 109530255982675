import React from "react";
import firebase from "../../../utils/Firebase";
import { Divider, Select, Space, Table } from "antd";
import { EditFilled, EyeFilled } from "@ant-design/icons";
import { callOnServer, RRServer } from "../../../utils/lib";
// import { any } from '../../../types';
import TopBar from "../../TopBar";
import TableContainer from "../../TableComp/TableContainer";
import FormComponent from "../../FormComponent/FormComponent";
import { SelectColumnFilter } from "../../TableComp/Filter";
import ClusterAdd from "./ClusterAdd";

const { Option } = Select;

interface DrivingSchoolInterface {
  code: string;
  id: string;
  drivingSchoolName: string;
  currentCount: string;
}

interface UserStateProps {
  clusters: any[];
  associations: any[];
  selectedAssociation: any | null;
  userCode: string;
  filterAssociationId: string;
  isToggle: boolean;
  isSalesUser: boolean
}

class Clusters extends React.Component<any, UserStateProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      clusters: [],
      associations: [],
      selectedAssociation: null,
      userCode: "",
      filterAssociationId: "",
      isToggle: true,
      isSalesUser: false
    };
    // this.getSchool = this.getSchool.bind(this);
  }

  componentDidMount() {
    const claimsString = localStorage.getItem("claims") || "{}";
    const claims = JSON.parse(claimsString);
    const isSalesUser = claims.drivingSchoolId === "#" && claims?.userType === "SALES"? true : false
    this.setState({isSalesUser})
    claims.drivingSchoolId = claims.drivingSchoolId || "";

    Promise.all([
      RRServer.list({ table: "associations", forCurrentUser: false }),
      RRServer.list({ table: "clusters", forCurrentUser: false }),
    ]).then((data: any) => {
      const clusters = data[1].data.data;
      const associations = data[0].data.data;

      // const clusters = clustersResult;

      let associationsHash: any = {};

      associations.map((a: any) => (associationsHash[a.id] = a));

      this.setState({
        clusters: clusters.map(
          (c: any) => {( (c.association = associationsHash[c.association]?  associationsHash[c.association].name : '')); return c}
        ),
        associations: associations,
      });
    });

  //   Promise.all([
  //     RRServer.list({ table: "associations", forCurrentUser: false }),
  //     RRServer.list({ table: "clusters", forCurrentUser: false }),
  //   ]).then((data: any) => {
  //     const clusters = data[1].data.data;
  //     const associations = data[0].data.data;
  //     console.log("cliusters", clusters);
  //     console.log("ass", associations);

  //     let associationsHash: any = {};
  //     associations.map((a: any) => (associationsHash[a.id] = a));
 
  //     this.setState({ 
  //       clusters: clusters.map(
  //         (c: any) => (c.association = associationsHash[c.association]?.name)
  //       ),
  //       associations: associations
  //     });
  //   });
  }

  getAssociation = (schoolId: string) => {
    const cluster = this.state.clusters.filter(
      (school) => (school.id = schoolId)
    );
    return cluster[0];
  };

  toggleForm = (e: any) => {
    e.preventDefault();
    this.setState({ isToggle: !this.state.isToggle });
    // console.log(this.state.isToggle);
  };

  handleValues = (values: any) => {
    console.log(values);
    // setTimeout(() => this.addDrivingSchool(values), 200);
  };

  render() {
    const { clusters, filterAssociationId: filterSchoolId } = this.state;
    // let { url } = this.props.match;

    // let dataSource = clusters.map((school) => ({ key: school.id, ...school }));

    // if (filterSchoolId) {
    //   dataSource = dataSource.filter((d) => d.id === filterSchoolId);
    // }

    const columns = [
      {
        Header: "Name",
        accessor: "name",
        // Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Association",
        accessor: "association",
        Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Date",
        accessor: "addedOn",
        Cell: (val: any) => {
          var unixts = val.value;
          var date = new Date(unixts);

          var fdate =
            ("0" + date.getDate()).slice(-2) +
            "/" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            date.getFullYear();
          return <p>{fdate}</p>;
        },
        // Filter: SelectColumnFilter,
        // disableSortBy: true
      },
      {
        Header: "Action",
        accessor: "id",
        disableFilters: true,
        Cell: (val: any) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                cursor: "pointer",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-square"
                viewBox="0 0 16 16"
                onClick={(e) =>
                  (window.location.href = `cluster/edit/${val.value}`)
                }
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-eye-fill"
                viewBox="0 0 16 16"
              >
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            </div>
          );
        },
      },
    ];

    const fields: any = [
      {
        type: "text",
        name: "clusterName",
        label: "Cluster Name",
        isMandatory: true,
        gridMd: 12,
        gridSm: 12,
        validation: (value: any) => (!value ? false : true),
      },
    ];

    return (
      <>
        <TopBar title="Cluster" />

        <div className="contentContainer">
          <div className="contentTop">
            <h5>{this.state.isToggle ? "Cluster List" : "Add Cluster"}</h5>

            <div className="tableBtn" style={{ marginLeft: "auto" }}>
              {/* <button
                    className="btn btn-primary"
                    onClick={(e) => this.toggleForm(e)}
                  >
                    {this.state.isToggle ? "Add Cluster" : "CANCEL"}
                  </button> */}
            {!this.state.isSalesUser &&  <button
                className="btn btn-primary"
                onClick={(e) => (window.location.href = "cluster/add")}
              >
                {this.state.isToggle ? "Add Cluster" : "CANCEL"}
              </button>}
            </div>
          </div>
          {console.log("clusters", this.state.clusters)}
          <div
            className={`${this.state.isToggle ? "table show" : "table hide"}`}
          >
            <TableContainer columns={columns} data={this.state.clusters} />
          </div>
          <div className={`${this.state.isToggle ? `form hide` : `form show`}`}>
            {/* <DrivingSchoolAdd/> */}
            {/* <FormComponent
                  fields={fields}
                  toggleForm={(e: any) => this.toggleForm(e)}
                  handleValues={this.handleValues}
                  buttonText="Submit"
                /> */}
            <ClusterAdd/>
          </div>
        </div>
      </>
    );
  }
}

// export default withRouter(UserAdd);
export default Clusters;

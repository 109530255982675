
import React from "react";
// import firebase from "../../utils/Firebase";
import { Button, DatePicker, Divider, Input, Select, Space, Table } from "antd";
import { EditFilled, EyeFilled, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { callOnServer, RRServer } from "../../../utils/lib";
import { InstallmentInterface, UserInterface } from "../../../types";
// import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import TopBar from "../../TopBar";
import { CSVLink } from "react-csv";
import TableContainer from "../../TableComp/TableContainer";
import { DateColumnFilter, SelectColumnFilter } from "../../TableComp/Filter";

const { Option } = Select;

interface DrivingSchoolInterface {
    code: string;
    id: string;
    drivingSchoolName: string;
    currentCount: string;
}

interface InstallmentStateProps {
    isToggle: boolean
    schools: DrivingSchoolInterface[];
    selectedSchool: DrivingSchoolInterface | null;
    schoolsHashed: { [key: string]: DrivingSchoolInterface };
    users: UserInterface[];
    usersHashed: { [key: string]: UserInterface };
    userCode: string;
    filterSchoolId: string;
    filterUserId: string;
    filterDate: string;
    installments: InstallmentInterface[];
    searchText: string;
    searchedColumn: string;
    installmentDetailsId: any;
    filteredInfo: any;
}

class InstallmentsList extends React.Component<any, InstallmentStateProps> {
    searchInput:  null | undefined;
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            schoolsHashed: {},
            selectedSchool: null,
            users: [],
            usersHashed: {},
            userCode: "",
            filterSchoolId: "",
            filterUserId: "",
            filterDate: "",
            installments: [],
            searchText: "",
            searchedColumn: "",
            filteredInfo: {},
            installmentDetailsId: null,
            isToggle: false
        };
        this.getSchool = this.getSchool.bind(this);
    }
    toggleCard = (e: any) => {
        this.setState({ isToggle: !this.state.isToggle });
    }

    

    componentDidMount() {
        const claimsString = localStorage.getItem("claims") || "{}";
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || "";

        if (claims.drivingSchoolId !== "*" || claims.drivingSchoolId === "#") {
            Promise.all([
                RRServer.get({ table: "driving_schools", id: claims.drivingSchoolId }),
                RRServer.list({
                    table: RRServer.Tables.USERS,
                    forCurrentUser: false,
                    where: [
                        ["companyId", "==", claims.drivingSchoolId],
                        ["type", "==", "DRIVING_SCHOOL_USER"],
                    ],
                }),
                RRServer.list({
                    table: RRServer.Tables.INSTALLMENTS,
                    forCurrentUser: false,
                    where: [["companyId", "==", claims.drivingSchoolId]],
                }),
            ]).then((results: any) => {
                let schools = [results[0].data.data];
                let users = results[1].data.data;
                let installments = results[2].data.data;
                let usersHashed: { [key: string]: UserInterface } = {};
                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                users.map((user: UserInterface) => (usersHashed[user?.id] = user));
                schools.map(
                    (school: DrivingSchoolInterface) =>
                        (schoolsHashed[school?.id] = school)
                );
                let userHash: any = {};

                users.map((a: any) => (userHash[a.id] = a));
                this.setState({
                  installments: installments.map(
                    (c: any) => {( (c.uid = userHash[c.uid]?  userHash[c.uid].name : '')); return c}
                  ),
                });
                this.setState({
                    installments: installments.map(
                      (c: any) => {( (c.companyId = schoolsHashed[c.companyId]?  schoolsHashed[c.companyId].drivingSchoolName : '')); return c}
                    ),
                  });
                this.setState({
                    schools,
                    users,
                    usersHashed,
                    installments,
                    schoolsHashed,
                });
            });
        } else {
            Promise.all([
                RRServer.list({ table: "driving_schools", forCurrentUser: false }),
                RRServer.list({
                    table: RRServer.Tables.USERS,
                    forCurrentUser: false,
                    where: [["type", "==", "DRIVING_SCHOOL_USER"]],
                }),
                RRServer.list({
                    table: RRServer.Tables.INSTALLMENTS,
                    forCurrentUser: false,
                }),
            ]).then((results: any) => {
                let schools = results[0].data.data;
                let users = results[1].data.data;
                let installments = results[2].data.data;
                let usersHashed: { [key: string]: UserInterface } = {};
                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                users.map((user: UserInterface) => (usersHashed[user.id] = user));
                schools.map(
                    (school: DrivingSchoolInterface) =>
                        (schoolsHashed[school.id] = school)
                );
                let userHash: any = {};
                let sklHash: any = {};


                users.map((a: any) => (userHash[a.id] = a));
                schools.map((a: any) => (sklHash[a.id] = a));

                const newLisst = installments.map(
                    (c: any) => {( (c.uid = userHash[c.uid]?  userHash[c.uid].name : '')); return c}
                  )
                const UpdatedListt = newLisst.map(
                    (c: any) => {( (c.companyId = sklHash[c.companyId]?  sklHash[c.companyId].drivingSchoolName : '')); return c}
                  )
                //   console.log('newList', UpdatedListt)

                this.setState({
                  installments: UpdatedListt})
            
        
                this.setState({
                    schools,
                    users,
                    usersHashed,
                    installments,
                    schoolsHashed,
                });
            });
        }
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(
            (school) => (school.id = schoolId)
        );
        return school[0];
    }

    handleChange = (pagination: any, filters: any, sorter: any) => {
        // console.log("Various parameters", pagination, filters, sorter);
        this.setState({
            filteredInfo: filters,
        });
    };

    handleToggleCheck = (e: any) => {
        // console.log(e.target.checked, "asd");
        e.target.checked = !e.target.checked;
    };

    render() {
        // console.log("Insytasfuyg",this.state.installments);
        const {
            schools,
            filterSchoolId,
            filterUserId,
            filterDate,
            usersHashed,
            users,
            installments,
            schoolsHashed,
            filteredInfo,
        } = this.state;
        // let { url } = this.props.match;

        let dataSource = installments.map((installment) => ({
            key: installment.id,
            ...installment,
            status: installment.status ? "Paid" : "Pending",
        }));

        if (filterSchoolId) {
            dataSource = dataSource.filter((d) => d.companyId === filterSchoolId);
        }
        if (filterUserId) {
            dataSource = dataSource.filter((d) => d.uid === filterUserId);
        }
        if (filterDate) {
            dataSource = dataSource.filter((d) => d.dueDate === filterDate);
        }

        function GetFormattedDate() {
            var todayTime = new Date();
            var month = todayTime.getMonth() + 1;
            var monthFormat = month.toString().length === 1 ? `0${month}` : month
            var day = todayTime.getDate();
            var dayFormat = day.toString().length === 1 ? `0${day}` : day
            var year = todayTime.getFullYear();
            return dayFormat + "/" + monthFormat + "/" + year;
          }
          function GetFormattedDateDash() {
            var todayTime = new Date();
            var month = todayTime.getMonth() + 1;
            var monthFormat = month.toString().length === 1 ? `0${month}` : month
            var day = todayTime.getDate();
            var dayFormat = day.toString().length === 1 ? `0${day}` : day
            var year = todayTime.getFullYear();
            return dayFormat + "-" + monthFormat + "-" + year;
          }

        return (
            <>
                <div className={this.state.isToggle ? `dashBotCard1 installments show` : `dashBotCard1 installments hide`} onClick={(e) => { this.toggleCard(e) }}>
                    <div className="linkTo">
                        <div className="titleTag">
                            <h4>Installments</h4>
                            <div className="arrow"></div>
                        </div>

                        <span className="">{GetFormattedDate()}</span>

                        <a href="installments">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                            </svg>
                        </a>
                    </div>
                    <div className='tableA'>
                        <table className="table table-borderless">
                            <thead>
                                <tr>
                                    <th>School</th>
                                    <th>Name</th>
                                    <th>No. Of Intallments</th>
                                </tr>
                            </thead>
                            <tbody>
                            {installments && installments.filter((ins:any)=>{
                                return ins.dueDate === GetFormattedDateDash()
                            }).map((td: any, index) => {
                                if (index <= 4) {
                                  return <tr>
                                    <td>{td.companyId}</td>
                                    <td>{td.uid}</td>
                                    <td>{td.installment}</td>
                                  </tr>
                                }
                              })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </>
        );
    }
}

// export default withRouter(UserAdd);
export default InstallmentsList;


// import React, { Component } from 'react'

// export default class InstallmentsMini extends Component {
//     render() {
//         return (
            // <div className="dashBotCard1 installments">
            //     <div className="linkTo">
            //         <div className="titleTag">
            //             <h4>Installments</h4>
            //             <div className="arrow"></div>
            //         </div>

            //         <span className="">1st March - 2022</span>

            //         <a href="installments">
            //             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
            //                 <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            //             </svg>
            //         </a>
            //     </div>
            //     <div className='tableA'>
            //         <table className="table table-borderless">
            //             <thead>
            //                 <tr>
            //                     <th>School</th>
            //                     <th>Name</th>
            //                     <th>No. Of Intallments</th>
            //                 </tr>
            //             </thead>
            //             <tbody>
            //                 <tr>
            //                     <td>Praveen</td>
            //                     <td>Ibu</td>
            //                     <td>1</td>
            //                 </tr>

            //                 <tr>
            //                     <td>Praveen</td>
            //                     <td>Ibu</td>
            //                     <td>5</td>
            //                 </tr>

            //                 <tr>
            //                     <td>Praveen</td>
            //                     <td>Ibu</td>
            //                     <td>3</td>
            //                 </tr>

            //                 <tr>
            //                     <td>Praveen</td>
            //                     <td>Ibu</td>
            //                     <td>2</td>
            //                 </tr>

            //                 <tr>
            //                     <td>Praveen</td>
            //                     <td>Ibu</td>
            //                     <td>3</td>
            //                 </tr>
            //             </tbody>
            //         </table>

            //     </div>
            // </div>
//         )
//     }
// }

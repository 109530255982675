import React from 'react';

import { DatePicker, Select, Table } from 'antd';
import { CorporateInterface, DrivingSchoolInterface, UserInterface } from '../../../types';
import { RRServer } from '../../../utils/lib';
import dayjs from 'dayjs';
import TopBar from './../../TopBar';
import { CSVLink } from 'react-csv';
import TableContainer from './../../TableComp/TableContainer';
import { DateColumnFilter, DateColumnFilter2, SelectColumnFilter } from '../../TableComp/Filter';

const { Option } = Select;

interface ScoreInterface {
    id: string
    cid: string
    uid: string
    game: string
    level: number
    score: number
    addedOn: number
}

interface ScoresViewStateProps {
    scores: ScoreInterface[]
    loading: boolean
    schools: DrivingSchoolInterface[],
    corporates: CorporateInterface[],
    users: UserInterface[]
    filterSchoolId: string,
    filterUserId: string,
    filterDate: number,
    userHashed: { [key: string]: UserInterface },
    schoolsHashed: { [key: string]: DrivingSchoolInterface },
    corpHashed: { [key: string]: CorporateInterface },
}

class ScoresList extends React.Component<any, ScoresViewStateProps>{
    constructor(props: any) {
        super(props);

        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};

        this.state = {
            loading: true,
            scores: [],
            schools: [],
            corporates: [],
            users: [],
            filterSchoolId: '',
            filterUserId: '',
            filterDate: 0,
            userHashed: {},
            schoolsHashed: {},
            corpHashed: {}
        };
    }

    componentDidMount() {

        const claimsStr = localStorage.getItem('claims');
        const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
        const drivingSchoolId = claims.drivingSchoolId || '';

        let promiseArr: any = [];
        if (drivingSchoolId === "*" || claims.drivingSchoolId === "#") {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false }),
                RRServer.list({ table: "users", forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
            ]
        } else {
            promiseArr = [
                RRServer.list({ table: "scores", forCurrentUser: false, where: [['cid', '==', drivingSchoolId]] }),
                RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
                RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
            ]
        }

        Promise.all(promiseArr)
            .then((results: any) => {
                const scores = results[0].data.data;
                const users = results[1].data.data as UserInterface[];
                const schools = results[2].data.data as DrivingSchoolInterface[];
                const corporates = results[3].data.data as CorporateInterface[];

                let userHashed: { [key: string]: UserInterface } = {};
                users.map(user => userHashed[user.id] = user);

                let schoolsHashed: { [key: string]: DrivingSchoolInterface } = {};
                schools.map(school => schoolsHashed[school.id] = school);

                let corpHashed: { [key: string]: CorporateInterface } = {};
                corporates.map(school => corpHashed[school.id] = school);

                const userHashh: any = {}
                const sklHash: any = {}
                users.map((a: any) => (userHashh[a.id] = a));
                schools.map((a: any) => (sklHash[a.id] = a));

                // const listt = scores.map((score:any)=>{
                //     return {...score, userId: userHashh[score.uid] ? userHashh[score.uid].uid : ""}
                // })

                // console.log(listt, 'liss')

                const newLisst = scores.map((c : any)=>{
                    c.addedBy = userHashh[c.uid] ? userHashh[c.uid].id : ''
                    return c
                }).map(
                    (c: any) => {
                        // c.addedBy = userHashh[c.uid] ? userHashh[c.uid].uid : ""
                        c.uid = userHashh[c.uid] ? userHashh[c.uid].name : ''

                        return c
                    }
                )

                    



                const UpdatedListt = newLisst.map(
                    (c: any) => { ((c.cid = sklHash[c.cid] ? sklHash[c.cid].drivingSchoolName : '')); return c }
                )
                //   console.log('newList', UpdatedListt)

                this.setState({
                    scores: UpdatedListt
                })

                this.setState({ loading: false, scores, users, userHashed, schools, schoolsHashed, corporates, corpHashed })
            })
    }

    render() {

        const { scores, schools, filterSchoolId, filterUserId, filterDate, users, userHashed, schoolsHashed, corporates, corpHashed } = this.state;
        // let { url } = this.props.match;

        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';
        const isSuperAdminOrSales = claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#"

        // const usersHash = users.map((user : any)=> ([user.id] = user))

        let dataSource = scores.map(score => ({ key: score.id, ...score }))


        console.log(dataSource.filter((data) => data.hasOwnProperty("diZoneLevel"), 'suu'))


        const columns = isSuperAdminOrSales ? [
            {
                Header: 'School',
                accessor: 'cid',
                Filter: SelectColumnFilter
            },
            {
                Header: 'User',
                accessor: 'uid',
                Filter: SelectColumnFilter
            },
            {
                Header: 'Game',
                accessor: 'game',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Date and Time',
                accessor: 'addedOn',
                Filter: DateColumnFilter,
                // Cell: (val: any) => {
                //     return ((new Date(val.value).toISOString().substr(0, 10))).replaceAll('-',"/").split("/").reverse().join("/").toString()
                // }
            },
            {
                Header: 'Attempt',
                accessor: 'attemptNumber',
            },
            {
                Header: 'di Zone Level',
                accessor: 'diZoneLevel',
                Filter: SelectColumnFilter

            },

            {
                Header: 'Level',
                accessor: 'level',
            },
            {
                Header: 'Score',
                accessor: 'score',
            },
        ]  : [
            {
                Header: 'School',
                accessor: 'cid',
                Filter: SelectColumnFilter
            },
            {
                Header: 'User',
                accessor: 'uid',
                Filter: SelectColumnFilter
            },
            {
                Header: 'Game',
                accessor: 'game',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Date and Time',
                accessor: 'addedOn',
                Filter: DateColumnFilter,
                // Cell: (val: any) => {
                //     return ((new Date(val.value).toISOString().substr(0, 10))).replaceAll('-',"/").split("/").reverse().join("/").toString()
                // }
            },
            {
                Header: 'Attempt',
                accessor: 'attemptNumber',
            },
            {
                Header: 'di Zone Level',
                accessor: 'diZoneLevel',
                Filter: SelectColumnFilter

            },
            {
                Header: 'Score',
                accessor: 'score',
            },
        ]


        if (filterSchoolId) {
            dataSource = dataSource.filter(d => d.cid === filterSchoolId)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.uid === filterUserId)
        }
        if (!!filterDate) {
            // const df = dayjs(filterDate).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            // dataSource = dataSource.filter(d => {
            //     const da = dayjs(d.addedOn).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            //     return da.isSame(df)
            // })
            dataSource = dataSource.filter(d => d.uid === filterUserId)

        }

        const headers = [
            { label: "Schools", key: "school" },
            { label: "Name", key: "name" },
            { label: "No Of Installments", key: "noOfInstallments" },
            { label: "Status", key: "status" },
            { label: "Payment Start Date", key: "paymentStartDate" },
            { label: "Payment End Date", key: "paymentEndDate" },
            { label: "Action", key: "action" },
        ];


        return (
            <>
                <TopBar title="Scores" />

                <div className='contentContainer'>
                    <div className='contentTop'>
                        <h5>Scores</h5>

                        <div className='tableBtn' style={{ marginLeft: "auto" }}>
                            {/* { <CSVLink data={Data} headers={headers} className='btn btn-primary' style={stylesBtn()} >Export CSV</CSVLink> } */}
                        </div>
                        <div className="tableBtn" style={{ marginLeft: "auto" }}>
                            <CSVLink
                                data={this.state.scores}
                                headers={headers}
                                className="btn btn-primary" /* style={stylesBtn()} */
                            >
                                Export CSV
                            </CSVLink>
                        </div>
                    </div>
                    <div className="table show">

                        <TableContainer columns={columns} data={this.state.scores.map((score: any) => {
                            const idArr = users.map((usr: any) => usr?.diZoneLevel ? usr.id : "").filter((user) => user !== undefined && user !== "")

                            if (idArr.includes(score.addedBy)) {

                                const selectedUser: any = users.filter((usr) => usr.id === score.addedBy)[0]

                                // console.log(selectedUser, 'suu')

                                return { ...score, diZoneLevel: selectedUser?.diZoneLevel }

                            } else {
                                return score
                            }

                        }).map((score) => {
                            return { ...score, addedOn: ((new Date(score.addedOn).toISOString().substr(0, 10))).replaceAll('-', "/").split("/").reverse().join("/").toString() }
                        })} />
                    </div>
                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="Name">Name</label>
                                            <input type="text" className='form-control' value="Data one" />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Due Date</label>
                                            <input type="date" className='form-control' />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Status</label>
                                            <input type="text" className='form-control' value="1" />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Paid Date</label>
                                            <input type="date" className='form-control' />
                                        </div>

                                        <div className="col-md-4">
                                            <label htmlFor="Name">Rating</label>
                                            <select name="rating" id="" className='form-control'>
                                                <option value="">--Rating--</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="exampleModal1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <table className='table table-hover'>
                                        <tr>
                                            <th>Name</th>
                                            <th>Due Date</th>
                                            <th>Status</th>
                                            <th>Paid Date</th>
                                            <th>Rating</th>
                                        </tr>
                                        <tr>
                                            <td>Data one</td>
                                            <td>07-05-2021</td>
                                            <td>Paid</td>
                                            <td>12-12-22</td>
                                            <td>1</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }




};

export default ScoresList;

const games = {
    roadways: 'Roadways',
    aa: 'Accident Analysis',
    lm: 'Landmark Mapping',
    oa: 'Overtaking Analysis',
    hazchem: 'Hazchem Quiz',
    basics: 'Quiz Basics',
    signfactory: 'Sign Factory',
    iso: 'Isometric Quiz'
};

const gameData = [
    {
        route: games.roadways,
        level: 1
    },
    {
        route: games.aa,
        level: 1
    },
    {
        route: games.basics,
        level: 1
    },
    {
        route: games.lm,
        level: 1
    },
    {
        route: games.oa,
        level: 1
    },
    {
        route: games.hazchem,
        level: 1
    },
    {
        route: games.iso,
        level: 1
    },
    {
        route: games.aa,
        level: 2
    },
    {
        route: games.basics,
        level: 2
    },
    {
        route: games.signfactory,
        level: 1
    },
    {
        route: games.oa,
        level: 2
    },
    {
        route: games.hazchem,
        level: 2
    },
    {
        route: games.roadways,
        level: 2
    },
    {
        route: games.aa,
        level: 3
    },
    {
        route: games.basics,
        level: 3
    },
    {
        route: games.lm,
        level: 2
    },
    {
        route: games.oa,
        level: 3
    },
    {
        route: games.hazchem,
        level: 3
    },
    {
        route: games.aa,
        level: 4
    },
    {
        route: games.iso,
        level: 2
    },
    {
        route: games.basics,
        level: 4
    },
    {
        route: games.oa,
        level: 4
    },
    {
        route: games.signfactory,
        level: 2
    },
    {
        route: games.roadways,
        level: 3
    },
    {
        route: games.hazchem,
        level: 4
    },
    {
        route: games.aa,
        level: 5
    },
    {
        route: games.lm,
        level: 3
    },
    {
        route: games.iso,
        level: 3
    },
    {
        route: games.oa,
        level: 5
    }
];



import React from 'react';

import { DatePicker, Select, Table } from 'antd';
import { CorporateInterface, DrivingSchoolInterface, UserInterface } from '../../../types';
import { RRServer } from '../../../utils/lib';
import dayjs from 'dayjs';
import TopBar from './../../TopBar';
import { CSVLink } from 'react-csv';
import TableContainer from './../../TableComp/TableContainer';
import { DateColumnFilter, SelectColumnFilter } from '../../TableComp/Filter';
// import MiniTable from './MiniTable';

const { Option } = Select;

const column = [
  "School", "User", "Game", "Score"
]
const tbody = [
  "schools", "user", "game", "score"
]
interface ScoreInterface {
  id: string
  cid: string
  uid: string
  game: string
  level: number
  score: number
  addedOn: number
}

interface ScoresViewStateProps {
  isToggle: boolean
  scores: ScoreInterface[]
  loading: boolean
  schools: DrivingSchoolInterface[],
  corporates: CorporateInterface[],
  users: UserInterface[]
  filterSchoolId: string,
  filterUserId: string,
  filterDate: number,
  userHashed: { [key: string]: UserInterface },
  schoolsHashed: { [key: string]: DrivingSchoolInterface },
  corpHashed: { [key: string]: CorporateInterface },
}

class ScoresMini extends React.Component<any, ScoresViewStateProps>{
  constructor(props: any) {
    super(props);

    const claimsStr = localStorage.getItem('claims');
    const claims = !!claimsStr ? JSON.parse(claimsStr) : {};

    this.state = {
      loading: true,
      scores: [],
      schools: [],
      corporates: [],
      users: [],
      filterSchoolId: '',
      filterUserId: '',
      filterDate: 0,
      userHashed: {},
      schoolsHashed: {},
      corpHashed: {},
      isToggle: false
    };
  }
  toggleCard=(e:any)=>{
    this.setState({isToggle:!this.state.isToggle});
  }
   GetFormattedDate =() => {
    var todayTime = new Date();
    var month = todayTime.getMonth() + 1;
    var monthFormat = month.toString().length === 1 ? `0${month}` : month
    var day = todayTime.getDate();
    var dayFormat = day.toString().length === 1 ? `0${day}` : day
    var year = todayTime.getFullYear();
    return dayFormat + "/" + monthFormat + "/" + year;
  }
  

  componentDidMount() {

    const claimsStr = localStorage.getItem('claims');
    const claims = !!claimsStr ? JSON.parse(claimsStr) : {};
    const drivingSchoolId = claims.drivingSchoolId || '';

    let promiseArr: any = [];
    if (drivingSchoolId === "*"  || claims.drivingSchoolId === "#") {
      promiseArr = [
        RRServer.list({ table: "scores", forCurrentUser: false }),
        RRServer.list({ table: "users", forCurrentUser: false }),
        RRServer.list({ table: 'driving_schools', forCurrentUser: false }),
        RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false }),
        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false })
      ]
    } else {
      promiseArr = [
        RRServer.list({ table: "scores", forCurrentUser: false, where: [['cid', '==', drivingSchoolId]] }),
        RRServer.list({ table: "users", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
        RRServer.list({ table: "driving_schools", forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
        RRServer.list({ table: RRServer.Tables.DRIVING_SCHOOLS, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] }),
        RRServer.list({ table: RRServer.Tables.CORPORATES, forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] })
      ]
    }

    Promise.all(promiseArr)
      .then((results: any) => {
        // console.log(results,'ress')
        const scores = results[0].data.data;
        const users = results[1].data.data as UserInterface[];
        const schools = results[2].data.data as DrivingSchoolInterface[];
        const corporates = results[3].data.data as CorporateInterface[];

        let userHashed: { [key: string]: UserInterface } = {};
        users.map(user => userHashed[user.id] = user);


        let corpHashed: { [key: string]: CorporateInterface } = {};
        corporates.map(school => corpHashed[school.id] = school);

        this.setState({
          scores: scores.map(
            (c: any) => {( (c.uid = userHashed[c.uid]?  userHashed[c.uid].name : '')); return c}
          ),
        });

        let sklHash: any = {};
        schools.map((a: any) => (sklHash[a.id] = a));
        
        this.setState({
          scores: scores.map(
            (c: any) => {( (c.cid = sklHash[c.cid]?  sklHash[c.cid].drivingSchoolName : '')); return c}
          ),
        });

        this.setState({ loading: false, scores, users, userHashed, schools, schoolsHashed: sklHash, corporates, corpHashed })
      })
  }

  

  render() {
    const { scores, schools, filterSchoolId, filterUserId, filterDate, users, userHashed, schoolsHashed, corporates, corpHashed } = this.state;
    // let { url } = this.props.match;

    const claimsString = localStorage.getItem('claims') || '{}';
    const claims = JSON.parse(claimsString);
    claims.drivingSchoolId = claims.drivingSchoolId || '';

    // const claimsString = localStorage.getItem('claims') || '{}';
    // const claims = JSON.parse(claimsString);
    const isLoggedIn = localStorage.getItem('claims');
    const isSkl =  claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#"? true : false;
    const isSuperAdmin = claims.drivingSchoolId === "*"? true : false
    const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER"? true : false
    const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION"? true : false

    let dataSource = scores.map(score => ({ key: score.id, ...score }))


    if (filterSchoolId) {
      dataSource = dataSource.filter(d => d.cid === filterSchoolId)
    }
    if (filterUserId) {
      dataSource = dataSource.filter(d => d.uid === filterUserId)
    }
    if (!!filterDate) {
      const df = dayjs(filterDate).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      dataSource = dataSource.filter(d => {
        const da = dayjs(d.addedOn).set('h', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
        return da.isSame(df)
      })
    }

    function GetFormattedDateDash() {
      var todayTime = new Date();
      var month = todayTime.getMonth() + 1;
      var monthFormat = month.toString().length === 1 ? `0${month}` : month
      var day = todayTime.getDate();
      var dayFormat = day.toString().length === 1 ? `0${day}` : day
      var year = todayTime.getFullYear();
      return year + "-" + monthFormat + "-" + dayFormat;
    }




    return (
      <>
        <div className={this.state.isToggle ? `dashBotCard1 scores show` : `dashBotCard1 scores hide`} onClick={(e)=>{this.toggleCard(e)}}>
          <div className="linkTo">
            <div className="titleTag">
              <h4>Scores</h4>
              <div className="arrow"></div>
            </div>

            <span className="">{this.GetFormattedDate()}</span>

            <a href="scores">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </a>
          </div>
          <div className="tableA">
            {/* <MiniTable thead={column} tbody={tbody} data={scores}/> */}
            <table className="table table-borderless">
              <thead>
                <tr>
                 {!isSkl && <th>School</th>}
                  <th>User</th>
                  <th>Game</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {scores && scores.filter((ins:any)=>{
                                return ins.dueDate === GetFormattedDateDash()
                            }).map((td: any, index) => {
                  if (index <= 4) {
                    return <tr key={index}>
                      {!isSkl && <td>{td.cid}</td>}
                      <td>{td.uid}</td>
                      <td>{td.game}</td>
                      <td>{td.score}</td>
                    </tr>
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }




};

export default ScoresMini;
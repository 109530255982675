import React, { Component } from 'react';
import styles from '../../../FormComponent/FormComponent.module.css';
import { DrivingSchoolInterface, InstructorInterface, UserInterface, DAYS, DayType, StudentInterface, EMPTY_SLOTS, DateTimeType, DRIVING_SCHOOL_USER_TYPES, InstallmentInterface } from '../../../../types';
import { throws } from 'assert';
import { Button } from 'antd';


interface userFormState {
    values: any,
    isPassword: boolean,
    selectedSchool: any,
    userCode: any,
    selectedUserType: any,
    chooseDrivingSchool: any
    submitLoading: boolean,
    isEdit: boolean

}

interface userFormProps {
    handleValues: (values: any) => void,
    schools: any[],
    student?: any,
    isEdit?: boolean
}


class UserForm extends Component<userFormProps, userFormState> {


    state: userFormState = {
        values: {
            companyId: "",
            type: "",
            name: "",
            mobile: "",
            dob: "",
            typeOfVehicle: "",
            noOfSeats: "",
            code: "",
            password: "",
        },
        isPassword: false,
        selectedSchool: null,
        userCode: null,
        selectedUserType: null,
        chooseDrivingSchool: null,
        submitLoading: false,
        isEdit: false
    }

    componentDidMount() {
        console.log("studentttt", this.props.student)
    }

    showPassword = (e: any) => {
        this.setState({ isPassword: !this.state.isPassword })
        e.target.previousSibling.previousSibling.type === 'text' ? e.target.previousSibling.previousSibling.setAttribute("type", "password") : e.target.previousSibling.previousSibling.setAttribute("type", "text");
    }

    handleSubmit = (e: any) => {
        e.preventDefault()
    }


    getSchool(schoolId: string) {
        const school = this.props.schools.filter(school => school.id === schoolId);
        return school[0];
    }

    incrementCounter(s: string) {
        let ss = s.split('');
        let alpha = ss.shift();
        let num = +ss.join('')
        num += 1;
        if (num > 99) {
            alpha = String.fromCharCode(alpha!.charCodeAt(0) + 1);
            num = 0;
        }
        const numStr = num < 10 ? ('0' + num) : num
        return alpha + '' + numStr;
    }

    onDrivingSchoolChange(value: string) {
        const school = this.getSchool(value);

        let ctr = school.currentCount;
        let newCtr = this.incrementCounter(ctr);
        let userCode = school.code.substring(0, 6) + newCtr;
        console.log('selected skl', school, userCode)
        this.setState({
            selectedSchool: school,
            userCode,
            values: {
                ...this.state.values,
                code: userCode
            }
        });
    }
    getCodeBasedOnType = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {

        const { selectedSchool } = this.state;
        if (selectedUserType && selectedSchool) {
            let ctr;

            if (selectedUserType === 'DRIVING_SCHOOL_USER') {
                ctr = selectedSchool!.currentCount;

            } else if (selectedUserType === 'DRIVING_SCHOOL_ADMIN') {
                ctr = selectedSchool!.currentCountAdmin;
            } else if (selectedUserType === 'DRIVING_SCHOOL_INSTRUCTOR') {
                ctr = selectedSchool!.instructorCurrentCount;
            } else if (selectedUserType === 'DRIVING_SCHOOL_OFFICE_ADMIN') {
                ctr = selectedSchool!.officeAdminCurrentCount;
            }
            return ctr;
        } else {
            return ''
        }

    }

    getCodeForSchool = (selectedUserType: DRIVING_SCHOOL_USER_TYPES) => {

        const { selectedSchool } = this.state;
        if (selectedUserType && selectedSchool) {
            let ctr, newCtr, userCode = '';

            if (selectedUserType === 'DRIVING_SCHOOL_ADMIN') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 10) {
                    return false;
                }
            } else if (selectedUserType === 'DRIVING_SCHOOL_INSTRUCTOR') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 99) {
                    return false;
                }
            } else if (selectedUserType === 'DRIVING_SCHOOL_OFFICE_ADMIN') {
                let t = selectedSchool!.currentCountAdmin.split('')
                let num = +t.slice(1).join('');
                if (num > 20) {
                    return false;
                }
            } else if(selectedUserType === 'DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR'){
                let t = selectedSchool!.adminAndInstructorCurrentCount.split('')
                let num = +t.slice(1).join('');
                if (num > 99) {
                    return false;
                }
            }

            ctr = this.getCodeBasedOnType(selectedUserType);
            newCtr = this.incrementCounter(ctr);
            userCode = selectedSchool!.code.substring(0, 6) + newCtr;

            return userCode;
        } else {
            return ''
        }
    }


    onFormValueChange = (val: any) => {
        console.log('onFormValuee', val)
        let key = val.key[0]
        if (key === 'companyId') {
            return this.onDrivingSchoolChange(val.value);
        } else if (key === 'type') {
            const code = this.getCodeForSchool(val.value);
            if (code === false) {
                alert('Max Limit of users reached');
                return;
            }
            this.setState({
                selectedUserType: val.value,
                userCode: code,
                values: {
                    ...this.state.values,
                    code: code
                }
            })
        }
    }

    handleSchoolChange = async (e: any) => {
        console.log('e.', e.target.value)
        await this.setState({ chooseDrivingSchool: e.target.value, values: { ...this.state.values, [e.target.name]: e.target.value } });
        if (this.state.chooseDrivingSchool) {
            const school = this.getSchool(e.target.value);
            this.setState({ selectedSchool: school })
        }

    }

    handleTypeChange = async (e: any) => {
        await this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })
        this.onFormValueChange({ key: [e.target.name], value: e.target.value })
    }

    handleFormSubmit = () => {
        this.setState({ submitLoading: true })
        this.props.isEdit ? this.props.handleValues({
            companyId: this.props.student?.companyId,
            type: this.props.student?.type,
            name: this.props.student?.name,
            mobile: this.props.student?.mobile,
            dob: this.props.student?.dob,
            typeOfVehicle: this.props.student?.typeOfVehicle,
            noOfSeats: "",
            code: this.props.student?.code,
            password: this.props.student?.password,
            id: this.props.student?.id
        }) : 
        this.props.handleValues(this.state.values)
    }

    render() {
        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        const isLoggedIn = localStorage.getItem('claims');
        const isSkl = claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#" ? true : false;
        const isSuperAdmin = claims.drivingSchoolId === "*" ? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER" ? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION" ? true : false
        { console.log(this.props, 'props from child') }
        return (
            <>
                {<div className={styles.formWrapper} >
                    <form className='container needs-validation' onSubmit={(e: any) => this.handleSubmit(e)}>
                        <div className="row">
                            <div className={`col-md-6 col-sm-12 mb-2 form-floating d-inline-block`}>
                                <select className={`form-select form-control`} disabled={this.props.isEdit} value={this.props.student?.companyId ? this.props.student?.companyId : this.state.chooseDrivingSchool} name={'companyId'} onChange={(e) => this.handleSchoolChange(e)} id='chooseDrivingSchool' aria-label="Floating label select example" required={true}>
                                    <option value="">Select Value from dropdown...</option>
                                    {this.props.schools.map((skl: any, idx: any) => {
                                        return <option value={skl.id} key={idx}>{skl.drivingSchoolName}</option>
                                    })}
                                </select>
                                <label htmlFor='chooseDrivingSchool' className={`px-4`}>Choose Driving School</label>
                            </div>

                            <div className={`col-md-6   col-sm-12 mb-2 form-floating d-inline-block`}>
                                <select className={`form-select form-control`} disabled={this.props.isEdit} value={this.props.student?.type ? this.props.student?.type : this.state.values.type} name={`type`} onChange={(e) => this.handleTypeChange(e)} id='userType' aria-label="Floating label select example" required={true}>
                                    <option value="">Select Value from dropdown...</option>

                                    {<option value={'DRIVING_SCHOOL_USER'}>Student</option>}
                                    {!isSkl && <option value={'DRIVING_SCHOOL_ADMIN'}>Admin</option>}
                                    {!isSkl && <option value={'DRIVING_SCHOOL_INSTRUCTOR'}>Instructor</option>}
                                    {!isSkl && <option value={'DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR'}>Admin & Instructor</option>}

                                    {/* <option value={'DRIVING_SCHOOL_OFFICE_ADMIN'}>office admin</option> */}
                                </select>
                                <label htmlFor='userType' className={`px-4`}>User Type</label>
                            </div>

                            <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`}>
                                <input className={`form-control`} type='text' name={'name'} disabled={this.props.isEdit} value={this.props.student?.name ? this.props.student?.name : this.state.values.name} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='select Date' maxLength={200} id='name' required={true} />
                                <label htmlFor='name' className={`px-4`}>Name</label>
                            </div>

                            <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`}>
                                <input className={`form-control`} type={'number'} name={'mobile'} disabled={this.props.isEdit} value={this.props.student?.mobile ? this.props.student?.mobile : this.state.values.mobile} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='Mobile' maxLength={200} id='mobile' required={true} />
                                <label htmlFor='mobile' className={`px-4`}>Mobile</label>
                            </div>

                            <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`}>
                                <input className={`form-control`} type={'date'} name={'dob'} disabled={this.props.isEdit} value={this.props.student?.dob ? this.props.student?.dob : this.state.values.dateOfBirth} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='dateOfBirth' maxLength={200} id='dateOfBirth' required={true} />
                                <label htmlFor='dob' className={`px-4`}>Date of Birth</label>
                            </div>

                            <div className={`col-md-6   col-sm-12 mb-2 form-floating d-inline-block`}>
                                <select className={`form-select form-control`} disabled={this.props.isEdit} value={this.props.student?.typeOfVehicle ? this.props.student?.typeOfVehicle : this.state.values.typeOfVehicle} name='typeOfVehicle' onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} id='typeOfVehicle' aria-label="Floating label select example" required={true}>
                                    <option value="">Select Value from dropdown...</option>

                                    <option value={'TWO_WHEELER'}>Two Wheeler</option>
                                    <option value={'FOUR_WHEELER'}>Four Wheeler</option>
                                    <option value={'HEAVY'}>Heavy</option>
                                    <option value={'REFRESHER'}>Refresher</option>
                                </select>
                                <label htmlFor='typeOfVehicle' className={`px-4`}>Type of Vehicle</label>
                            </div>

                            {(this.state.values.typeOfVehicle === 'fourWheeler' || this.state.values.typeOfVehicle === 'heavy') && this.state.values.userType === 'instructor' && <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`}>
                                <input className={`form-control`} type='number' name={'noOfSeats'} value={this.state.values.noOfSeats} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='noOfSeats' maxLength={200} id='noOfSeats' required={true} />
                                <label htmlFor='noOfSeats' className={`px-4`}>noOfSeats</label>
                            </div>}

                            <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`}>
                                <input className={`form-control`} type='text' name={'userCode'} value={this.props.student?.code ? this.props.student?.code : this.state.values.code} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='Code' maxLength={200} id='code' required={true} disabled />
                                <label htmlFor='userCode' className={`px-4`}>Code</label>
                            </div>

                            <div className={`mb-3 form-floating  inline-block col-md-6 col-sm-12`} style={{ position: "relative" }} >
                                <input className={`form-control`} type='password' name={'password'} disabled={this.props.isEdit} value={ this.props.student?.password ? this.props.student?.password :this.state.values.password} onChange={(e) => this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })} placeholder='password' maxLength={200} id='password' required={true} />
                                <label htmlFor='password' className={`px-4`}>Password</label>
                                {/* <p style={{ cursor: "pointer", fontSize: "22px", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "30px" }}
                                            onClick={
                                                (e: any) => { e.target.previousSibling.previousSibling.type === 'text' ? e.target.previousSibling.previousSibling.setAttribute("type", "password") : e.target.previousSibling.previousSibling.setAttribute("type", "text") }} >
                                            &#x1F441;
                                        </p> */}

                                <svg onClick={(e: any) => this.showPassword(e)} style={{ cursor: "pointer", fontSize: "22px", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "30px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                    {this.state.isPassword ?
                                        <>
                                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                        </>
                                        :

                                        <>
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                        </>
                                    }
                                </svg>


                            </div>
                            <div className="text-right">
                                {/* <button className={`${styles.btn} btn btn-primary`} type="button" data-bs-toggle="modal" data-bs-target="#userPreview">Preview</button> */}
                                {/* <button className={styles.btn} onClick={()=>this.props.handleValues(this.state.values)} type='submit'>Submit</button> */}
                                <Button type="primary" loading={this.state.submitLoading} onClick={() => this.handleFormSubmit()}> Submit </Button>
                            </div>
                        </div>
                    </form>
                </div>}
            </>
        );
    }
}

export default UserForm;
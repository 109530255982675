import React, { useEffect, useState } from 'react'
import TopBar from '../TopBar'
import { Select } from 'antd'
import { callOnServer, getClaims, getLoggedInUserName, RRServer } from '../../utils/lib';
import UpdateInsForm from './UpdateInsForm';


const { Option } = Select;

export default function UpdateInstructor() {

    const [schools, setSchools] = useState([])
    const [selectedSchool, setSelectedSchool] = useState("")
    const [instructors, setInstructors]: any = useState([])
    const [selectedIns, setSelectedIns]: any = useState("")
    const [slots, setSlots] = useState({})


    useEffect(() => {
        const claimsString: any = localStorage.getItem('claims');
        const claims = JSON.parse(claimsString);

        RRServer.list({ table: 'driving_schools', forCurrentUser: false }).then((result: any) => {
            let schools = result.data.data;
            setSchools(schools)
        })
    }, [])

    useEffect(() => {
        RRServer.list({
            table: RRServer.Tables.USERS,
            where: [['type', '==', "DRIVING_SCHOOL_INSTRUCTOR"], ["companyId", "==", selectedSchool]],
            forCurrentUser: false
        }).then((result: any) => {
            setInstructors(result.data.data)
        }).catch((err: any) => {
            console.log(err, 'error')
        })
    }, [selectedSchool])

    const updateIns = async (slots: any) => {

        const selectedObj = instructors?.filter((ins: any) => {
            return ins.id === selectedIns
        })[0]

        const obj = {
            ...selectedObj,
            slots
        }

        Promise.all([RRServer.update({
            table: RRServer.Tables.USERS,
            id: selectedIns,
            ...obj,
        })]).then((res) => {
            alert(" slots successfully updated")
            window.location.reload()
        }).catch((err) => {
            alert("Something went wrong")
            window.location.reload()
        })
    }

    return (
        <div>
            <TopBar title="Update Instructor" />

            <div className='contentContainer'>
                <div className='contentTop' style={{ justifyContent: "start" }}>
                    <Select
                        showSearch
                        style={{ width: 200, marginLeft: "50px" }}
                        placeholder="Select School"
                        optionFilterProp="children"
                        onChange={(id) => setSelectedSchool(id)}
                        filterOption={(input: any, option: any) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {schools.map((skl: any) => <Option key={skl.id} value={skl.id}>{skl.drivingSchoolName}</Option>)}
                    </Select>

                    <Select
                        showSearch
                        style={{ width: 200, marginLeft: "50px" }}
                        placeholder="Select Instructor"
                        optionFilterProp="children"
                        onChange={(ins) => {
                            setSelectedIns(ins)
                            setSlots(instructors.filter((inss: any)=>{
                                return inss.id === ins
                            })[0]?.slots)
                        }}
                        filterOption={(input: any, option: any) => {
                            return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                        }
                        }
                    >
                        {instructors.map((instructor: any) => <Option key={instructor.id} value={instructor.id}>{instructor.name}</Option>)}

                    </Select>

                </div>
                <div style={{ width: "100%", overflow: "scroll", height: "500px" }}>

                    {selectedIns && <UpdateInsForm updateIns={updateIns} slots={slots} />}

                </div>
            </div>

        </div >
    )
}

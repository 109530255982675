import React from 'react';
import { Button, Card, Collapse, Divider, Input, message, Space, Table } from 'antd';
import Icon, { EditFilled, EyeFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
// import { Link, withRouter } from 'react-router-dom';
import { callOnServer, getClaims, getLoggedInUserName, RRServer } from '../../../utils/lib';
import { UserInterface } from '../../../types';
import FormComponent from '../../FormComponent/FormComponent';
// import Razorpay from "razorpay";
// import logo from "../../logo.svg";
import { Select } from 'antd';
import TableContainer from '../../TableComp/TableContainer';
import { DateColumnFilter, DateColumnFilter3, DefaultColumnFilter, SelectColumnFilter } from '../../TableComp/Filter';
import TopBar from '../../TopBar';
import UserForm from './subComponents/UserForm';
import AddUserForm from './subComponents/AddUserForm';
import AddInstructorForm from './subComponents/AddInstructorForm';
import DrivingSchoolUserAdd from './subComponents/DrivingSchoolUserAdd';
import UserAdd from './UserAdd';
// import noCodesImages from '../../../assets/noCodesImage.webp'
import noCodesImages from '../../../assets/noCodesImage.webp'
import { Navigate } from 'react-router-dom';
import { timeStamp } from 'console';
import { Row } from 'reactstrap';
// import { withRouter } from 'react-router';


const { Option } = Select;
const { Panel } = Collapse;

interface DrivingSchoolInterface {
    code: string;
    id: string
    drivingSchoolName: string
    currentCount: string
    pricePerCode: number
    codesRemaining: number
}
interface CorporateInterface {
    code: string;
    id: string
    corporateName: string
    currentCount: string
}
interface CorporateCodesInterface {
    code: string;
    password: string;
}


type schoolHashedType = { [key: string]: DrivingSchoolInterface };
type corporateHashedType = { [key: string]: CorporateInterface };
type corporateCodesHashedType = { [key: string]: CorporateCodesInterface };
interface UserStateProps {
    schools: DrivingSchoolInterface[],
    schoolsHashed: schoolHashedType

    corporates: CorporateInterface[],
    corporatesHashed: corporateHashedType

    selectedSchool: DrivingSchoolInterface | null
    userCode: string
    users: UserInterface[]

    corporateCodes: CorporateCodesInterface[],
    corporateCodesHashed: corporateCodesHashedType


    filterSchoolId: string
    filterUserId: string
    filterMobileNumber: string

    codesToBuy: number,

    loading: boolean,

    isToggle: boolean,
    isEdit: boolean,
    progress: number,
    userType: string,
    formOneValues: any,
    value: string,

    currentSchool: any,
    instructors: any,
    selectedStudent: any,
    deleteStudent: any,
    remarks: any,
}



class UserListNew extends React.Component<any, UserStateProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            schools: [],
            schoolsHashed: {},
            corporates: [],
            corporatesHashed: {},
            selectedSchool: null,
            userCode: '',
            users: [],
            corporateCodes: [],
            corporateCodesHashed: {},
            filterSchoolId: '',
            filterUserId: '',
            filterMobileNumber: '',
            codesToBuy: 5,
            loading: false,
            isToggle: true,
            isEdit: false,
            progress: 100,
            userType: "user",
            formOneValues: null,
            value: '',
            currentSchool: {},
            instructors: "",
            selectedStudent: {},
            deleteStudent: "",
            remarks: ""
        }
        this.getSchool = this.getSchool.bind(this);
    }

    componentDidMount() {
        //         const companyId=getUse
        this.getData('');
        const claimsString: any = localStorage.getItem('claims');
        const claims = JSON.parse(claimsString);

        RRServer.get({
            table: RRServer.Tables.DRIVING_SCHOOLS,
            id: claims.drivingSchoolId
        }).then((res: any) => {
            console.log('qqqqq', res.data.data)
            this.setState({ currentSchool: res.data.data })
        }).catch((err) => {
            console.log('errr', err)
        })


        RRServer.list({
            table: RRServer.Tables.USERS,
            where: [['companyId', '==', claims.drivingSchoolId], ['type', '==', "DRIVING_SCHOOL_INSTRUCTOR"]],
            forCurrentUser: false
        }).then((result: any) => {
            this.setState({ instructors: result.data.data })
        }).catch(err => {
            console.log(err, 'error')
        })


        RRServer.list({ table: 'logs', forCurrentUser: false })
            .then((res) => {
                console.log(res, "res list")
            })
            .catch((err) => {
                console.log(err, "err")
            })
    }

    componentDidUpdate(prevProps: any) {

        if (this.props.drivingSchool !== prevProps.drivingSchool) {
            console.log('Props changed', this.props)
            this.getData(this.props.drivingSchool.id);
        }
    }

    getData = (id: string) => {

        return getClaims().then(claims => {
            if (!claims || !!!claims['drivingSchoolId']) { return null }
            const drivingSchoolId = claims ? claims['drivingSchoolId'] : '';

            const userPromise = drivingSchoolId === '*' || claims.drivingSchoolId === "#"
                ? RRServer.list({ table: 'users', forCurrentUser: false })
                : RRServer.list({ table: 'users', forCurrentUser: false, where: [['companyId', '==', drivingSchoolId]] });
            const dsPromise = drivingSchoolId === '*' || claims.drivingSchoolId === "#"
                ? RRServer.list({ table: 'driving_schools', forCurrentUser: false })
                : RRServer.get({ table: 'driving_schools', id: drivingSchoolId });

            return Promise.all([
                // RRServer.list({ table: 'users', forCurrentUser: false, where:[['companyId','==',id]] }),
                userPromise,
                dsPromise,
                RRServer.list({ table: 'corporate_users', forCurrentUser: false }),
                RRServer.list({ table: 'corporate_codes', forCurrentUser: false }),
            ])
                .then((results: any) => {
                    let users = results[0].data.data.sort((a: UserInterface, b: UserInterface) => a?.name?.localeCompare(b.name));

                    let schools: DrivingSchoolInterface[];
                    let _s: schoolHashedType = {}
                    if (results[1].data.data) {
                        schools = results[1].data.data.length ? results[1].data.data : [results[1].data.data];
                        _s = {};
                        schools.map(school => _s[school.id] = school)
                    } else {
                        schools = [];
                    }

                    let corporates: CorporateInterface[] = results[2].data.data;
                    let _c: corporateHashedType = {};
                    corporates.map(corp => _c[corp.id] = corp)

                    let corporateCodes: CorporateCodesInterface[] = results[3].data.data;
                    let _cc: corporateCodesHashedType = {};
                    corporateCodes.map(corp => _cc[corp.code] = corp)
                    console.log('here', users.filter((user: any) => {
                        return user.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR"
                    }))
                    this.setState({ users, schools, schoolsHashed: _s, corporates, corporatesHashed: _c, corporateCodes, corporateCodesHashed: _cc })
                });
        })
    }

    getSchool(schoolId: string) {
        const school = this.state.schools.filter(school => school.id = schoolId);
        return school[0];
    }

    getName = (user: UserInterface) => {
        if (!user) { return 'NU' }
        const { schoolsHashed, corporatesHashed } = this.state;
        if (user.type === 'DRIVING_SCHOOL') {
            const _school = schoolsHashed[user.companyId];
            return (_school && _school.drivingSchoolName);
        } else if (user.type === 'CORPORATE_USER_IOCL') {
            const _corp = corporatesHashed[user.companyId];
            return (_corp && _corp.corporateName);
        }
    }

    getPassword = (user: UserInterface) => {
        if (user.password) {
            return user.password;
        }
        const { corporateCodesHashed } = this.state;
        const cc = corporateCodesHashed[user.code];
        return cc ? cc.password : '-';
    }

    loadScript(src: string) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    createOrder = async () => {
        const { schools, codesToBuy } = this.state;
        const pricePerCode = schools[0].pricePerCode;

        if (!pricePerCode) {
            alert('Price Per Code Not Set');
            return;
        }

        return RRServer.callFn('payment-createOrder', {
            pricePerCode: pricePerCode, codes: codesToBuy
        })
            .then((r: any) => r.data.order)
    }

    displayRazorpay = async () => {
        const { schools, codesToBuy } = this.state;

        const res = await this.loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        this.setState({ loading: true })


        // creating a new order
        const result = await this.createOrder();
        console.log(result);

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        // // Getting the order details back
        const { amount, id: order_id, currency } = result;
        const name = getLoggedInUserName();

        const options = {
            key: "rzp_live_bfig0kGIyXdE7J", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: name,
            description: "Roadrakshak User Codes",
            // image: { logo },
            order_id: order_id,
            handler: async (response: any) => {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    code: codesToBuy
                };


                RRServer.add({
                    table: 'payments', data: {
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        amount,
                        codesToBuy,
                        pricePerCode: schools[0].pricePerCode,
                        schoolId: schools[0].id,
                        schoolName: schools[0].drivingSchoolName
                    }
                });
                const codesRemaining = schools[0].codesRemaining || 0;
                RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                alert('Payment Successful');
                this.setState({ loading: false })

                return;
                //skipping this for now
                //@todo MUST VERIFY ORDER
                RRServer.callFn('payment-verifyOrder', data)
                    .then((r: any) => {
                        console.log('Success', r);
                        // alert(result.data.msg);
                        if (r.data.s) {

                            RRServer.add({
                                table: 'payments', data: {
                                    razorpayPaymentId: response.razorpay_payment_id,
                                    razorpayOrderId: response.razorpay_order_id,
                                    amount,
                                    codesToBuy,
                                    pricePerCode: schools[0].pricePerCode,
                                    schoolId: schools[0].id,
                                    schoolName: schools[0].drivingSchoolName
                                }
                            });
                            const codesRemaining = schools[0].codesRemaining || 0;
                            RRServer.update({ table: RRServer.Tables.DRIVING_SCHOOLS, id: schools[0].id, codesRemaining: codesRemaining + codesToBuy });
                            alert('Payment Successful');
                            this.setState({ loading: false })

                        }

                    })

            },
            prefill: {
                name: name,

            },
            notes: {

            },
            theme: {
                color: "#61dafb",
            },
        };

        const paymentObject = new (window as any).Razorpay(options);
        paymentObject.open();
    }

    subtractCodes = () => this.setState({ codesToBuy: this.state.codesToBuy == 5 ? 5 : this.state.codesToBuy - 5 })
    addCodes = () => this.setState({ codesToBuy: this.state.codesToBuy + 5 })

    toggleForm = (e: any) => {
        e.preventDefault();
        this.setState({ selectedStudent: null, isEdit: false })
        if (this.state.currentSchool?.codesRemaining <= 0) {
            this.setState({ progress: -1 })
        } else {
            if (this.state.progress === 1 && (this.state.formOneValues.userType === 'user' || this.state.formOneValues.userType === 'instructor')) {
                this.setState({ progress: 50 })
            } else if (this.state.progress === 100 && (this.state.userType === 'user' || this.state.userType === 'instructor')) {
                this.setState({ isToggle: !this.state.isToggle, progress: 1 });
            } else {
                this.setState({ progress: 1, isToggle: !this.state.isToggle })
            }
        }


    }

    cancelForm = (e: any) => {
        e.preventDefault();
        window.location.reload()
        this.setState({ isToggle: !this.state.isToggle, progress: 100, isEdit: !this.state.isEdit, selectedStudent: null });
    }

    handleValues = (values: any) => {
        console.log('values from parent', values)
        this.setState({ formOneValues: values, value: values.userType })
    }

    handleFormOneValues = (values: any) => {
        console.log('formOneValues', values)
        if (values.userType === 'user' || values.userType === 'instructor') {
            this.setState({ formOneValues: { ...values }, progress: 50 })
        } else {
            this.setState({ formOneValues: { ...values }, isToggle: !this.state.isToggle, progress: 100 })
        }
    }

    handleClassAssign = (val: any, isReset: boolean) => {
        if (isReset) {
            const answer = window.confirm('This feature is in open BETA now. There may be some issues when resetting class schedules. Do you want to proceed?');
            if (answer) {
                this.setState({ isToggle: !this.state.isToggle, isEdit: true, selectedStudent: val.row.original })
            }
        }
        else {
            this.setState({ isToggle: !this.state.isToggle, isEdit: true, selectedStudent: val.row.original })
        }
    }

    handleUserSlots = (val: any) => {
        console.log(val, 'from child comp')
    }

    handleDeleteUser = (id: any) => {
        console.log(id, 'idd')

        RRServer.callFn("deleteUser", { id: id })
            .then((res) => console.log('success', res))
            .catch((err) => console.log('err', err))
    }

    handleChangePassword = (id: any) => {
        console.log(id, 'idd')

        RRServer.callFn("users1-changePassword", { id: id, password: 'qazwsx123' })
            .then((res) => console.log('success', res))
            .catch((err) => console.log('err', err))
    }

    handleUserDelete = (val: any) => {
        console.log(val, 'user delete')

        console.log(val,)
        const { users, remarks } = this.state

        const selectedUser = users?.filter((student) => {
            return student.id === val
        })[0]

        const { code, id, name, companyId } = selectedUser

        RRServer.add({ table: 'deleted_logs', data: { student: selectedUser, remarks } })
            .then((res) => {

                Promise.all([
                    RRServer.remove({ table: "users", id: val }),
                    RRServer.remove({ table: "class_schedules", where: [["studentId", "==", val]] }),
                ]).then((res) => {
                    window.location.reload()
                }).catch(err => {
                    console.log(err, 'err')
                })
            })
            .catch((err) => {
                message.error("Something went wrong, unable to delete!")
            })
    }

    render() {

        const { users, schools, filterSchoolId, filterUserId, filterMobileNumber, codesToBuy, deleteStudent } = this.state;
        // let { url } = this.props.match;
        const currentSchool = this.props.drivingSchool || {};


        const claimsString = localStorage.getItem('claims') || '{}';
        const claims = JSON.parse(claimsString);
        claims.drivingSchoolId = claims.drivingSchoolId || '';


        const isLoggedIn = localStorage.getItem('claims');
        const isSuperAdmin = claims.drivingSchoolId === "*" ? true : false
        const isClusterAdmin = claims.drivingSchoolId === "#" && claims?.userType === "CLUSTER" ? true : false
        const isAssociationAdmin = claims.drivingSchoolId === "#" && claims?.userType === "ASSOCIATION" ? true : false
        const isSkl = claims.drivingSchoolId !== "*" && claims.drivingSchoolId !== "#" ? true : false;

        let dataSource = users.map(user => {
            if (!user?.deviceUUID || user?.deviceUUID === "") {
                if (user.hasOwnProperty("classSchedules")) {
                    return { ...user, appDownload: 'NO', key: user.id, isClassAdded: true }

                } else {
                    return { ...user, appDownload: 'NO', key: user.id, isClassAdded: false }

                }
            } else {
                if (user.hasOwnProperty("classSchedules")) {
                    return { ...user, appDownload: 'YES', key: user.id, isClassAdded: true }
                } else {
                    return { ...user, appDownload: 'YES', key: user.id, isClassAdded: false }
                }
            }
        })


        console.log(this.state.users.filter((data: any) => {
            return data.code === "AP0710B41"
        }), 'codee')





        const superAdminColumns = [
            {
                Header: 'Schools',
                accessor: 'companyId',
                disableFilters: true,
                Cell: (val: any) => {
                    const sklData: any = this.state.schools.filter((skl: any) => {
                        return skl?.id === val?.value
                    })
                    return sklData[0] ? sklData[0].drivingSchoolName : null
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Password',
                accessor: 'password',
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
            },
            {
                Header: 'App Download',
                accessor: 'appDownload',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Type',
                accessor: 'type',
                Filter: SelectColumnFilter,
            },
            {
                Header: "Boarded Date",
                accessor: "BoardedDate",
                Filter: DateColumnFilter3
            },
            {
                Header: 'Is Class Scheduled',
                accessor: 'isClassAdded',
                disableSortBy: true,
                disableFilters: true,
                Cell: (val: any) => {
                    if (!val.value) {
                        if (val.row.original.type === "DRIVING_SCHOOL_USER") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        } else if (val.row.original.type === "DRIVING_SCHOOL_INSTRUCTOR") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        } else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        }
                        else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN") {
                            return <button className='btn btn-secondary' disabled>Admin</button>
                        } else {
                            return null
                        }
                    } else {
                        if (val.row.original.type === "DRIVING_SCHOOL_USER") {
                            return <button className='btn btn-secondary' type='button' onClick={() => this.handleClassAssign(val, true)}>Reset Class Schedule</button>
                        } else {
                            if (val.row.original.type === "DRIVING_SCHOOL_INSTRUCTOR") {
                                return <button className='btn btn-secondary' disabled>Instructor</button>
                            } else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
                                return <button className='btn btn-secondary' disabled> Admin & Instructor</button>
                            }
                            else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN") {
                                return <button className='btn btn-secondary' disabled>Admin</button>
                            } else {
                                return null
                            }
                        }
                    }
                },
            },
            {
                Header: 'Action',
                accessor: 'id',
                disableSortBy: true,
                disableFilters: true,
                Cell: (val: any) => {
                    return <button type="button" data-bs-toggle="modal" data-bs-target="#deleteModal" className='btn btn-secondary' onClick={() => this.setState({ deleteStudent: val.value })}>Delete</button>
                },
            }
        ]

        const columns = [
            {
                Header: 'Schools',
                accessor: 'companyId',
                disableFilters: true,
                Cell: (val: any) => {
                    const sklData: any = this.state.schools.filter((skl: any) => {
                        return skl?.id === val?.value
                    })
                    return sklData[0] ? sklData[0].drivingSchoolName : null
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Code',
                accessor: 'code',
            },
            {
                Header: 'Password',
                accessor: 'password',
            },
            {
                Header: 'Mobile',
                accessor: 'mobile',
            },
            {
                Header: 'App Download',
                accessor: 'appDownload',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Type',
                accessor: 'type',
                Filter: SelectColumnFilter,
            },
            {
                Header: "Boarded Date",
                accessor: "BoardedDate",
                Filter: DateColumnFilter3
            },
            {
                Header: 'Is Class Scheduled',
                accessor: 'isClassAdded',
                disableSortBy: true,
                disableFilters: true,
                Cell: (val: any) => {
                    if (!val.value) {
                        if (val.row.original.type === "DRIVING_SCHOOL_USER") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        } else if (val.row.original.type === "DRIVING_SCHOOL_INSTRUCTOR") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        } else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
                            return <button type="button" onClick={() => this.handleClassAssign(val, false)} className="btn btn-primary" >
                                ASSIGN CLASS
                            </button>
                        }
                        else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN") {
                            return <button className='btn btn-secondary' disabled>Admin</button>
                        } else {
                            return null
                        }
                    } else {
                        if (val.row.original.type === "DRIVING_SCHOOL_USER") {
                            return <button className='btn btn-secondary' onClick={() => this.handleClassAssign(val, true)} type="button">Reset Class Schedule</button>
                        } else {
                            if (val.row.original.type === "DRIVING_SCHOOL_INSTRUCTOR") {
                                return <button className='btn btn-secondary' disabled>Instructor</button>
                            } else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN_AND_INSTRUCTOR") {
                                return <button className='btn btn-secondary' disabled> Admin & Instructor</button>
                            }
                            else if (val.row.original.type === "DRIVING_SCHOOL_ADMIN") {
                                return <button className='btn btn-secondary' disabled>Admin</button>
                            } else {
                                return null
                            }
                        }
                    }
                },
            }
        ]

        if (filterSchoolId) {
            dataSource = dataSource.filter((d: any) => d.companyId === filterSchoolId)
            console.log(dataSource)
        }
        if (filterUserId) {
            dataSource = dataSource.filter(d => d.id === filterUserId)
        }
        if (filterMobileNumber) {
            dataSource = dataSource.filter(d => d.id === filterMobileNumber)
        }


        return (
            <>
                <TopBar title="Users" />

                <div className="contentContainer">
                    <div className='contentTop'>
                        {<h5>{this.state.isToggle ? 'Users List' : 'Add User'}</h5>}
                        {claims.drivingSchoolId === "*" || claims.drivingSchoolId === "#" ?

                            this.state.isToggle && <Select
                                showSearch
                                style={{ width: 200, marginLeft: "50px" }}
                                placeholder="Filter School"
                                optionFilterProp="children"
                                onChange={(id) => this.setState({ filterSchoolId: id + "", })}
                                filterOption={(input: any, option: any) => {
                                    return option!.children ? option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }
                                }
                            >
                                {schools.map(school => <Option key={school.id} value={school.id}>{school.drivingSchoolName}</Option>)}

                            </Select>

                            : ""}
                        {(isSkl || isSuperAdmin) && <> <div className='tableBtn' style={{ marginLeft: "auto", display:"flex" , alignItems:"center"}}>
                            {isSuperAdmin && <a href="/updateInstructor">Update Instructor</a>}
                            {this.state.isToggle && <button className='btn btn-primary' onClick={(e) => this.toggleForm(e)}>{isSkl ? " ADD DRIVING SCHOOL STUDENT" : "ADD DRIVING SCHOOL USER"}</button>}
                            {/* {!this.state.isToggle && <button className='btn btn-primary' onClick={(e) => this.cancelForm(e)}>{this.state.isToggle ? 'ADD DRIVING SCHOOL USER' : 'CANCEL'}</button>} */}
                        </div></>}
                    </div>

                    <div className={this.state.isToggle ? `table show` : `table hide`}>
                        {this.state.progress === 100 && <TableContainer columns={isSuperAdmin ? superAdminColumns : columns} data={dataSource} />}
                        {this.state.progress === -1 &&
                            <div className='NoCodesContainer' style={{ textAlign: "center" }}>
                                <img src={noCodesImages} alt="nocodespreview" width={450} style={{ margin: "auto" }} />
                                <p style={{ fontSize: "16px" }}>You dont have enough codes to create a new student. Kindly purchase codes to enable this option</p>
                                <p style={{ fontSize: "16px" }}><a href='/purchase' style={{ display: "inline-block" }}>Click here </a>  to go to purchase</p>
                            </div>
                        }
                    </div>
                    <div className={this.state.isToggle ? `form hide` : `form show`}>
                        {this.state.isEdit ? <UserAdd student={this.state.selectedStudent} isEdit={this.state.isEdit} /> : <UserAdd />}
                    </div>
                </div>


                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {/* {this.state.instructors &&  <AddUserForm
                                instructors={this.state.instructors}
                                handleSlots={this.handleUserSlots}
                                />} */}
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>


                {/* <!--delete  Modal --> */}
                <div className="modal fade" id="deleteModal" tabIndex={-1} aria-labelledby="deleteModal" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModal">Confirm Delete</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-floating">
                                    <textarea className="form-control" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })} placeholder="Leave a remark here" id="floatingTextarea2" style={{ height: "100px" }}></textarea>
                                    <label htmlFor="floatingTextarea2">Remarks</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={() => this.handleUserDelete(deleteStudent)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
// export default (UserAdd);
export default UserListNew;